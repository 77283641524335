import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  model,
  input,
  effect
} from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-filter',
  templateUrl: './select-filter.component.html',
  styleUrls: ['./select-filter.component.scss']
})
export class SelectFilterComponent implements OnInit, OnChanges {
  @Input() options: { label: string; value: string | number }[] = [];
  @Input() title: string = '';
  @Input() selectedEmitter?: any;
  @Input() resetSelection?: (string | number)[] = [];
  @Input() initialSelection?: (string | number)[] = [];
  selectedValue = model(this.initialSelection || []);
  resetSignal = input();
  formCtrl = new FormControl([] as { label: string; value: string | number }[]);
  // Keeping it for refernece as it might change outside of this component
  initialSelectionCopy: (string | number)[] = [];
  resetSelectionCopy: (string | number)[] = [];
  currentSelectionCopy: (string | number)[] = [];
  initialValue: { label: string; value: string | number }[] = [];
  resetValue: { label: string; value: string | number }[] = [];
  currentValue: { label: string; value: string | number }[] = [];

  constructor() {
    effect(() => {
      if (this.resetSignal() === true) {
        this.reset();
      }
    });
  }

  ngOnInit(): void {
    if (this.initialSelection && this.initialSelection.length > 0) {
      this.initialSelectionCopy = _.cloneDeep(this.initialSelection);
      this.initialValue = this.options.filter(o =>
        // @ts-ignore
        this.initialSelection.includes(o.value)
      );
      this.currentSelectionCopy = _.cloneDeep(this.initialSelection);
      this.currentValue = _.cloneDeep(this.initialValue);
      this.formCtrl.setValue(this.initialValue);
    }

    if (this.resetSelection && this.resetSelection.length > 0) {
      this.resetSelectionCopy = _.cloneDeep(this.resetSelection);
      this.resetValue = this.options.filter(o =>
        // @ts-ignore
        this.resetSelection.includes(o.value)
      );
    }

    this.formCtrl.valueChanges.subscribe(value => {
      const res = value?.map(v => v.value).sort();
      if (!_.isEqual(this.currentSelectionCopy, res)) {
        this.selectedEmitter?.emit(res || []);
        this.selectedValue.set(res || []);
        this.currentValue = value || [];
        this.currentSelectionCopy = res || [];
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.initialSelection && this.initialSelection.length > 0) {
      this.initialSelectionCopy = _.cloneDeep(this.initialSelection);
      this.initialValue = this.options.filter(o =>
        // @ts-ignore
        this.initialSelection.includes(o.value)
      );
      this.currentSelectionCopy = _.cloneDeep(this.initialSelection);
      this.currentValue = _.cloneDeep(this.initialValue);
      this.formCtrl.setValue(this.initialValue);
    }

    if (this.resetSelection && this.resetSelection.length > 0) {
      this.resetSelectionCopy = _.cloneDeep(this.resetSelection);
      this.resetValue = this.options.filter(o =>
        // @ts-ignore
        this.resetSelection.includes(o.value)
      );
    }
  }

  get hasChanged() {
    return !_.isEqual(
      this.currentSelectionCopy?.sort(),
      this.resetSelectionCopy.sort()
    );
  }

  setValue(values: (string | number)[]) {
    const selectedValues = this.options.filter(x => {
      return values.includes(x.value as string | number);
    });
    this.formCtrl.setValue(selectedValues);
  }

  reset() {
    this.formCtrl.setValue(this.resetValue);
  }
}
