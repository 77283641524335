import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { JsWidget } from '../_interfaces/Widget';
import { CacheService } from '../_services/cache.service';
import * as _ from 'lodash';
import { SvgViewerModalService } from '../_services/svg-viewer-modal.service';
import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { JsTask } from '../_interfaces/Task';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { taskStatusList } from '../shared/status';

@Component({
  selector: 'app-list-specs-of-task',
  templateUrl: './list-specs-of-task.component.html',
  styleUrls: ['./list-specs-of-task.component.scss'],
})
export class ListSpecsOfTaskComponent implements OnInit {
  widgets: JsWidget[] = [];
  faFigma = faFigma;
  faImage = faImage;
  linkIcon = faEdit;
  typeClassMap = {
    state: 'text-bg-warning',
    action: 'text-bg-review',
    rule: 'text-bg-info',
    bug: 'text-bg-danger',
    unknown: 'text-bg-secondary',
    other: 'text-bg-secondary',
  };
  statusClassMap = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Correction: 'text-bg-dark-danger',
    Review: 'text-bg-review',
    Develop: 'text-bg-primary',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Approve: 'text-bg-success',
    Deprecated: 'text-bg-purple',
    Removed: 'text-bg-dark-purple',
  };
  statusFilter = {
    options: ['All', ...taskStatusList],
    selected: 'All'
  }
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { task: JsTask, status: string },
    private cc: CacheService,
    private SvgViewerModalService: SvgViewerModalService,
    private dManager: DialogManagerService
  ) {}

  ngOnInit(): void {
    this.widgets = this.cc.getSpecsOfTask(this.data.task);
    this.statusFilter.selected = this.data.status || 'All';
    this.onStatusChange();
  }

  previewFigma(widget: JsWidget) {
    if (!widget?.stateFigmaPreviewUrl) return;
    const isMinimal = widget.path.startsWith('taskDiagram');
    this.SvgViewerModalService.show(
      widget!,
      isMinimal,
      this.widgets.filter((w) => w.type === 'state' && w.stateFigmaPreviewUrl)
    ).subscribe(async (res: any) => {
      if (res && res.action === 'delete') {
        alert('Delete Preview not supported from this view!');
      }
    });
  }

  onStatusChange() {
    const allWidgets = _.cloneDeep(this.cc.getSpecsOfTask(this.data.task));
    if(this.statusFilter.selected == 'All') {
      this.widgets = allWidgets;
    } else {
      this.widgets = allWidgets.filter(widget => widget.status === this.statusFilter.selected);
    }
  }

  openWidget(widget: JsWidget, e: any) {
    // this._dialog.open(WidgetDetailEditComponent, {
    //   panelClass: 'widget-detail-edit-bottom-sheet',
    //   disableClose: true,
    //   data: {
    //     widgetId: widget.id,
    //   },
    // });
    this.dManager.openDialog(WidgetDetailEditComponent, {
      disableClose: true,
      data: {
        widgetId: widget.id,
      },
      panelClass: 'widget-detail-edit-bottom-sheet',
    });
    e.stopPropagation();
  }

  openFigma(url: string) {
    if (!url) return;
    window.open(url, '_blank');
  }
}
