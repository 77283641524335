<span *ngIf="!isEmpty; else noSpecs">
  <span class="badge rounded-pill text-bg-light me-1 ri-pointer" matTooltip="Draft" [ngClass]="{ 'opacity-25': !Draft }" (click)="showSpecList(viewId,'Draft', $event)">
    {{ Draft || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-dark me-1 ri-pointer" matTooltip="Paused" [ngClass]="{ 'opacity-25': !Paused }" (click)="showSpecList(viewId,'Paused', $event)">
    {{ Paused || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-primary me-1 ri-pointer" matTooltip="Reviewed" [ngClass]="{ 'opacity-25': !Reviewed }" (click)="showSpecList(viewId,'Reviewed', $event)">
    {{ Reviewed || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-primary me-1 ri-pointer" matTooltip="Translated" [ngClass]="{ 'opacity-25': !Translated }" (click)="showSpecList(viewId,'Translated', $event)">
    {{ Translated || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-info me-1 ri-pointer" matTooltip="Ready" [ngClass]="{ 'opacity-25': !Ready }" (click)="showSpecList(viewId,'Ready', $event)">
    {{ Ready || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-warning me-1 ri-pointer" matTooltip="Implemented" [ngClass]="{ 'opacity-25': !Implemented }" (click)="showSpecList(viewId,'Implemented', $event)">
    {{ Implemented || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-danger me-1 ri-pointer" matTooltip="Failed" [ngClass]="{ 'opacity-25': !Failed }" (click)="showSpecList(viewId,'Failed', $event)">
    {{ Failed || '-' }}
  </span>
  <!-- passed -->
  <span class="badge rounded-pill text-bg-success me-1 ri-pointer" matTooltip="Passed" [ngClass]="{ 'opacity-25': !Passed }" (click)="showSpecList(viewId,'Passed', $event)">
    {{ Passed || '-' }}
  </span>
  <!-- deprecated -->
  <span class="badge rounded-pill text-bg-secondary me-1 ri-pointer" matTooltip="Deprecated" [ngClass]="{ 'opacity-25': !Deprecated }" (click)="showSpecList(viewId,'Deprecated', $event)">
    {{ Deprecated || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-dark me-1 ri-pointer" matTooltip="Removed" [ngClass]="{ 'opacity-25': !Removed }" (click)="showSpecList(viewId,'Removed', $event)">
    {{ Removed || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-dark me-1 ri-pointer" matTooltip="Total" (click)="showSpecList(viewId,'All', $event)">
    {{ total }}
  </span>
</span>
<span>
  <!-- <span *ngIf="totalTranslations" class="badge rounded-pill text-bg-secondary ms-2" matTooltip="All">
    {{ totalTranslations || '-'}}
  </span> -->
  <span *ngIf="incompleteTranslations" class="badge rounded-pill text-bg-danger ms-3" matTooltip="Incomplete">
    {{ incompleteTranslations || '-' }}
  </span>
</span>
<ng-template #noSpecs>
  <span class="ri-pointer" (click)="showSpecList(viewId, 'All',  $event)">- - -</span>
</ng-template>
