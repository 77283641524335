<main class="comment-form p-3 ri-w-600">
    <div class="d-flex justify-content-between mb-3">
        <h2 class="fw-semibold mb-0">Comment {{ mode === 'new' ? 'Create' : 'Update' }}</h2>
        <div class="d-flex">
            <button *ngIf="commentInitial.deletedAt && commentInitial.createdBy === fbs.getCurrentUserId()" matTooltip="Restore" class="btn btn-danger btn-sm btn-restore d-flex align-items-center me-2" (click)="deleteHandler(false)" [disabled]="commentInitial.createdBy !== this.fbs.getCurrentUserId()"><mat-icon class="text-light">replay</mat-icon></button>
            <button *ngIf="mode === 'edit'" matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center" (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
        </div>
    </div>

    <div class="ri-dialog-content" [ngClass]="{'ri-pe-none': commentInitial.deletedAt}">
        <form [formGroup]="commentForm" #cmtForm="ngForm">
            <app-textarea class="d-block mb-1" [control]="getFc('text')" [defaultValue]="commentInitial.text" [minRow]="'2'" [maxRow]="'10'" title="Text" name="riCommentText" [mentions]="mentions" [disabled]="commentInitial.createdBy !== this.fbs.getCurrentUserId()"></app-textarea>

            <div class="row g-1 mb-1">
                <div class="col-6">
                    <app-select [options]="commentType" [title]="'Type'" class="w-100" [control]="getFc('type')"
                        [defaultValue]="commentInitial.type"></app-select>
                </div>
                <div class="col-6">
                    <app-select [options]="fbs.users" [title]="'Assigned To'" class="" [noneOption]="true"
                    [control]="getFc('assignedTo')" [defaultValue]="commentInitial.assignedTo" [label]="'label'" [value]="'value'"></app-select>
                </div>
            </div>
            <mat-checkbox [checked]="commentForm.value.resolvedBy" color="primary" (change)="toggleResolved($event)">{{ commentForm.value.resolvedBy ? 'Resolved by ' + fbs.getUserNameFromId(commentForm.value.resolvedBy) : 'Mark as resolved' }}</mat-checkbox>

            <app-attachments [newAttachments]="newAttachments" [deletedAttachments]="deletedAttachments" [entityId]="commentForm.value.id" [attachmentsControl]="getFc('attachmentNames')"></app-attachments>
        </form>
    </div>

    <div class="pt-2 text-center" [hidden]="!isSaveInProcess">
        <img class="img img-fluid loader" src="assets/images/loader.gif">
    </div>

    <div class="ri-dialog-footer" *ngIf="!commentInitial.deletedAt">
        <div class="d-flex justify-content-between mt-5">
            <div class="">
                <button *ngIf="mode === 'edit' && commentInitial.createdBy === fbs.getCurrentUserId()" type="button" class="btn btn-sm text-bg-danger ri-fs-16px lh-sm me-2 ri-btn-delete"
                    (click)="deleteHandler(true)" [disabled]="commentInitial.deletedAt">
                    <i class="bi bi-trash"></i>
                </button>
            </div>
            <div>
                <button type="button" class="btn btn-sm btn-outline-secondary" (click)="cancelChanges()"
                    [disabled]="!hasChanges() || isSaveInProcess">Reset</button>
                <button type="submit" class="btn btn-sm btn-primary ms-2" (click)="saveChanges()"
                    [disabled]="!hasChanges() || commentForm.invalid || isSaveInProcess">Save</button>
            </div>
        </div>
    </div>

</main>