import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatLabel, MatFormField, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { OnlyLowercaseDirective } from 'src/app/_directives/only-lowercase.directive';
import { ParamsWarningModalComponent } from 'src/app/params/params-warning-modal/params-warning-modal.component';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { JsEvent } from 'src/app/_interfaces/Events';
import { JsLog } from 'src/app/_interfaces/Log';
import { JsParam, Param } from 'src/app/_interfaces/Param';
import { ActivityService } from 'src/app/_services/activity.service';
import { CacheService } from 'src/app/_services/cache.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { getNewId } from 'src/app/shared/utils';
import * as _ from 'lodash';
import { duplicateValidator } from 'src/app/_configs/form-validator';
import { AlphabetsWithDotsDirective } from 'src/app/_directives/alphabets-with-dot.directive';
import { DateFnsModule } from 'ngx-date-fns';
import { SharedFunctionService } from 'src/app/_services/shared-function.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-param-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CdkTextareaAutosize,
    MatLabel,
    MatFormField,
    MatInput,
    MatError,
    MatIcon,
    OnlyLowercaseDirective,
    AlphabetsWithDotsDirective,
    SelectComponent,
    MatSelect,
    MatOption,
    DateFnsModule
  ],
  templateUrl: './param-form.component.html',
  styleUrl: './param-form.component.scss'
})
export class ParamFormComponent {

  paramForm: FormGroup;
  allEvents: JsEvent[] = [];
  mode: 'new' | 'edit';
  @ViewChild('pgForm') pgForm!: NgForm;
  paramInitial: JsParam;
  paramGroupTypes: string[] = ['Event', 'Log'];

  paramTypes = [
    'string',
    'number',
    'boolean',
    'viewId',
    'unixTimestamp',
    'isoDateOnly',
    'isoTimeOnly',
    'isoDateTimeOnly',
    'isoDateTimeTz',
    'isoDateTimeUTC',
    'other',
  ];

  paramTypeExamples: { [key: string]: string } = {
    string: 'e.g. "hello"',
    number: 'e.g. 123',
    boolean: 'e.g. true|false',
    viewId: 'e.g. "screen_addTodo"',
    unixTimestamp: 'e.g. 1611111111 (seconds)',
    isoDateOnly: 'e.g. "2021-01-01"',
    isoTimeOnly: 'e.g. "12:00:00"',
    isoDateTimeOnly: 'e.g. "2021-01-01T12:00:00"',
    isoDateTimeTz: 'e.g. "2021-01-01T12:00:00+08:00"',
    isoDateTimeUTC: 'e.g. "2021-01-01T12:00:00Z"',
    other: 'e.g. "other"',
  };
  unSubscribe = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      param: JsParam,
      mode: 'new' | 'edit',
      type: 'Event' | 'Log'
    },
    public dialogRef: MatDialogRef<ParamFormComponent>,
    public cc: CacheService,
    private confirm: ConfirmService,
    private fbo: FirebaseOptimisticService,
    private dialog: MatDialog,
    private as: ActivityService,
    private fb: FormBuilder,
    public sharedFunc: SharedFunctionService
  ) {

    this.mode = this.data.mode;
    this.paramInitial = data.mode == 'new' ? this.initiateParamsForm() : this.initiateParamsForm(data.param);

    this.paramForm = this.fb.group({
      name: new FormControl(this.paramInitial.name, Validators.required),
      type: new FormControl(this.paramInitial.type, Validators.required),
      params: this.fb.array([], [Validators.required, duplicateValidator('key')]),
      note: new FormControl(this.paramInitial.note),
      id: new FormControl(this.paramInitial.id, Validators.required),
      deletedAt: new FormControl(this.paramInitial.deletedAt)
    });
    if (this.paramInitial.params && this.paramInitial.params.length !== 0) {
      this.clearParams();
      this.paramInitial.params.forEach((param: Param) => {
        this.params.push(this.createParam(param));
      });
    } else {
      this.params.push(this.createParam());
    }
    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirm.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
  }

  ngOnInit(): void { }

  initiateParamsForm(param?: JsParam): JsParam {
    return {
      name: param ? param.name : '',
      params: param ? param.params : [],
      note: param ? param.note : '',
      type: param ? param.type : this.data.type,
      id: param ? param.id : getNewId(),
      deletedAt: param ? param.deletedAt : null,
    }
  }

  get params(): FormArray {
    return this.paramForm.get('params') as FormArray;
  }

  getFc(fc: string): FormControl {
    return this.paramForm.get(fc) as FormControl;
  }

  addParamsArray() {
    const paramsUsed = this.checkIsParamsUsed();
    if (this.mode === 'edit' && paramsUsed.length !== 0) {
      this.blockAction(paramsUsed, 'A');
    } else {
      this.params.push(this.createParam());
    }
  }

  createParam(value?: Param) {
    return this.fb.group({
      key: new FormControl(value ? value.key : null, [Validators.required, Validators.max(38), Validators.maxLength(38)]),
      type: new FormControl(value ? value.type : 'string', Validators.required)
    });
  }

  removeAction(index: number) {
    const paramsUsed = this.checkIsParamsUsed();
    if (this.mode === 'edit' && paramsUsed.length !== 0) {
      this.blockAction(paramsUsed, 'R', index);
    } else {
      this.removeParamsFromArray(index);
    }
  }

  async removeParamsFromArray(index: number) {
    const itemGroup = this.params.at(index);
    if (!itemGroup.value.key) {
      this.params.removeAt(index);
    } else {
      const result = await this.confirm.confirm(
        'Delete Param',
        'Are you sure you want to delete this param?'
      );
      if (result) {
        this.params.removeAt(index);
      }
    }
  }

  getActivity() {
    this.as.getActivity('param', this.paramInitial)
  }

  cancelChanges() {
    this.paramForm.reset();
    this.pgForm.resetForm();
    this.clearParams();
    this.paramForm.patchValue(this.paramInitial);
    if (this.paramInitial.params.length !== 0) {
      this.paramInitial.params.forEach((param: Param) => {
        this.params.push(this.createParam(param))
      });
    } else {
      this.params.push(this.createParam())
    }
  }

  clearParams() {
    while (this.params.length !== 0) {
      this.params.removeAt(0)
    }
  }

  blockAction(datas: JsEvent[] | JsLog[], type: 'A' | 'R' | 'E' | 'D', index?: number) { // A- add, R- Remove, E - edit, D - Delete
    const warningModel = this.dialog.open(ParamsWarningModalComponent, {
      width: '700px',
      height: '800px',
      data: {
        selectedGroups: [this.paramInitial],
        type: this.paramForm.value.type,
        logs: this.paramForm.value.type === 'Log' ? datas : [],
        events: this.paramForm.value.type === 'Event' ? datas : [],
      },
    });
    warningModel.afterClosed().subscribe(result => {
      if (result === true) {
        switch (type) {
          case 'A':
            this.params.push(this.createParam());
            break;
          case 'R':
            if (index || index === 0) {
              this.removeParamsFromArray(index);
            }
            break;
          case 'E':
            this.saveParamGroup();
            break;
          case 'D':
            this.deleteHandler(true);
            break;
          default:
            break;
        }

      }
    });
  }

  hasChanges() {
    const initial = _.cloneDeep(this.paramInitial);
    const current = _.cloneDeep(this.paramForm.value);
    // sort params and paramsGroupIds
    if (initial.params?.length !== current.params?.length) return true;
    initial.params.sort((a: { key: string; }, b: { key: string; }) => (a.key > b.key ? 1 : -1));
    current.params.sort((a: { key: string; }, b: { key: string; }) => (a.key > b.key ? 1 : -1));
    return !_.isEqual(initial, current);
  }

  saveAction() {
    const paramsUsed = this.checkIsParamsUsed();
    if (this.mode === 'edit' && paramsUsed.length !== 0 && this.checkParamsEdited()) {
      this.blockAction(paramsUsed, 'E');
    } else {
      this.saveParamGroup();
    }
  }

  async saveParamGroup() {
    const paramsBackup = _.cloneDeep(this.paramForm.value);
    let updateStatus = null;

    const updateId = this.paramForm.value.id;
    if (!this.paramForm.value.id) return;
    if (this.mode === 'new') {
      updateStatus = await this.fbo.createItemsOptimistic<JsParam>(
        [this.paramForm.value],
        'params'
      );
      if (updateStatus && updateId === this.paramInitial.id) {
        this.mode = 'edit';
        this.paramInitial = _.cloneDeep(this.paramInitial);
        this.cancelChanges();
      }
    } else if (this.mode === 'edit') {
      this.paramInitial = _.cloneDeep(this.paramForm.value);
      updateStatus = await this.fbo.updateItemsOptimistic<JsParam>(
        [this.paramForm.value],
        'params'
      );
      this.cc.setUpdatedParam(this.paramInitial);
    }

    if (!updateStatus) {
      this.paramInitial = _.cloneDeep(paramsBackup);
    }
    this.dialogRef.close(this.paramForm.value);
  }

  checkParamsEdited() {
    if (this.mode === 'new' || !this.paramInitial || !this.paramForm.value) {
      return false
    }
    if (this.paramInitial?.params.length !== this.paramForm.value?.params.length) {
      return true; // Arrays are of different lengths, so they have changed
    }

    return this.paramInitial.params.some((ip, index) => {
      const ep = this.paramForm.value!.params[index];
      return ip.key !== ep.key || ip.type !== ep.type;
    });
  }

  checkIsParamsUsed(): JsEvent[] | JsLog[] {
    if (this.paramForm.value.type === 'Log') {
      if (this.cc.allLogs.length === 0 || !this.paramInitial) {
        return [];
      }
      const paramsUsed = this.cc.allLogs.filter(log => log.paramsGroupIds.includes(this.paramInitial.id) && !log.deletedAt);
      return paramsUsed;
    } else {
      if (this.cc.allEvents.length === 0 || !this.paramInitial) {
        return [];
      }
      const paramsUsed = this.cc.allEvents.filter(event => event.paramsGroupIds.includes(this.paramInitial.id) && !event.deletedAt);
      return paramsUsed;
    }
  }

  deleteAction() {
    const paramsUsed = this.checkIsParamsUsed();
    if (paramsUsed.length !== 0) {
      this.blockAction(paramsUsed, 'D');
    } else {
      this.deleteHandler(true);
    }
  }

  async deleteHandler(isDelete: boolean) {
    const confirmed = await this.confirm.confirm(`${isDelete ? 'Delete' : 'Restore'} Param`, `Are you sure you want to ${isDelete ? 'delete' : 'restore'} this param?`);
    if (confirmed) {
      let preDeleteParam: JsParam = _.cloneDeep(this.paramInitial);
      preDeleteParam.deletedAt = isDelete ? new Date() : null;
      await this.fbo.updateItemsOptimistic<JsParam>([preDeleteParam], 'params');
      if (!isDelete) {
        this.cc.restoreEntitySubject.next('param');
      }
      this.cc.setUpdatedParam(preDeleteParam);
      this.dialogRef.close();
    }
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
