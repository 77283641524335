import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { JsWidget } from '../_interfaces/Widget';
import { FirebaseService } from '../_services/firebase.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-widget-detail-fingerprints',
  templateUrl: './widget-detail-fingerprints.component.html',
  styleUrls: ['./widget-detail-fingerprints.component.scss'],
})
export class WidgetDetailFingerprintsComponent implements OnInit, OnChanges {
  @Input()
  widget: JsWidget | null = null;
  backlogAssignedTo: string | null = null;
  designAssignedTo: string | null = null;
  reviewAssignedTo: string | null = null;
  correctionAssignedTo: string | null = null;
  developAssignedTo: string | null = null;
  testAssignedTo: string | null = null;
  failedAssignedTo: string | null = null;
  approveAssignedTo: string | null = null;
  deprecatedAssignedTo: string | null = null;
  removedAssignedTo: string | null = null;
  lastUpdatedBy: string | null = null;

  public mapClass = {
    backlogAssignedTo: 'bg-info',
    designAssignedTo: 'bg-secondary',
    reviewAssignedTo: 'bg-review',
    correctionAssignedTo: 'bg-dark-danger',
    developAssignedTo: 'bg-primary',
    testAssignedTo: 'bg-warning',
    failedAssignedTo: 'bg-danger',
    approveAssignedTo: 'bg-success',
    deprecatedAssignedTo: 'bg-success',
    removedAssignedTo: 'bg-success',
  };

  constructor(public fbs: FirebaseService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      _.isEqual(
        changes['widget']?.currentValue,
        changes['widget']?.previousValue
      )
    ) {
      return;
    }
    this.widget = changes['widget']?.currentValue;
    this.resetAssignedTo();
    this.setAssignedTo(this.widget);
  }

  resetAssignedTo() {
    this.backlogAssignedTo = null;
    this.designAssignedTo = null;
    this.reviewAssignedTo = null;
    this.correctionAssignedTo = null;
    this.developAssignedTo = null;
    this.testAssignedTo = null;
    this.failedAssignedTo = null;
    this.approveAssignedTo = null;
    this.deprecatedAssignedTo = null;
    this.removedAssignedTo = null;
    this.lastUpdatedBy = null;
  }

  setAssignedTo(widget: JsWidget | null) {
    if (!widget) {
      return;
    }
    this.backlogAssignedTo =
      this.fbs.getDisplayName(widget.backlogAssignedTo) || '- - -';
    this.designAssignedTo =
      this.fbs.getDisplayName(widget.designAssignedTo) || '- - -';
    this.reviewAssignedTo =
      this.fbs.getDisplayName(widget.reviewAssignedTo) || '- - -';
    this.developAssignedTo =
      this.fbs.getDisplayName(widget.developAssignedTo) || '- - -';
    this.testAssignedTo =
      this.fbs.getDisplayName(widget.testAssignedTo) || '- - -';
    this.failedAssignedTo =
      this.fbs.getDisplayName(widget.failedAssignedTo) || '- - -';
    this.approveAssignedTo =
      this.fbs.getDisplayName(widget.approveAssignedTo) || '- - -';
    this.lastUpdatedBy = this.fbs.getDisplayName(widget.uid) || '- - -';
  }
}
