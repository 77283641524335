import { CommonModule } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { TagsTextareaComponent } from '../tags-textarea/tags-textarea.component';
import { MatInput } from '@angular/material/input';

@Component({
  selector: 'app-textarea',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    TagsTextareaComponent
  ],
  templateUrl: './textarea.component.html',
  styleUrl: './textarea.component.scss'
})
export class TextareaComponent {

  @Input() title: string = '';
  @Input() name: string = '';
  @Input() defaultValue!: string;
  @Input() control: FormControl = new FormControl(this.defaultValue);
  @Input() minRow: string = '1';
  @Input() maxRow: string = '1';
  @Input() disabled: boolean = false;
  @Input() mentions: string[] = [];
  @Input() options: { [key: string]: string } = {};
  @ViewChild(TagsTextareaComponent) tagsTextArea: TagsTextareaComponent | null = null;

  constructor() {

  }


  reset() {
    if(this.tagsTextArea && this.tagsTextArea?.editableDiv && this.tagsTextArea?.editableDiv.nativeElement){
      this.tagsTextArea.editableDiv.nativeElement.innerHTML = this.control.value;
    }
  }

}
