<span class="" *ngIf="!isEmpty; else noTodos">
    <span class="badge rounded-pill text-bg-review me-1 ri-pointer" appGlobalTooltip [matTooltip]="getPercentage('todo')" [ngClass]="{ 'opacity-25': !todo }" (click)="viewRelatedTodosList($event, 'Todo')">
      {{ todo || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-info me-1 ri-pointer" appGlobalTooltip [matTooltip]="getPercentage('paused')" [ngClass]="{ 'opacity-25': !paused }" (click)="viewRelatedTodosList($event, 'Paused')">
      {{ paused || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-primary me-1 ri-pointer" appGlobalTooltip [matTooltip]="getPercentage('inProgress')" [ngClass]="{ 'opacity-25': !inProgress }" (click)="viewRelatedTodosList($event, 'In Progress')">
      {{ inProgress || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-secondary me-1 ri-pointer" appGlobalTooltip [matTooltip]="getPercentage('blocked')" [ngClass]="{ 'opacity-25': !blocked }" (click)="viewRelatedTodosList($event, 'Blocked')">
      {{ blocked || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-info me-1 ri-pointer" appGlobalTooltip [matTooltip]="getPercentage('needClarity')" [ngClass]="{ 'opacity-25': !needClarity }" (click)="viewRelatedTodosList($event, 'Need Clarity')">
      {{ needClarity || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-warning me-1 ri-pointer" appGlobalTooltip [matTooltip]="getPercentage('done')" [ngClass]="{ 'opacity-25': !done }" (click)="viewRelatedTodosList($event, 'Done')">
      {{ done || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-success me-1 ri-pointer" appGlobalTooltip [matTooltip]="getPercentage('approved')" [ngClass]="{ 'opacity-25': !approved }" (click)="viewRelatedTodosList($event, 'Approved')">
      {{ approved || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark ri-pointer" appGlobalTooltip [matTooltip]="getTotalPercentageProgress()" [ngClass]="{ 'opacity-25': !total }" (click)="viewRelatedTodosList($event, 'All')">
      {{ isShowPercentage() ? getPercentageProgressOnly('total') : total || '-' }}
    </span>
  </span>
  <ng-template #noTodos>
    <span class="d-inline-block">- - -</span>
  </ng-template>
  