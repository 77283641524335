<span class="ri-pointer" *ngIf="!isEmpty; else noSpecs">
  <span class="badge rounded-pill text-bg-info me-1" [matTooltip]="getPercentage('Backlog')" [ngClass]="{ 'opacity-25': !Backlog }" (click)="showTasksList($event, 'Backlog')">
    {{ Backlog || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-secondary me-1" [matTooltip]="getPercentage('Design')" [ngClass]="{ 'opacity-25': !Design }" (click)="showTasksList($event, 'Design')">
    {{ Design || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-review me-1" [matTooltip]="getPercentage('Review')" [ngClass]="{ 'opacity-25': !Review }" (click)="showTasksList($event, 'Review')">
    {{ Review || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-primary me-1" [matTooltip]="getPercentage('Develop')" [ngClass]="{ 'opacity-25': !Develop }" (click)="showTasksList($event, 'Develop')">
    {{ Develop || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-warning me-1" [matTooltip]="getPercentage('Test')" [ngClass]="{ 'opacity-25': !Test }" (click)="showTasksList($event, 'Test')">
    {{ Test || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-danger me-1" [matTooltip]="getPercentage('Failed')" [ngClass]="{ 'opacity-25': !Failed }" (click)="showTasksList($event, 'Failed')">
    {{ Failed || '-' }}
  </span>
  <!-- Mergeable -->
  <span class="badge rounded-pill text-bg-purple me-1" [matTooltip]="getPercentage('Mergeable')" [ngClass]="{ 'opacity-25': !Mergeable }" (click)="showTasksList($event, 'Mergeable')">
    {{ Mergeable || '-' }}
  </span>
  <!-- Passed -->
  <span class="badge rounded-pill text-bg-dark-success me-1" [matTooltip]="getPercentage('Passed')" [ngClass]="{ 'opacity-25': !Passed }" (click)="showTasksList($event, 'Passed')">
    {{ Passed || '-' }}
  </span>
  <!-- Approved -->
  <span class="badge rounded-pill text-bg-success me-1" [matTooltip]="getPercentage('Approved')" [ngClass]="{ 'opacity-25': !Approved }" (click)="showTasksList($event, 'Approved')">
    {{ Approved || '-' }}
  </span>
  <span class="badge rounded-pill text-bg-dark" [matTooltip]="getTotalPercentageProgress()" [ngClass]="{ 'opacity-25': !Total }" (click)="showTasksList($event, 'All')">
    {{ cc.showTaskProgressAsPercentage ? getPercentageProgressOnly('Total') : Total || '-' }}
  </span>
</span>
<ng-template #noSpecs>
  <span class="d-inline-block">- - -</span>
</ng-template>
