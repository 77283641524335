import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  SimpleChange,
  computed,
  Signal,
  effect,
} from '@angular/core';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { CacheService } from '../_services/cache.service';
import { JsTask } from '../_interfaces/Task';
import { ListSpecsOfTaskComponent } from '../list-specs-of-task/list-specs-of-task.component';
import { CommonModule } from '@angular/common';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip } from '@angular/material/tooltip';
import { customTooltipDefaults } from '../_configs/tooltip.config';
import { takeUntil } from 'rxjs';
import { JsWidget } from '../_interfaces/Widget';

@Component({
  selector: 'app-spec-status-counts-of-task',
  templateUrl: './spec-status-counts-of-task.component.html',
  styleUrls: ['./spec-status-counts-of-task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    MatTooltip
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
  ]
})

export class SpecStatusCountsOfTaskComponent implements OnInit {

  @Input() task: JsTask | undefined = undefined;
  Backlog: number = 0;
  Design: number = 0;
  Review: number = 0;
  Correction: number = 0;
  Develop: number = 0;
  Test: number = 0;
  Failed: number = 0;
  Approve: number = 0;
  Deprecated: number = 0;
  Removed: number = 0;
  Total: number = 0;
  isEmpty: boolean = true;
  relatedSpecs: Signal<JsWidget[]> = computed(() => this.cc.featureToRelatedSpecsMap()[this.task?.id || ''] || []);;

  constructor(
    private dManager: DialogManagerService,
    private cc: CacheService
  ) {
    effect(() => {
      this.update();
    });
  }

  ngOnInit(): void {
    this.getSpecsUpdate();
  }

  getSpecsUpdate() {
    // this.cc.widgets$.pipe(takeUntil(this.unSubscribe)).subscribe((specs: JsWidget[] | null) => {
    //   if (specs) {
    //     this.update();
    //   }
    // })
  }

  ngOnChanges(change: SimpleChange) {
    // @ts-ignore
    if (change['task']) {
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.Backlog = 0;
    this.Design = 0;
    this.Review = 0;
    this.Correction = 0;
    this.Develop = 0;
    this.Test = 0;
    this.Failed = 0;
    this.Approve = 0;
    this.Deprecated = 0;
    this.Removed = 0;
    if (this.cc.isFeatureToRelatedSpecs()) {
      const filteredWidgets = this.cc.featureToRelatedSpecsMap()[this.task?.id || ''] || [];
      filteredWidgets.forEach((widget) => {
        this[widget.status]++;
      });

      // Calculate the total number of widgets with starting with any of the specPrefixes
      this.Total =
        this.Backlog +
        this.Design +
        this.Review +
        this.Correction +
        this.Develop +
        this.Test +
        this.Failed +
        this.Approve +
        this.Deprecated +
        this.Removed;
      if (
        this.Backlog ||
        this.Design ||
        this.Review ||
        this.Correction ||
        this.Develop ||
        this.Test ||
        this.Failed ||
        this.Approve ||
        this.Deprecated ||
        this.Removed
      ) {
        this.isEmpty = false;
      }
    }
  }

  showSpecsListOfTask(task: JsTask | undefined, status: string, e: any) {
    if (!task) return;
    // this._dialog.open(ListSpecsOfTaskComponent, {
    //   data: {
    //     task,
    //   },
    // });
    this.dManager.openDialog(ListSpecsOfTaskComponent, {
      data: {
        task,
        status
      },
    });

    e.stopPropagation();
  }
}
