<span class="" *ngIf="!isEmpty; else noSpecs">
  <span class="badge rounded-pill text-bg-info me-1 ri-pointer" *ngIf="Backlog" matTooltip="Backlog" (click)="showSpecList(viewId,'Backlog', $event)">
    {{Backlog || ''}}
  </span>
  <span class="badge rounded-pill text-bg-secondary me-1 ri-pointer" *ngIf="Design" matTooltip="Design" (click)="showSpecList(viewId,'Design', $event)">
    {{Design || ''}}
  </span>
  <span class="badge rounded-pill text-bg-review me-1 ri-pointer" *ngIf="Review" matTooltip="Review" (click)="showSpecList(viewId,'Review', $event)">
    {{Review || ''}}
  </span>
  <span class="badge rounded-pill text-bg-dark-danger me-1 ri-pointer" *ngIf="Correction" matTooltip="Correction" (click)="showSpecList(viewId,'Correction', $event)">
    {{Correction || ''}}
  </span>
  <span class="badge rounded-pill text-bg-primary me-1 ri-pointer" *ngIf="Develop" matTooltip="Develop" (click)="showSpecList(viewId,'Develop', $event)">
    {{Develop || ''}}
  </span>
  <span class="badge rounded-pill text-bg-warning me-1 ri-pointer" *ngIf="Test" matTooltip="Test" (click)="showSpecList(viewId,'Test', $event)">
    {{Test || ''}}
  </span>
  <span class="badge rounded-pill text-bg-danger me-1 ri-pointer" *ngIf="Failed" matTooltip="Failed" (click)="showSpecList(viewId,'Failed', $event)">
    {{Failed || ''}}
  </span>
  <span class="badge rounded-pill text-bg-success me-1 ri-pointer" *ngIf="Approve" matTooltip="Approve" (click)="showSpecList(viewId,'Approve', $event)">
    {{Approve || ''}}
  </span>
  <span class="badge rounded-pill text-bg-purple me-1 ri-pointer" *ngIf="Deprecated" matTooltip="Deprecated" (click)="showSpecList(viewId,'Deprecated', $event)">
    {{Deprecated || ''}}
  </span>
  <span class="badge rounded-pill text-bg-dark-purple me-1 ri-pointer" *ngIf="Removed" matTooltip="Removed" (click)="showSpecList(viewId,'Removed', $event)">
    {{Removed || ''}}
  </span>
  <span class="badge rounded-pill text-bg-dark me-1 ri-pointer" matTooltip="Total" (click)="showSpecList(viewId,'All', $event)">
    {{ Total || ''}}
  </span>
</span>
<ng-template #noSpecs>-</ng-template>