import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { JsTask } from '../_interfaces/Task';
import { JsWidget } from '../_interfaces/Widget';
import { CacheService } from '../_services/cache.service';
import { FirebaseService } from '../_services/firebase.service';
import { WidgetDetailEditComponent } from '../widget-detail-edit/widget-detail-edit.component';
import { faImage } from '@fortawesome/free-regular-svg-icons';
import { faFigma } from '@fortawesome/free-brands-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { SvgViewerModalService } from '../_services/svg-viewer-modal.service';

@Component({
  selector: 'app-spec-list-modal',
  templateUrl: './spec-list-modal.component.html',
  styleUrl: './spec-list-modal.component.scss'
})
export class SpecListModalComponent {
  faFigma = faFigma;
  faImage = faImage;
  linkIcon = faEdit;
  typeClassMap = {
    state: 'text-bg-warning',
    action: 'text-bg-review',
    rule: 'text-bg-info',
    bug: 'text-bg-danger',
    unknown: 'text-bg-secondary',
    other: 'text-bg-secondary'
  };
  statusClassMap = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Correction: 'text-bg-dark-danger',
    Develop: 'text-bg-primary',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Approve: 'text-bg-success',
    Deprecated: 'text-bg-purple',
    Removed: 'text-bg-dark-purple'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      widgets: JsWidget[];
    },
    public cc: CacheService,
    public fbs: FirebaseService,
    private _dialog: DialogManagerService,
    private SvgViewerModalService: SvgViewerModalService
  ) {}

  previewFigma(widget: JsWidget) {
    if (!widget?.stateFigmaPreviewUrl) return;
    const isMinimal = widget.path.startsWith('taskDiagram');
    this.SvgViewerModalService.show(
      widget!,
      isMinimal,
      this.data.widgets.filter((w: JsWidget) => w.type === 'state' && w.stateFigmaPreviewUrl)
    ).subscribe(async (res: any) => {
      if (res && res.action === 'delete') {
        alert('Delete Preview not supported from this view!');
      }
    });
  }

  openFigma(url: string) {
    if (!url) return;
    window.open(url, '_blank');
  }

  openWidget(widget: JsWidget, e: any) {
    this._dialog.openDialog(WidgetDetailEditComponent, {
      panelClass: 'widget-detail-edit-bottom-sheet',
      disableClose: true,
      data: {
        widgetId: widget.id
      }
    });
    e.stopPropagation();
  }
}
