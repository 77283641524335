import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CacheService } from '../_services/cache.service';
import { TaskProgress } from '../_interfaces/Other';
import { FirebaseService } from '../_services/firebase.service';
import { status } from '../_interfaces/Task';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { TaskListModalComponent } from '../task-list-modal/task-list-modal.component';

@Component({
  selector: 'app-task-progress-modal',
  templateUrl: './task-progress-modal.component.html',
  styleUrl: './task-progress-modal.component.scss'
})
export class TaskProgressModalComponent implements OnInit {
  startDate!: Date;
  endDate!: Date;
  taskProgress: TaskProgress = {};
  statusList: (status | 'All')[] = ['Backlog', 'Design', 'Review', 'Develop', 'Test', 'Failed', 'Mergeable', 'Passed', 'Approved', 'All'];
  statusClassMap = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Develop: 'text-bg-primary',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Mergeable: 'text-bg-purple',
    Passed: 'text-bg-dark-success',
    Approved: 'text-bg-success',
    All: 'text-bg-dark'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      from: Date;
      to: Date;
    },
    public cc: CacheService,
    public fbs: FirebaseService,
    private _dialog: DialogManagerService
  ) {}

  ngOnInit(): void {
    this.startDate = this.data.from || new Date();
    this.endDate = this.data.to || new Date();
    this.taskProgress = this.cc.getTaskProgress(this.startDate, this.endDate);
  }

  update(): void {}

  getTooltip(status: status | 'All', uid: string): string {
    return status;
  }

  getNumber(status: status | 'All', uid: string, direction: 'up' | 'down'): number {
    return this.taskProgress[uid]?.[direction]?.[status]?.size || 0;
  }

  showTasks(uid: string, direction: 'up' | 'down'): void {
    const fids = Array.from(this.taskProgress[uid]?.[direction]?.All || []);
    this._dialog.openDialog(TaskListModalComponent, {
      data: {
        title: `${this.fbs.getDisplayName(uid)}'s Features - ${direction.toUpperCase()}`,
        tasks: this.cc.getFeaturesByFids(fids),
        status: 'All'
      }
    });
  }
}
