import { CommonModule } from '@angular/common';
import { Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subject, takeUntil } from 'rxjs';
import { JsTask } from 'src/app/_interfaces/Task';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { getNewId } from 'src/app/shared/utils';
import _ from 'lodash';
import { CacheService } from 'src/app/_services/cache.service';
import { taskPrioritiesList, taskStatusList, taskSubStatusList, taskTypesList } from 'src/app/shared/status';
import { MatIconButton } from '@angular/material/button';
import { MatFormField, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { MatButtonToggle, MatButtonToggleGroup } from '@angular/material/button-toggle';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { DailyPlanDetailComponent } from 'src/app/daily-plan-detail/daily-plan-detail.component';
import { SharedFunctionService } from 'src/app/_services/shared-function.service';
import { MatGridList, MatGridTile } from '@angular/material/grid-list';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { ActivityService } from 'src/app/_services/activity.service';
import { DateFnsModule } from 'ngx-date-fns';
import { SpecStatusCountsOfTaskComponent } from 'src/app/spec-status-counts-of-task/spec-status-counts-of-task.component';
import { TranslationStatusCountsOfTaskComponent } from 'src/app/translation-status-counts-of-task/translation-status-counts-of-task.component';
import { ListDailysOfTaskComponent } from 'src/app/list-dailys-of-task/list-dailys-of-task.component';
import { SelectConfigComponent } from 'src/app/shared/components/select-config/select-config.component';

@Component({
  selector: 'app-feature-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatIcon,
    MatSuffix,
    MatIconButton,
    MatButtonToggleGroup,
    MatButtonToggle,
    MatGridList,
    MatGridTile,
    CdkTextareaAutosize,
    DateFnsModule,
    SelectComponent,
    SpecStatusCountsOfTaskComponent,
    TranslationStatusCountsOfTaskComponent,

  ],
  templateUrl: './feature-form.component.html',
  styleUrl: './feature-form.component.scss'
})

export class FeatureFormComponent {

  mode: 'new' | 'edit';
  featureForm: FormGroup;
  @ViewChild('feForm') feForm!: NgForm;
  featureInitial: JsTask;
  unSubscribe = new Subject<void>();
  taskTypes = taskTypesList;
  taskPriorities = taskPrioritiesList;
  taskStatuses = taskStatusList;
  taskSubStatuses = taskSubStatusList;
  public currentRegression = '';
  public regressionResult = ''; //PASS, FAIL, '';
  otherFeatures: JsTask[] = [];
  showTimeLogs = false;
  isUpdate: boolean = false;
  noteOptions: { [type: string]: boolean } = {
    productOwnerNote: false,
    designerNote: false,
    developerNote: false,
    testerNote: false
  };

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'new' | 'edit', featureValue: JsTask },
    public fbs: FirebaseService,
    private fbo: FirebaseOptimisticService,
    public dialogRef: MatDialogRef<FeatureFormComponent>,
    public dialog: MatDialog,
    private confirm: ConfirmService,
    public cc: CacheService,
    public sharedFunc: SharedFunctionService,
    private as: ActivityService
  ) {

    this.mode = data.mode;
    this.featureInitial = data.mode == 'new' ? this.initiateFeatureForm() : this.initiateFeatureForm(data.featureValue);
    this.featureForm = this.fb.group({
      tid: new FormControl(this.featureInitial.tid),
      title: new FormControl(this.featureInitial.title, Validators.required),
      description: new FormControl(this.featureInitial.description, Validators.required),
      assignedTo: new FormControl(this.featureInitial.assignedTo),
      priority: new FormControl(this.featureInitial.priority),
      status: new FormControl(this.featureInitial.status),
      subStatus: new FormControl(this.featureInitial.subStatus),
      type: new FormControl(this.featureInitial.type),
      order: new FormControl(this.featureInitial.order, [Validators.required, Validators.min(0), Validators.max(100000)]),
      includePatterns: new FormControl(this.featureInitial.includePatterns),
      excludePatterns: new FormControl(this.featureInitial.excludePatterns),
      blockedByTids: new FormControl(this.featureInitial.blockedByTids),
      relatedTids: new FormControl(this.featureInitial.relatedTids),
      timeLogs: new FormControl(this.featureInitial.timeLogs),
      productOwner: new FormControl(this.featureInitial.productOwner),
      committedAt: new FormControl(this.featureInitial.committedAt),
      designOwner: new FormControl(this.featureInitial.designOwner),
      devOwner: new FormControl(this.featureInitial.devOwner),
      testOwner: new FormControl(this.featureInitial.testOwner),
      fromRelease: new FormControl(this.featureInitial.fromRelease),
      toRelease: new FormControl(this.featureInitial.toRelease),
      deliverable: new FormControl(this.featureInitial.deliverable),
      productOwnerNote: new FormControl(this.featureInitial.productOwnerNote),
      designerNote: new FormControl(this.featureInitial.designerNote),
      developerNote: new FormControl(this.featureInitial.developerNote),
      testerNote: new FormControl(this.featureInitial.testerNote),
      regressions: new FormControl(this.featureInitial.regressions),
      id: new FormControl(this.featureInitial.id, Validators.required),
      deletedAt: new FormControl(this.featureInitial.deletedAt)
    });

    this.noteOptions = {
      productOwnerNote: !!this.featureInitial.productOwnerNote,
      designerNote: !!this.featureInitial.designerNote,
      developerNote: !!this.featureInitial.developerNote,
      testerNote: !!this.featureInitial.testerNote
    };

    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirm.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });

    const sortedTasks = _.sortBy(this.cc.allTasks, 'tid');
    const options = sortedTasks.filter(task => task.tid !== this.featureInitial.tid);

    this.otherFeatures = _.cloneDeep(options);
  }

  showTaskDailys(task: JsTask) {
    // Show app-task-dailys component in bottom sheet
    this.dialog.open(ListDailysOfTaskComponent, {
      data: { task: task }
    });
  }

  getTotalTimeSpent(task: JsTask): number {
    return task.timeLogs.reduce((acc, curr) => acc + curr.hours, 0);
  }

  getTimeDistance(time: Date): string {
    const timeDiff = new Date().getTime() - time.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    if (hours > 24) {
      const days = Math.floor(hours / 24);
      return `${days} days ago`;
    } else if (hours > 0) {
      return `${hours} hours ago`;
    } else if (minutes > 0) {
      return `${minutes} minutes ago`;
    } else {
      return `${seconds} seconds ago`;
    }
  }

  initiateFeatureForm(feature?: JsTask): JsTask {
    return {
      tid: feature ? feature.tid : this.getNextTaskId(),
      description: feature ? feature.description : '',
      title: feature ? feature.title : '',
      assignedTo: feature ? feature.assignedTo : '',
      priority: feature ? feature.priority : 'Default',
      status: feature ? feature.status : 'Backlog',
      subStatus: feature ? feature.subStatus : 'To Do',
      type: feature ? feature.type : 'Feature',
      order: feature ? feature.order : this.getNextOrderId(),
      includePatterns: feature ? feature.includePatterns : '',
      excludePatterns: feature ? feature.excludePatterns : '',
      blockedByTids: feature ? feature.blockedByTids : [],
      relatedTids: feature ? feature.relatedTids : [],
      committedAt: feature ? feature.committedAt : null,
      timeLogs: feature ? feature.timeLogs : [],
      productOwner: feature ? feature.productOwner : '',
      designOwner: feature ? feature.designOwner : '',
      devOwner: feature ? feature.devOwner : '',
      testOwner: feature ? feature.testOwner : '',
      fromRelease: feature ? feature.fromRelease : this.cc.config?.nextRelease || null,
      toRelease: feature ? feature.toRelease : null,
      deliverable: feature ? feature.deliverable : '',
      regressions: feature ? feature.regressions : [],
      productOwnerNote: feature ? feature.productOwnerNote : '',
      designerNote: feature ? feature.designerNote : '',
      developerNote: feature ? feature.developerNote : '',
      testerNote: feature ? feature.testerNote : '',
      id: feature ? feature.id : getNewId(),
      deletedAt: feature ? feature.deletedAt : null
    }
  }

  ngOnInit() {
    this.cc.currentRegression$.pipe(takeUntil(this.unSubscribe)).subscribe(regression => {
      this.currentRegression = regression;
      this.initRegressionResult();
    });
  }

  getActivity() {
    this.as.getActivity('task', this.featureInitial)
  }

  initRegressionResult() {
    if (!this.featureInitial) return;
    const { regressions } = this.featureInitial;
    const currentRegression = this.currentRegression;
    const PASS = currentRegression + '_PASS';
    const FAIL = currentRegression + '_FAIL';
    if (regressions.includes(PASS)) {
      this.regressionResult = 'PASS';
    } else if (regressions.includes(FAIL)) {
      this.regressionResult = 'FAIL';
    } else {
      this.regressionResult = '';
    }
  }

  onRegressionChange($event: any) {
    const regression = this.featureForm.value!.regressions.filter(
      (r: string) => !r.startsWith(this.currentRegression) // Remove all regressions of current regression
    );
    if ($event.value === 'PASS' || $event.value === 'FAIL') {
      regression.push(this.currentRegression + '_' + $event.value);
      this.featureForm.get('regressions')?.setValue(regression);
    } else {
      this.featureForm.get('regressions')?.setValue(regression);
    }
  }

  isRegressionEdited(): boolean {
    if (!this.featureInitial) return false;
    if (!this.featureForm.value) return false;

    const initial = _.cloneDeep(this.featureInitial.regressions);
    const current = _.cloneDeep(this.featureForm.value.regressions);
    // sort params and paramsGroupIds
    if (initial?.length !== current?.length) return true;
    initial.sort((a: string, b: string) => (a > b ? 1 : -1));
    current.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial, current);
  }

  fromReleaseChange() {
    const fromRelease = this.featureForm.get('fromRelease')?.value;
    if (fromRelease == null) {
      this.featureForm.get('toRelease')?.setValue(null);
      this.featureForm.get('toRelease')?.setValidators([]);
      this.featureForm.get('toRelease')?.updateValueAndValidity();
    } else {
      this.featureForm.get('toRelease')?.setValidators([Validators.min(fromRelease)]);
      this.featureForm.get('toRelease')?.updateValueAndValidity();
    }
  }

  openDailyPlanModal(isEdit: boolean) {
    const planDialog = this.dialog.open(DailyPlanDetailComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: {
        from: 'model',
        disableClose: true,
        selectedDaily: isEdit ? this.cc.getDailyByTid(this.featureForm.value?.tid, 'Feature') : this.sharedFunc.createNewDailyTask(this.featureForm.value?.tid, 'Feature'),
        mode: isEdit ? 'edit' : 'new'
      },
      autoFocus: false
    });
  }

  getNextTaskId(): number {
    const maxTaskId = _.max(this.cc.allTasks.map(task => task.tid)) || 0;
    return maxTaskId + 1;
  }

  getNextOrderId(): number {
    const maxOrderId = _.max(this.cc.allTasks.map(task => task.order)) || 0;
    return maxOrderId + 1;
  }

  getFc(fcName: string): FormControl {
    return this.featureForm.get(fcName) as FormControl;
  }

  selectValues() {
    const selectConfig = this.dialog.open(SelectConfigComponent, {
      width: '600px',
      maxHeight: '90vh',
      disableClose: true,
      data: {
        title: 'Feature Note',
        options: Object.keys(this.noteOptions),
        selectedOptions: this.getNotesSelectedKeys()
      },
    });

    selectConfig.afterClosed().subscribe(result => {
      if (result !== undefined) {
        Object.keys(this.noteOptions).forEach(key => {
          this.noteOptions[key] = result.includes(key);
        });
      }
    });
  }

  getNotesSelectedKeys(): string[] {
    const trueKeys = Object.entries(this.noteOptions)
      .filter(([key, value]) => value === true)
      .map(([key]) => key);
    return trueKeys;
  }

  hasChanges() {
    const initial = _.cloneDeep(this.featureInitial);
    const current = _.cloneDeep(this.featureForm.value);
    return !_.isEqual(initial, current);
  }

  async saveChanges() {
    this.isUpdate = true;
    const featureBackup = _.cloneDeep(this.featureForm.value);
    let updateStatus = null;

    const updateId = this.featureForm.value.id;
    if (!this.featureForm.value.id) return;
    if (this.mode === 'new') {
      updateStatus = await this.fbo.createItemsOptimistic<JsTask>(
        [this.featureForm.value],
        'tasks'
      );
      if (updateStatus && updateId === this.featureInitial.id) {
        this.mode = 'edit';
        this.featureInitial = _.cloneDeep(featureBackup);
        this.cancelChanges();
      }
    } else if (this.mode === 'edit') {
      updateStatus = await this.fbo.updateItemsOptimistic<JsTask>(
        [this.featureForm.value],
        'tasks'
      );
      this.featureInitial = _.cloneDeep(this.featureForm.value);
    }
    this.isUpdate = false;

    if (!updateStatus) {
      this.featureInitial = _.cloneDeep(featureBackup);
    }
    this.dialogRef.close();
  }

  openTask(feature: JsTask) {
    const featureForm = this.dialog.open(FeatureFormComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        featureValue: feature,
      },
    });
    return featureForm.afterClosed();
  }

  async deleteHandler(isDelete: boolean) {
    const confirmed = await this.confirm.confirm(
      'Delete Feature',
      'Are you sure you want to delete this feature?'
    );
    if (confirmed) {
      if (!this.featureForm.value.id || this.mode === 'new') return;
      this.featureInitial.deletedAt = isDelete ? new Date() : null;
      await this.fbo.updateItemsOptimistic<JsTask>([this.featureInitial], 'tasks');
      if (!isDelete) {
        this.cc.restoreEntitySubject.next('task');
      }
      this.featureInitial = this.initiateFeatureForm();
      this.cancelChanges();
      this.dialogRef.close();
    }
  }

  cancelChanges() {
    this.featureForm.reset();
    this.feForm.resetForm()
    this.featureForm.patchValue(this.featureInitial);
    this.initRegressionResult();
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }

}
