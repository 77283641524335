import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal, SimpleChanges, WritableSignal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatOptgroup, MatOption, MatSelect, MatSelectTrigger, MatSuffix } from '@angular/material/select';
import * as _ from 'lodash';

@Component({
  selector: 'app-select',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatLabel,
    MatSelect,
    MatInput,
    MatOptgroup,
    MatOption,
    MatSuffix,
    MatIconButton,
    MatIcon,
    MatSelectTrigger
  ],
  templateUrl: './select.component.html',
  styleUrl: './select.component.scss'
})

export class SelectComponent {

  @Input() title: string = '';
  @Input() defaultValue!: (string | number)[] | string | number | null;
  @Input() control: FormControl = new FormControl(this.defaultValue);
  @Input() options: any[] = [];
  @Input() multiple: boolean = false;
  @Input() clear: boolean = false;
  @Input() search: boolean = false;
  @Input() disabled: boolean = false;
  @Input() noneOption: boolean = false;
  @Input() noneValue: '' | null = '';
  @Input() noneOptionText: string = 'None';
  @Input() allOption: boolean = false;
  @Input() label: string = '';
  @Input() value: string = '';
  @Input() prefix: string = '';
  @Input() prefixValue: string = '';
  @Input() disabledOptions: any[] = [];
  @Input() hiddenOptions: any[] = [];
  searchControl: FormControl = new FormControl(null);
  filteredOptions: any[] = this.options;

  @Output() selectEvent = new EventEmitter<{ label: string; value: any }>();

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['options']) {
      this.filteredOptions = this.options;
    }
  }

  get hasChanged() {
    if (Array.isArray(this.defaultValue)) {
      return !_.isEqual(
        this.control.value?.sort(),
        this.defaultValue?.sort()
      );
    } else {
      return this.control.value !== this.defaultValue
    }
  }

  getValueOfAll() {
    return this.multiple ? ['All'] : 'All';
  }

  getValueOfNone() {
    return this.multiple ? [''] : '';
  }

  filterValues() {

    // Safely get search value and split into terms
    const searchValue = this.searchControl.value || '';
    const searchTerms = this.normalizeString(searchValue)
      .split(/\s+/)
      .filter(term => term.length > 0);

    // Get selected values
    const selectedValues = this.control.value || (this.multiple ? [] : null);

    const filteredOpts = this.options.filter(opt => {
      // Get the field to search in
      const searchField = this.normalizeString(
        this.label ? opt[this.label] : opt
      );

      // Check if option is selected
      const isSelected = this.multiple
        ? (selectedValues?.length 
           ? selectedValues.includes(this.value ? opt[this.value] : opt)
           : false)
        : (selectedValues !== null 
           ? selectedValues === (this.value ? opt[this.value] : opt)
           : false);

      // Check if option matches search terms
      const matchesSearch = searchTerms.length === 0 ||  searchTerms.every(term => this.fuzzyMatch(term, searchField));

      return isSelected || matchesSearch;
    });

    this.filteredOptions = filteredOpts;
    
    // const searchValue = this.searchControl.value?.toLowerCase();
    // const searchTerms = searchValue.split(/\s+/).filter((term: string) => term.length > 0);
    // const selectedValues: any = this.control.value || this.multiple ? [] : null;

    // const filteredOpts = this.options.filter(opt => {
    //   const searchField = (this.label ? opt[this.label] : opt);
    //   const isSelected = this.multiple ? (selectedValues && selectedValues?.length !== 0 ? (selectedValues.includes(this.value ? opt[this.value] : opt)) : false) : (selectedValues ? (selectedValues == (this.value ? opt[this.value] : opt)) : false);
    //   const matchesSearch = searchTerms.every((term: string) => searchField?.includes(term));

    //   return isSelected || matchesSearch;
    // });

    // this.filteredOptions = filteredOpts;

    // Optionally, sort to bring selected items to the top
    // this.filteredTasks.sort((a, b) => {
    //   const aSelected = selectedIds.includes(a.id);
    //   const bSelected = selectedIds.includes(b.id);
    //   if (aSelected && !bSelected) return -1;
    //   if (!aSelected && bSelected) return 1;
    //   return 0;
    // });
  }

  private normalizeString(str: any): string {
    // Handle null/undefined values
    if (!str) return '';
    
    // Convert to string if it isn't already
    const text = String(str);
    
    // Normalize string: lowercase, remove diacritics, trim whitespace
    return text
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .trim();
  }

  private fuzzyMatch(needle: string, haystack: string): boolean {
    needle = this.normalizeString(needle);
    haystack = this.normalizeString(haystack);
    
    let hIndex = 0;
    for (let nIndex = 0; nIndex < needle.length; nIndex++) {
      const nChar = needle[nIndex];
      let found = false;
      
      while (hIndex < haystack.length) {
        if (haystack[hIndex] === nChar) {
          found = true;
          hIndex++;
          break;
        }
        hIndex++;
      }
      
      if (!found) return false;
    }
    
    return true;
  }

  handleSearchKeydown(event: KeyboardEvent) {
    if (event.code === 'Space') {
      event.stopPropagation();
    }
  }

  clearSearch(event: any) {
    this.searchControl.setValue(null);
    this.filterValues();
  }

  getAllValueOfOptions(): any[] {
    return this.options.map(opt => this.value ? opt[this.value] : opt);
  }

  selectChange(value?: string) {
    if (this.multiple) {
      let selectedValues = this.control.value || [];
  
      // Handle "All" option
      if (this.allOption && value === 'All') {
        this.control.setValue(this.getValueOfAll());
      } else if (this.allOption && value !== 'All') {
        const index = selectedValues.indexOf('All');
        if (index > -1) {
          selectedValues.splice(index, 1); // Remove 'All' if selected
        }
        // Handle "None" option
        if (this.noneOption && value === 'None') {
          selectedValues = [''];
        } else if (this.noneOption && value !== 'None') {
          const noneIndex = selectedValues.indexOf('');
          if (noneIndex > -1) {
            selectedValues.splice(noneIndex, 1); // Remove 'None' if selected
          }
        }
        this.control.setValue(selectedValues);
      }
    }
    this.selectEvent.emit();
  }

  getFirstIndexValue(): string | number | [] {
    return this.multiple ? this.control.value && this.control.value.length > 0 ? (this.label && this.value ? (this.control.value.includes('All') ? this.control.value : this.options.find(x => x[this.value] === this.control.value[0])[this.label]) : this.control.value[0]) : [] : this.control.value;
  }
}
