import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'compareDate',
    standalone: true
})

export class CompareDatePipe implements PipeTransform {
    transform(inputDate: string | Date): string {
        const today = new Date();
        const input = new Date(inputDate);
        
        // Set time to midnight for accurate comparison (ignoring hours, minutes, seconds)
        today.setHours(0, 0, 0, 0);
        input.setHours(0, 0, 0, 0);
        
        // Function to check if a date is a weekend (Saturday or Sunday)
        const isWeekend = (date: Date) => {
            const day = date.getDay();
            return day === 0 || day === 6; // 0 is Sunday, 6 is Saturday
        };
        
        // Return 'Today' if dates match
        if (input.toDateString() === today.toDateString()) {
            return 'Today';
        }
        
        // Calculate business days difference
        let currentDate = new Date(input);
        let dayDiff = 0;
        
        // Determine direction of calculation
        const isForward = input > today;
        const startDate = isForward ? today : input;
        const endDate = isForward ? input : today;
        
        while (startDate.toDateString() !== endDate.toDateString()) {
            // Move to next date
            startDate.setDate(startDate.getDate() + 1);
            
            // Skip weekends
            while (isWeekend(startDate)) {
                startDate.setDate(startDate.getDate() + 1);
            }
            
            // Count business days
            dayDiff++;
        }
        
        // Apply correct sign based on date comparison
        dayDiff = isForward ? dayDiff : -dayDiff;
        
        // Format the output
        return dayDiff > 0 ? `+${dayDiff}d` : `${dayDiff}d`;
    }
}
