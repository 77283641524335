<main class="log-form p-3 ri-w-800">
    <div class="d-flex justify-content-between mb-2">
        <h2 class="fw-semibold mb-0">Param Group {{ mode === 'new' ? 'Create' : 'Update' }}</h2>
        <div class="d-flex" *ngIf="this.mode === 'edit'">
            <button *ngIf="paramInitial.deletedAt" matTooltip="Restore" class="btn btn-danger btn-sm btn-restore d-flex align-items-center me-2" (click)="deleteHandler(false)"><mat-icon class="text-light">replay</mat-icon></button>
            <button *ngIf="!paramInitial.deletedAt" class="btn btn-comment btn-sm btn-success d-flex align-items-center h-100 me-2" matTooltip="Comment" (click)="sharedFunc.getComments('param', paramInitial)">
                <mat-icon class="pe-0">question_answer</mat-icon>
                <span class="ps-2" *ngIf="cc.idToParamMap[paramInitial.id]?.latestComment">{{ cc.idToParamMap[paramInitial.id].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
            </button>
            <button matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center" (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
        </div>
    </div>

    <div class="ri-dialog-content" [ngClass]="{'ri-pe-none': paramInitial.deletedAt }">
        <form [formGroup]="paramForm" #pgForm="ngForm">

            <div class="row g-1 mb-1">
                <div class="col-6">
                    <mat-form-field appearance="fill" class="w-100 mb-1"
                        [class]="{'edited' : paramForm.value.name !== paramInitial.name}">
                        <mat-label>Param Group Name</mat-label>
                        <input matInput type="text" autocomplete="off" autocorrect="off" autocapitalize="none"
                            spellcheck="false" formControlName="name" name="riParamGroupName" />
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <app-select [options]="paramGroupTypes" [title]="'Type'" [control]="getFc('type')"
                        [defaultValue]="paramInitial.type" [disabled]="true"></app-select>
                </div>
            </div>

            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : paramForm.value.note !== paramInitial.note}">
                <mat-label>Notes</mat-label>
                <textarea matInput formControlName="note" name="riParamNote" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>

            <div class="d-flex justify-content-between align-items-center">
                <h3 class="fw-semibold">Params</h3>
                <button type="button" class="btn btn-sm btn-primary mt-1" (click)="addParamsArray()">Add Param</button>
            </div>

            <div formArrayName="params" *ngIf="params.controls">
                <ng-container *ngFor="let row of params.controls; let i = index">

                    <div class="row py-1" [formGroupName]="i">
                        <div class="col-11">
                            <div class="d-flex">
                                <mat-form-field appearance="fill" class="w-30 pe-1" [class]="{'edited' : mode === 'new' ?  row.value.key : (!paramInitial.params[i] || row.value.key !== paramInitial.params[i].key)}">
                                    <mat-label>Param Name</mat-label>
                                    <input *ngIf="paramForm.value.type === 'Event';else logParamKeyFormControl" matInput appOnlyLowercase type="text" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" formControlName="key" name="riGroupParamsKey" max="38" maxlength="38">
                                    <ng-template #logParamKeyFormControl>
                                        <input matInput appAlphabetsWithDots type="text"  autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" formControlName="key" name="riGroupParamsKey" max="38" maxlength="38">
                                    </ng-template>
                                    
                                </mat-form-field>

                                <mat-form-field appearance="fill" class="w-30 ps-1" [class]="{'edited' : mode === 'new' ? row.value.type !== 'string' : !paramInitial.params[i] || row.value.type !== paramInitial.params[i].type}">
                                    <mat-label>Param Type</mat-label>
                                    <mat-select formControlName="type" name="riGroupParamsType">
                                        <ng-container *ngFor="let type of paramTypes">
                                            <mat-option [value]="type">{{type}}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                                <span
                                    class="ps-2 d-flex align-items-center text-muted w-40 ri-fs-12px">{{paramTypeExamples[row.value.type]}}</span>
                            </div>
                        </div>
                        <div class="col-1 d-flex align-items-center justify-content-end">
                            <button type="button" class="btn btn-sm btn btn-sm text-bg-danger ri-fs-16px lh-sm ri-btn-delete"
                                (click)="removeAction(i)" [disabled]="params.controls.length === 1">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>
                    </div>

                </ng-container>
                <mat-error class="text-center" *ngIf="paramForm.hasError('duplicate', 'params')">
                    Duplicate params are not allowed.
                </mat-error>
            </div>

        </form>
    </div>

    <div class="ri-dialog-footer d-flex justify-content-between mt-3" *ngIf="!paramInitial.deletedAt">
        <div class="">
            <button type="button" class="btn btn-sm text-bg-danger ri-fs-16px lh-sm ri-btn-delete" *ngIf="mode === 'edit'"  (click)="deleteAction()"matTooltip="Delete">
                <i class="bi bi-trash"></i>
            </button>
        </div>
        <div class="">
            <button type="button" class="btn btn-sm btn-secondary me-2" (click)="cancelChanges()">Reset</button>
            <button type="submit" class="btn btn-sm btn-primary" (click)="saveAction()"
                [disabled]="paramForm.invalid || !hasChanges()">Save</button>
        </div>
    </div>
</main>