import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { JsPublication, type } from '../_interfaces/Publication';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CacheService } from '../_services/cache.service';
import { FirebaseService } from '../_services/firebase.service';
import { JsonService } from '../_services/json.service';
import { MatDialog } from '@angular/material/dialog';
import { faArrowUpRightFromSquare, faDownload } from '@fortawesome/free-solid-svg-icons';
import { environment } from 'src/environments/environment';
import { ConfirmService } from '../_services/confirm.service';
import { JsonViewComponent } from '../shared/components/json-view/json-view.component';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'app-list-publications',
  templateUrl: './list-publications.component.html',
  styleUrls: ['./list-publications.component.scss']
})
export class ListPublicationsComponent implements OnInit, OnDestroy {
  loadingPid = 0;
  filteredPublications: JsPublication[] = [];
  publicattionsSub: any;
  faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  faDownload = faDownload;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: type },
    public cc: CacheService,
    public fbs: FirebaseService,
    public dialog: MatDialog,
    public jsonService: JsonService,
    private confirmService: ConfirmService
  ) {}

  ngOnInit(): void {
    this.publicattionsSub = this.cc.publications$.subscribe(publications => {
      const filteredPublications = publications.filter(publication => publication.type === this.data.type);
      // Sort by pid in descending order
      this.filteredPublications = filteredPublications.sort((a, b) => b.pid - a.pid);
    });
  }

  ngOnDestroy(): void {
    this.publicattionsSub?.unsubscribe();
  }

  async showDiff(pid: number): Promise<any> {
    this.loadingPid = pid;
    let leftPid = pid - 1;
    const rightPid = pid;
    const [leftData, rightData] = await Promise.all([
      this.jsonService.fetchJson(this.data.type, leftPid),
      this.jsonService.fetchJson(this.data.type, rightPid)
    ]);
    this.loadingPid = 0;
    
    const titleCasePipe = new TitleCasePipe();
    const jsonDiffDialog = this.dialog.open(JsonViewComponent, {
      autoFocus: false,
      data: {
        entity: this.data.type,
        mode: 'view',
        title:`Version diff - ${titleCasePipe.transform(this.data.type)} v${leftPid < 1 ? 0 : leftData.pid} vs v${rightData.pid}`,
        type: 'JSONDIFF',
        oldJson: leftPid < 1 ? {} : leftData || {},
        newJson: rightData || {}
      },
    });

    return jsonDiffDialog.afterClosed().toPromise();
  }

  openUrl(pid: number, e: any) {
    // const url = `https://${
    //   environment.production ? '' : 'dev.'
    // }project.internal.mevolve.app/api/${this.data.type}/${pid}`;
    let url;
    if (environment.production) {
      url = `https://assets.mevolve.app/${this.data.type}/${pid}`;
    } else {
      url = `https://dev.project.internal.mevolve.app/api/${this.data.type}/${pid}`;
    }
    window.open(url, '_blank');
    e.stopPropagation();
  }

  async download(type: type, pid: number, e: any) {
    e.stopPropagation();
    const confirmation = await this.confirmService.confirm(`Download ${type} v${pid}?`, `This will download the v${pid} of ${this.data.type} as a json file.`);
    if (!confirmation) return;
    const data = await this.jsonService.fetchJson(type, pid);
    this.jsonService.downloadJson(`${type}_v${pid}`, data);
  }
}
