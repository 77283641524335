<div *ngIf="widgetToEdit" class="d-flex flex-column h-100 w-100">
  <!-- <div class="p-3">
        <mat-form-field class="w-100" appearance="outline">
            <mat-label class="text-capitalize">{{widgetToEdit.type}} Path</mat-label>
            <input matInput [(ngModel)]="widgetToEdit.path" readonly>
            <mat-icon matSuffix fontIcon="content_copy" [cdkCopyToClipboard]="widgetToEdit.path"></mat-icon>
        </mat-form-field>
        <button mat-button color="primary">Update Changes</button>
    </div> -->
  <div class="d-flex flex-column path-bar" [ngClass]="{ 'bg-danger': currentRegression && regressionResult === 'FAIL', 'bg-success': currentRegression && regressionResult === 'PASS'}">
    <div class="d-flex justify-content-between">
      <div class="overflow-hidden me-2 path m-2">
        <div class="title">{{ getLastSegment(widgetToEdit.path) }}</div>
        <div class="subtitle opacity-75">{{ widgetToEdit.path }}</div>
      </div>
      <span class="p-2" [ngClass]="{'pe-none opacity-50': widget?.deletedAt }">
        <fa-icon
          *ngIf="isNotHidden('navigation')"
          class="icon ri-pointer pe-3 ri-pointer"
          [icon]="faLink"
          (click)="navigateToWidget(widgetToEdit.path)"
          matTooltip="Navigate in Spec Tree"></fa-icon>
        <fa-icon class="icon ri-pointer" [icon]="faCopy" [cdkCopyToClipboard]="copySpecUrl()" matTooltip="Copy Url"></fa-icon>
      </span>
    </div>

    <div class="d-flex justify-content-between px-2 pb-2 align-items-center" *ngIf="mode === 'edit' && currentRegression && !widgetToEdit.deletedAt && isNotHidden('regressions')">
      <p class="mb-0">Regression Status</p>
      <mat-button-toggle-group class="regression-type" [(ngModel)]="regressionResult" aria-label="Font Style" (change)="onRegressionChange($event)">
        <mat-button-toggle [value]="''">None</mat-button-toggle>
        <mat-button-toggle value="PASS">PASS</mat-button-toggle>
        <mat-button-toggle value="FAIL">FAIL</mat-button-toggle>
      </mat-button-toggle-group>

      <!-- <mat-form-field appearance="fill" class="w-50 ps-1" [class]="{ edited: widgetToEdit.mid !== widget?.mid }" *ngIf="isNotHidden('regressions')">
        <mat-label>Regression</mat-label>
        <mat-select
          [(value)]="regressionResult"
          (selectionChange)="onRegressionChange($event)"
          [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production' || !currentRegression">
          <mat-option [value]="''">- - -</mat-option>
          <mat-option value="PASS">PASS</mat-option>
          <mat-option value="FAIL">FAIL</mat-option>
        </mat-select>
      </mat-form-field> -->
    </div>
  </div>
  <!-- <div>
    <div class="pb-2 d-flex justify-content-between smaller-font text-black-50 text-center float-end pe-2">
      <div class=""><span>Last updated by {{getUserById(widget?.uid)?.name}} </span>
        <span>{{widgetToEdit.updatedAt |
          dfnsFormatDistanceToNow}} ago</span>
      </div>
    </div>
  </div> -->
  <div class="specs-container" >
   <div class="spec-sub-container" >
    <div class="d-flex align-items-center justify-content-between">
      <div class="text-primary ps-2 fs-5 fw-bolder d-flex align-items-center" matTooltip="Spec ID">S-{{ widgetToEdit.specId }}</div>
        <div class="d-flex" *ngIf="mode === 'edit'">
          <button *ngIf="widget?.deletedAt" matTooltip="Restore" class="btn btn-danger btn-sm btn-restore d-flex align-items-center me-2" (click)="deleteHandler(false)"><mat-icon class="text-light">replay</mat-icon></button>
          <button *ngIf="!widget?.deletedAt && isNotHidden('comment')" class="btn btn-comment btn-sm btn-success d-flex align-items-center me-2" matTooltip="Comment" (click)="sharedFunc.getComments('widget', widget)">
            <mat-icon class="pe-0">question_answer</mat-icon>
            <span class="ps-2" *ngIf="cc.idToSpecMap[widget?.id || '']?.latestComment">{{ cc.idToSpecMap[widget?.id || ''].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
          </button>
          <button matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center me-2" (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
        </div>
    </div>
    <div class="spec-body-container" [ngClass]="{'ri-pe-none': widget?.deletedAt }">
      <div class="mt-2" *ngIf="isNotHidden('path')">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.path !== widget?.path }">
          <mat-label>Path</mat-label>
          <input
            matInput
            type="text"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
            [(ngModel)]="widgetToEdit.path"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'" />
        </mat-form-field>
      </div>
    
      <div class="mt-2">
        <mat-form-field appearance="fill" class="w-50 pe-1" *ngIf="isNotHidden('status')" [class]="{ edited: widgetToEdit.status !== widget?.status }">
          <mat-label>Status</mat-label>
          <mat-select
            [(value)]="widgetToEdit.status"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"
            (selectionChange)="onStatusChange($event)">
            <ng-container *ngFor="let status of statusOptions">
              <mat-option [value]="status.value">{{ status.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="w-50 ps-1" *ngIf="isNotHidden('subStatus')" [class]="{ edited: widgetToEdit.subStatus !== widget?.subStatus }">
          <mat-label>Sub Status</mat-label>
          <mat-select
            [(value)]="widgetToEdit.subStatus"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"
            [value]="widgetToEdit.subStatus || subStatusOptions[0].value">
            <ng-container *ngFor="let sub of subStatusOptions">
              <mat-option [value]="sub.value">{{ sub.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="mt-2" *ngIf="widget">
        <mat-form-field
          appearance="fill"
          class="w-50 pe-1"
          *ngIf="isNotHidden('assignedTo')"
          [class]="{ edited: isAssignedToEdited() }">
          <mat-label>Assigned to</mat-label>
          <mat-select [(value)]="widgetToEdit[localAssignedToKey]" [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'">
            <mat-option [value]="'Unassigned'">None</mat-option>
            <ng-container *ngFor="let user of fbService.users">
              <mat-option [value]="user.value">{{ user.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <mat-form-field
        appearance="fill"
        class="w-50 pe-1"
        *ngIf="isNotHidden('tags')"
        [class]="{ edited: widgetToEdit && !isSameArray(widgetToEdit.tags, widget.tags) }">
        <mat-label>Tags</mat-label>
        <mat-select [(value)]="widgetToEdit.tags" [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'" multiple>
          <mat-select-trigger>
            {{ widgetToEdit.tags[0] || '' }}
            <span *ngIf="(widgetToEdit.tags.length || 0) > 1">+ {{ (widgetToEdit.tags.length || 0) - 1 }}</span>
          </mat-select-trigger>
          <ng-container *ngFor="let tag of cc.activeTags">
            <mat-option [value]="tag.value">{{ tag.label }}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>

        <!-- <mat-form-field appearance="fill" class="w-50 ps-1" [class]="{'edited' : widgetToEdit.mid !== widget.mid}">
          <mat-label>Release</mat-label>
          <mat-select [(value)]="widgetToEdit.mid"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'">
            <mat-option [value]="null">Unassigned</mat-option>
            <ng-container *ngFor="let ms of cc.roadmapFilterOptions">
              <mat-option [disabled]="ms.isRelease || ms.inProd" [value]="ms.value">{{ms.label}}
                <span *ngIf="ms.inProd" class="badge rounded-pill bg-primary" matTooltip="In Production">P</span>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field> -->
      </div>
      <!-- Drop down to select viewId -->
      <div class="mt-2">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.viewId !== widget?.viewId }" *ngIf="isNotHidden('viewId')">
          <mat-label>viewId</mat-label>
          <input
            #viewName
            type="text"
            placeholder="Label"
            matInput
            [(ngModel)]="widgetToEdit.viewId"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"
            (keyup)="setFilteredViewIds(viewName.value)"
            [matAutocomplete]="autolabel"
            (ngModelChange)="checkValidity()" />
          <mat-autocomplete #autolabel="matAutocomplete">
            <mat-option *ngFor="let viewId of filteredViewIds" [value]="viewId">
              {{ viewId }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
    
      <!-- // BUG -->
      <div class="mt-2" *ngIf="widgetToEdit.type === 'bug'">
        <mat-form-field
          appearance="fill"
          class="w-100"
          *ngIf="isNotHidden('description')"
          [class]="{ edited: widgetToEdit.description !== widget?.description }">
          <mat-label>Steps to reproduce</mat-label>
          <textarea
            matInput
            [(ngModel)]="widgetToEdit.description"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="9"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"></textarea>
          <app-link-icon-button [link]="widgetToEdit.description" tooltip="Open Link" matSuffix mat-icon-button></app-link-icon-button>
        </mat-form-field>
      </div>
      <div class="mt-2" *ngIf="widgetToEdit.type === 'bug'">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.bugExpected !== widget?.bugExpected }">
          <mat-label>Expected</mat-label>
          <textarea
            matInput
            [(ngModel)]="widgetToEdit.bugExpected"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="3"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"></textarea>
        </mat-form-field>
      </div>
      <div class="mt-2" *ngIf="widgetToEdit.type === 'bug'">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.bugActual !== widget?.bugActual }">
          <mat-label>Actual</mat-label>
          <textarea
            matInput
            [(ngModel)]="widgetToEdit.bugActual"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="3"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"></textarea>
        </mat-form-field>
      </div>
    
      <!-- // STATE -->
      <div class="mt-2" *ngIf="widgetToEdit.type === 'state'">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.stateFigmaFrameUrl !== widget?.stateFigmaFrameUrl }">
          <mat-label>Figma Frame URL</mat-label>
          <input
            matInput
            type="text"
            autocomplete="off"
            autocorrect="off"
            autocapitalize="none"
            spellcheck="false"
            [(ngModel)]="widgetToEdit.stateFigmaFrameUrl"
            [disabled]="
              mode === 'restore' || mode === 'activity' || mode === 'production' || !['Backlog', 'Design', 'Review', 'Correction', 'Removed'].includes(widget?.status || '')
            " />
          <app-link-icon-button [link]="widgetToEdit.stateFigmaFrameUrl" tooltip="Show in Figma" matSuffix mat-icon-button></app-link-icon-button>
          <app-clear-icon-button propertyName="stateFigmaFrameUrl" [object]="widgetToEdit" matSuffix mat-icon-button></app-clear-icon-button>
        </mat-form-field>
      </div>
      <div class="mt-2" *ngIf="widgetToEdit.type === 'state'">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.description !== widget?.description }">
          <mat-label>Description (Optional)</mat-label>
          <textarea
            matInput
            [(ngModel)]="widgetToEdit.description"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"></textarea>
        </mat-form-field>
      </div>
    
      <!-- // ACTION -->
      <div class="mt-2" *ngIf="widgetToEdit.type === 'action'">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.actionType !== widget?.actionType }">
          <mat-label>Action Type</mat-label>
          <mat-select [(value)]="widgetToEdit.actionType" [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'">
            <ng-container *ngFor="let type of actionTypes">
              <mat-option [value]="type.value">{{ type.label }}</mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>
    
      <div class="mt-2" *ngIf="widgetToEdit.type === 'action'">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.actionResult !== widget?.actionResult }">
          <mat-label>Action Result Description</mat-label>
          <textarea
            matInput
            [(ngModel)]="widgetToEdit.actionResult"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"></textarea>
        </mat-form-field>
      </div>
    
      <div class="mt-2" *ngIf="widgetToEdit.type === 'action'">
        <!-- <mat-form-field appearance="fill" class="w-100"
          [class]="{'edited' : widgetToEdit.actionResultPath !== widget?.actionResultPath}">
          <mat-label>Action Result State</mat-label>
          <input matInput type="text" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false"
            [(ngModel)]="widgetToEdit.actionResultPath"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'">
          <button *ngIf="widgetToEdit.actionResultPath" matSuffix mat-icon-button aria-label="Open in Figma"
            (click)="openState(widgetToEdit.actionResultPath)">
            <mat-icon color="primary" fontIcon="file_open" matTooltip="Open State"></mat-icon>
          </button>
          <button *ngIf="widgetToEdit.actionResultPath && mode === 'edit'" matSuffix mat-icon-button aria-label="Clear"
            (click)="widgetToEdit.actionResultPath=''">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field> -->
        <app-select-autocomplete
          label="Action Result State"
          [initialValue]="widget?.actionResultStateId"
          #actionResultStateId
          [options]="activeStateWidgets"
          searchKey="path"
          valueKey="id"
          [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"
          [clear] = true
          (onSelect)="onActionResultStateIdChange($event)"></app-select-autocomplete>
      </div>
    
      <!-- // RULE -->
      <div class="mt-2" *ngIf="widgetToEdit.type === 'rule'">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.rule !== widget?.rule }">
          <mat-label>Rule Description</mat-label>
          <textarea
            matInput
            [(ngModel)]="widgetToEdit.rule"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"></textarea>
        </mat-form-field>
      </div>
    
      <!-- // OTHER -->
      <div class="mt-2" *ngIf="widgetToEdit.type === 'other'">
        <mat-form-field appearance="fill" class="w-100" [class]="{ edited: widgetToEdit.description !== widget?.description }">
          <mat-label>Description</mat-label>
          <textarea
            matInput
            [(ngModel)]="widgetToEdit.description"
            cdkTextareaAutosize
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="9"
            [disabled]="mode === 'restore' || mode === 'activity' || mode === 'production'"></textarea>
        </mat-form-field>
      </div>
    
      <!-- Error message -->
      <div class="text-danger text-center" role="alert">
        {{ errorMessage }}
      </div>
      <div class="d-flex justify-content-between mb-5 ps-2 me-1 mt-3">
        <div class="d-flex align-items-center">
          <app-watchers-preview
            class="py-1"
            [initialWatchers]="widget?.watchers || []"
            (onUpdateWatchers)="updateWidgetWatchers($event)"></app-watchers-preview>
        </div>
        
        <div class="d-flex align-items-center" *ngIf="mode === 'edit'">
          <button type="button" class="btn btn-outline-secondary btn-sm px-3" (click)="resetWidget()" [disabled]="!hasChanges()">
            <fa-icon class="icon" [icon]="faRotateLeft"></fa-icon>
          </button>
          <button type="button" class="btn btn-primary btn-sm px-5" [disabled]="!hasChanges() || errorMessage" (click)="updateWidget()">Save</button>
        </div>
        <div class="d-flex align-items-center" *ngIf="mode === 'restore'">
          <button type="button" class="btn btn-warning btn-sm px-5" (click)="restoreWidget()">Restore</button>
        </div>
      </div>
    
      <app-tags-preview class="px-1" [widget]="widget"></app-tags-preview>
    
      <!-- State Preview Figma -->
      <div class="m-1 mb-3 text-capitalize" *ngIf="widget?.stateFigmaPreviewUrl">
        <button
          class="btn btn-sm px-3 text-capitalize"
          (click)="previewFigma(widget)"
          [ngClass]="{ 'btn-danger': !widgetToEdit.stateFigmaPreviewValidated, 'btn-success': widgetToEdit.stateFigmaPreviewValidated }"
          *ngIf="widget?.stateFigmaPreviewUrl">
          <fa-icon class="icon py-2 pe-2" [icon]="faFigma"></fa-icon>
          {{ widget?.stateFigmaPreviewAt | dfnsFormatDistanceToNow }} ago
          <fa-icon *ngIf="widgetToEdit.stateFigmaPreviewValidated" class="icon text-light ps-2 fs-6" [icon]="faCheck"></fa-icon>
          <fa-icon *ngIf="!widgetToEdit.stateFigmaPreviewValidated" class="icon text-dark ps-2 fs-6" [icon]="faXmark"></fa-icon>
        </button>
        <button
          type="button"
          matTooltip="Reset Figma Preview"
          class="btn btn-outline-secondary btn-sm"
          (click)="resetFigmaPreview()"
          *ngIf="widgetToEdit.stateFigmaPreviewUrl && ['Backlog', 'Design', 'Review', 'Correction', 'Removed'].includes(widget?.status || '')">
          <fa-icon class="icon" [icon]="faRetweet"></fa-icon>
        </button>
        <button
          type="button"
          matTooltip="Preview History"
          class="btn btn-outline-secondary btn-sm"
          (click)="openPreviewHistory(widgetToEdit)"
          *ngIf="widget?.stateFigmaPreviewUrl">
          <fa-icon class="icon" [icon]="faClockRotateLeft"></fa-icon>
        </button>
      </div>
    
      <!-- Action Result State Preview -->
      <div class="m-1 mb-3 text-capitalize" *ngIf="actionResultStateWidget?.stateFigmaPreviewUrl">
        <button class="btn btn-review btn-sm px-3 text-capitalize" (click)="previewFigma(actionResultStateWidget)" *ngIf="actionResultStateWidget">
          <fa-icon class="icon py-2 pe-2" [icon]="faFigma"></fa-icon>
          {{ actionResultStateWidget.stateFigmaPreviewAt | dfnsFormatDistanceToNow }} ago
        </button>
        <button
          type="button"
          matTooltip="Show Action Result Widget"
          class="btn btn-outline-secondary btn-sm"
          (click)="showWidgetDetails(actionResultStateWidget)">
          View State Spec
        </button>
      </div>
    
      <div *ngIf="isNotHidden('viewBlock')">
        <app-view-block *ngIf="widget?.viewId && cc.isValidViewId(widget?.viewId!)" [viewId]="widget?.viewId || ''"></app-view-block>
        <!-- <div *ngIf="widget?.viewId" class="d-flex justify-content-between m-2 p-2 translations-block">
                <span>All Translations for viewId</span>
                <app-translation-status-counts-of-view [viewId]="widget?.viewId!"></app-translation-status-counts-of-view>
              </div>
              <div *ngIf="widget?.viewId" class="d-flex justify-content-between m-2 p-2 specs-block">
                <span>All Specs for viewId</span>
                <app-spec-type-counts-of-view [viewId]="widget?.viewId!"></app-spec-type-counts-of-view>
              </div> -->
        <div *ngIf="!widget?.viewId" class="text-center text-bg-warning m-2 p-2 missing-viewid">Missing viewId</div>
        <div *ngIf="widget?.viewId && !cc.isValidViewId(widget?.viewId!)" class="text-center text-bg-danger m-2 p-2 missing-viewid">Invalid viewId</div>
      </div>
      <app-spec-task-block *ngIf="isNotHidden('taskBlock')" [spec]="widget"></app-spec-task-block>
      <app-widget-detail-fingerprints *ngIf="isNotHidden('fingerPrints')" [widget]="widget"></app-widget-detail-fingerprints>
    
      <div class="ri-bottom-spacer"></div>
    </div>
   </div>
  </div>

  
</div>
<div class="w-100 h-100 d-flex justify-content-center align-items-center text-muted" *ngIf="!widgetToEdit">Select a path to see the details</div>
