<div class="ri-filter-row">
  <mat-form-field [class]="{'edited': diagramSearch.value}" floatLabel="always">
    <mat-label>Search</mat-label>
    <input matInput placeholder="Spec Path, Status, etc..." [formControl]="diagramSearch" autocomplete="off">
    <button *ngIf="diagramSearch.value" matSuffix mat-icon-button aria-label="Clear" (click)="diagramSearch.setValue(null);onFilterChange()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <mat-form-field class="select-control ri-max-w-200 ri-min-w-200" appearance="fill"
    [class]="{ edited: filterOptions.groupBy.selected !== 'Category' }">
    <mat-label>Group By</mat-label>
    <mat-select [(value)]="filterOptions.groupBy.selected" placeholder="Group By"
      (selectionChange)="showGroupValues = [];onFilterChange()">
      <mat-option *ngFor="let item of filterOptions.groupBy.options" [value]="item">
        {{ item }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div class="position-relative table-block">
  <table class="table w-100 position-relative mb-0">
      <thead class="header-row">
          <tr>
              <th class="header-cell sticky-top ri-pointer {{column}}" [ngClass]="{'sorted' : sortKey === column }" #theadCol scope="col" *ngFor="let column of displayedColumns" sortOrder="none" (click)="sortData(theadCol, column)">{{ column }}</th>
          </tr>
      </thead>
      <ng-container *ngIf="filterOptions.groupBy.selected === 'None'">
          <ng-container *ngTemplateOutlet="diagramTable; context: { $implicit: tableData, groupId: 'noGroup' }"></ng-container>
          <ng-container *ngTemplateOutlet="groupTotal"></ng-container>
      </ng-container>
      <ng-container *ngIf="filterOptions.groupBy.selected !== 'None'">
          <ng-container *ngFor="let group of groupValues;let i = index;">
              <tbody>
                  <tr class="ri-pointer" [attr.isOpen]="false" #tgroupHead (click)="toggleChildGroup(tgroupHead,'diagramGroup' + i)">
                      <td [attr.colspan]="displayedColumns.length" class="group-title">
                          <strong class="group-key">
                            {{ filterOptions.groupBy.selected }} -
                            {{ ['uid','createdBy', 'assignedTo'].includes(filterOptions.groupBy.selected) ? fbs.getDisplayName(group): group}} ({{ groupedDiagrams[group].length }})
                          </strong>
                      </td>
                  </tr>
              </tbody>
              <ng-container *ngTemplateOutlet="diagramTable; context: { $implicit: groupedDiagrams[group], groupId: 'diagramGroup' + i }"></ng-container>
          </ng-container>
          <ng-container *ngTemplateOutlet="groupTotal"></ng-container>
      </ng-container>
  </table>
  <app-loader [class]="'py-5 my-5'" [isLoading]="isLoading"></app-loader>
    <p class="py-5 my-5 text-center" *ngIf="isLoading === false && tableData.length === 0">No diagrams found</p>
</div>

<ng-template #diagramTable let-element let-groupId="groupId">
  <tbody [ngClass]="filterOptions.groupBy.selected === 'None' ? 'row-nogroup' : 'row-grouped'" [attr.id]="groupId" [attr.isGroupOpened]="filterOptions.groupBy.selected === 'None' ? 'true' : 'false'">
      <tr class="ri-pointer diagram-row" *ngFor="let row of element" (click)="previewFigma(row)">
        <ng-container *ngFor="let col of displayedColumns">
          <ng-container [ngSwitch]="col">
            <ng-container *ngSwitchCase="'name'">
              <td class="{{col}} td" valign="middle">{{getName(row)}}</td>
            </ng-container>
            <ng-container *ngSwitchCase="'subStatus'">
              <td class="{{col}} td" valign="middle" [ngClass]="{
                'text-bg-info': row[col] === 'To Do',
                'text-bg-primary': row[col] === 'In Progress',
                'text-bg-dark':  row[col] === 'Blocked',
                'text-bg-success': row[col] === 'Done'}">
                {{row[col] || '-'}}
              </td>
            </ng-container>
            <ng-container *ngSwitchCase="'cloudUpdatedAt'">
              <td class="{{col}} td" valign="middle">{{row[col] | dfnsFormatDistanceToNowStrict}}</td>
            </ng-container>
            <ng-container *ngSwitchCase="'deletedAt'">
              <td class="{{col}} td" valign="middle">{{(row[col] | dfnsFormatDistanceToNowStrict) || '-'}}</td>
            </ng-container>
            <ng-container *ngSwitchCase="'uid'">
              <td class="{{col}} td" valign="middle">{{fbs.getDisplayName(row[col]) || '-'}}</td>
            </ng-container>
            <ng-container *ngSwitchCase="'assignedTo'">
              <td class="{{col}} td" valign="middle">{{getAssignedTo(row) || '-'}}</td>
            </ng-container>
            <ng-container *ngSwitchCase="'category'">
              <td class="{{col}} td fw-bold" valign="middle">{{getCategory(row) || '-'}}</td>
            </ng-container>
            <ng-container *ngSwitchCase="'figjamLink'">
              <td class="{{col}} td" valign="middle">
                <app-link-icon-button [link]="row.stateFigmaFrameUrl" tooltip="Open in Figjam" matSuffix
                  mat-icon-button [noPadding]="true"></app-link-icon-button>
              </td>
            </ng-container>
            <ng-container *ngSwitchCase="'specLink'">
              <td class="{{col}} td" valign="middle">
                <fa-icon (click)="showWidgetDetails(row, $event)" class="icon py-2" [icon]="linkIcon"
                  matTooltipPosition="above" matTooltip="Edit Spec"></fa-icon>
              </td>
            </ng-container>
            <ng-container *ngSwitchDefault>
              <td class="{{col}} td" valign="middle">{{row[col] || '-'}}</td>
            </ng-container>
          </ng-container>
        </ng-container>
      </tr>
  </tbody>
</ng-template>

<ng-template #groupTotal>
  <tbody *ngIf="tableData.length !== 0">
      <tr>
          <td class="group-total py-1" [attr.colspan]="displayedColumns.length">
              <div class="position-relative">
                  <strong class="group-key">TOTAL - ({{ tableData.length }})</strong>
              </div>
          </td>
      </tr>
  </tbody>
</ng-template>
