import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, effect, model } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipModule } from '@angular/material/tooltip';
import { JsTask } from '../_interfaces/Task';
import { CacheService } from '../_services/cache.service';
import { status } from '../_interfaces/Widget';
import { customTooltipDefaults } from '../_configs/tooltip.config';

@Component({
  selector: 'app-spec-status-count-of-tasks',
  standalone: true,
  imports: [CommonModule, MatTooltipModule],
  templateUrl: './spec-status-count-of-tasks.component.html',
  styleUrl: './spec-status-count-of-tasks.component.scss',
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
  ]
})
export class SpecStatusCountOfTasksComponent {
  @Output() onUpdate = new EventEmitter();
  tasks = model<JsTask[]>();
  Backlog: number = 0;
  Design: number = 0;
  Review: number = 0;
  Correction: number = 0;
  Develop: number = 0;
  Test: number = 0;
  Failed: number = 0;
  Approve: number = 0;
  Deprecated: number = 0;
  Removed: number = 0;
  Total: number = 0;
  isEmpty: boolean = true;

  progress = {
    Backlog: 0,
    Design: 0,
    Review: 0,
    Correction: 0,
    Develop: 0,
    Test: 0,
    Failed: 0,
    Approve: 0,
    Deprecated: 0,
    Removed: 0,
    Total: 0
  };

  progressMax: number = 0;

  constructor(public cc: CacheService) {
    effect(() => {
      const tasks = this.tasks();
      const filteredWidgets = this.cc.getSpecsOfFeatures(tasks || []);
      this.isEmpty = true;
      this.Backlog = 0;
      this.Design = 0;
      this.Review = 0;
      this.Correction = 0;
      this.Develop = 0;
      this.Test = 0;
      this.Failed = 0;
      this.Approve = 0;
      this.Deprecated = 0;
      this.Removed = 0;
      this.Total = 0;
      this.progress = {
        Backlog: 0,
        Design: 0,
        Review: 0,
        Correction: 0,
        Develop: 0,
        Test: 0,
        Failed: 0,
        Approve: 0,
        Deprecated: 0,
        Removed: 0,
        Total: 0
      };
      if (filteredWidgets?.length) {
        filteredWidgets.forEach(widget => {
          switch (widget.status) {
            case 'Backlog':
              this.Backlog++;
              this.progress.Backlog = this.progress.Backlog + 0;
              break;
            case 'Design':
              this.Design++;
              this.progress.Design = this.progress.Design + 1;
              break;
            case 'Review':
              this.Review++;
              this.progress.Review = this.progress.Review + 2;
              break;
            case 'Correction':
              this.Correction++;
              this.progress.Correction = this.progress.Correction + 1;
              break;
            case 'Develop':
              this.Develop++;
              this.progress.Develop = this.progress.Develop + 3;
              break;
            case 'Test':
              this.Test++;
              this.progress.Test = this.progress.Test + 4;
              break;
            case 'Failed':
              this.Failed++;
              this.progress.Failed = this.progress.Failed + 3;
              break;
            case 'Approve':
              this.Approve++;
              this.progress.Approve = this.progress.Approve + 5;
              break;
            case 'Deprecated':
              this.Approve++;
              this.progress.Approve = this.progress.Approve + 5;
              break;
            case 'Removed':
              this.Approve++;
              this.progress.Approve = this.progress.Approve + 5;
              break;
          }
        });
        this.isEmpty = false;
        this.Total = this.Backlog + this.Design + this.Review + this.Correction + this.Develop + this.Test + this.Failed + this.Approve + this.Deprecated + this.Removed;
        this.progress.Total =
          this.progress.Backlog +
          this.progress.Design +
          this.progress.Review +
          this.progress.Correction +
          this.progress.Develop +
          this.progress.Test +
          this.progress.Failed +
          this.progress.Approve + 
          this.progress.Deprecated + 
          this.progress.Removed;
        this.progressMax = this.Total * 5;
      }

      this.onUpdate.emit();
    });
  }

  getPercentage(status: status | 'Total'): string {
    const val = this[status];
    const total = this.Total;
    if (!total || !val) return `${status} 0%`;
    return `${status} ${((val / total) * 100).toFixed(0)}%`;
  }

  getTotalPercentageProgress(): string {
    const val = this.progress.Total;
    const total = this.progressMax;
    if (!total || !val) return `Total 0%`;
    return `Total Progress ${((val / total) * 100).toFixed(0)}%`;
  }

  getPercentageProgressOnly(status: status | 'Total'): string {
    const val = this.progress[status];
    const total = this.progressMax;
    if (!total || !val) return `0%`;
    return `${((val / total) * 100).toFixed(0)}%`;
  }
}
