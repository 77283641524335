<main class="view-form p-3 ri-w-800">
  <div class="d-flex justify-content-between mb-3">
    <h2 class="fw-semibold mb-0">View {{ mode === 'add' ? 'Create' : 'Update' }}</h2>
    <div class="d-flex">
      <button *ngIf="initialView.deletedAt" matTooltip="Restore" class="btn btn-danger btn-sm btn-restore d-flex align-items-center me-2" (click)="deleteHandler(false)"><mat-icon class="text-light">replay</mat-icon></button>
      <button class="btn btn-comment btn-sm btn-success d-flex align-items-center h-100 me-2" matTooltip="Comment"
        *ngIf="this.mode === 'edit' && !viewToEdit.deletedAt" (click)="sharedFunc.getComments('view', initialView)">
        <mat-icon class="pe-0">question_answer</mat-icon>
        <span class="ps-2" *ngIf="cc.idToViewMap[initialView.id]?.latestComment">{{
          cc.idToViewMap[initialView.id].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
      </button>
      <button matTooltip="Get Activity" *ngIf="this.mode === 'edit'"
        class="btn btn-info btn-sm btn-activity d-flex align-items-center" (click)="getActivity()"><mat-icon
          class="text-light">restore</mat-icon></button>
    </div>
  </div>

  <div class="ri-dialog-content" [ngClass]="{'ri-pe-none': viewToEdit.deletedAt}">
    <div class="row g-1">
      <div class="col-6">
        <mat-form-field class="w-100" appearance="fill"
        [class]="{'edited': initialView.viewType != viewToEdit.viewType}">
        <mat-label>View Type</mat-label>
        <mat-select [(value)]="viewToEdit.viewType" (selectionChange)="onTypeChange($event.value)"
          [disabled]="!isEditable()">
          <mat-option *ngFor="let viewType of viewTypes" [value]="viewType">{{viewType}}</mat-option>
        </mat-select>
      </mat-form-field>
      </div>
      <div class="col-6">
        <mat-form-field class="w-100" appearance="fill" [class]="{'edited': initialView.status != viewToEdit.status}">
          <mat-label>View Status</mat-label>
          <mat-select [(value)]="viewToEdit.status" (selectionChange)="onStatusChange($event.value)">
            <mat-option *ngFor="let status of viewStatuses" [value]="status">{{status}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    
    <mat-form-field appearance="fill" class="w-100 pt-1"
      [class]="{'edited' : initialView.viewName !== viewToEdit.viewName}">
      <mat-label>View Name (Required)</mat-label>
      <input #viewName type="text" placeholder="Label" matInput [(ngModel)]="viewToEdit.viewName"
        [disabled]="!isEditable()" (ngModelChange)="setViewId()"
        (keyup)="setFilteredValues('viewNames', viewName.value, 'filteredViewNames')" [matAutocomplete]="autolabel">
      <mat-autocomplete #autolabel="matAutocomplete">
        <mat-option *ngFor="let option of filteredViewNames" [value]="option">
          {{option}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <div *ngIf="!errorMessage" class="text-center my-3 text-primary ri-pointer"
      [cdkCopyToClipboard]="viewToEdit.viewId || 'NO KEY'" (click)="onCopy()">{{viewToEdit.viewId || 'NO KEY'}}</div>
    <div *ngIf="errorMessage" class="text-center my-3 text-danger">{{errorMessage}}</div>

    <!-- Note -->
    <mat-form-field appearance="fill" class="w-100 mt-1" [class]="{'edited' : initialView.note !== viewToEdit.note}">
      <mat-label>Notes (Bullet Points)</mat-label>
      <textarea matInput [(ngModel)]="viewToEdit.note" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="9"></textarea>
    </mat-form-field>
  </div>

  <div class="ri-dialog-footer" [ngClass]="{'ri-pe-none': viewToEdit.deletedAt}">
    <div class="d-flex align-items-center justify-content-between mt-5" *ngIf="!initialView.deletedAt">
      <div>
        <button type="button" class="btn btn-sm text-bg-danger ri-fs-16px lh-sm ri-btn-delete" (click)="deleteHandler(true)"
          *ngIf="this.mode === 'edit'" [disabled]="deletionInProgress" matTooltip="Delete">
          <i class="bi bi-trash"></i>
        </button>
      </div>
      <div>
        <button class="btn btn-sm btn-outline-secondary" (click)="resetHandler()" [disabled]="!hasChanges()">
          Reset
        </button>
        <button class="btn btn-sm btn-primary ms-2" (click)="updateHandler()"
          [disabled]="updationInProgress || errorMessage || !isValid() || !hasChanges()">Save
        </button>
      </div>
    </div>
    <div class="mt-4 pt-2 mb-3 d-flex justify-content-between">
      <span>Specs:&nbsp;<app-spec-status-counts-of-view
          [viewId]="viewToEdit.viewId"></app-spec-status-counts-of-view></span>
      <span>Translations:&nbsp;<app-translation-status-counts-of-view
          [viewId]="viewToEdit.viewId!"></app-translation-status-counts-of-view></span>
    </div>
  </div>
</main>