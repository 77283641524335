export const taskStatusList: string[] = ['Backlog', 'Design', 'Review', 'Develop', 'Test', 'Failed', 'Mergeable', 'Passed', 'Approved'];
export const taskSubStatusList: string[] = ['To Do', 'In Progress', 'Blocked', 'Done'];
export const taskTypesList: string[] = ['Feature', 'Optimisation', 'Routine', 'Bug', 'Other'];
export const taskPrioritiesList: string[] = ['Low', 'Default', 'High', 'Critical'];
export const translationStatusList: string[] = ['Draft', 'Paused', 'Reviewed', 'Translated', 'Ready', 'Implemented', 'Failed', 'Passed', 'Deprecated', 'Removed'];
export const viewStatusList: string[] = ['Draft', 'Reviewed', 'Approved', 'Implemented'];
export const todoStatusList: string[] = ['Todo', 'Paused', 'Need Clarity', 'In Progress', 'Blocked', 'Done', 'Approved'];
export const devEventStatusList: string[]  = ['Ready', 'Implemented', 'Failed', 'Passed', 'Deprecated', 'Removed'];
export const eventStatusList: string[]  = ['Draft', 'Paused', 'Reviewed', ...devEventStatusList];
export const devLogStatusList: string[] = ['Ready', 'Implemented', 'Failed', 'Passed', 'Deprecated', 'Removed'];
export const logStatusList: string[] = ['Draft', 'Paused', 'Reviewed', ...devLogStatusList];