import { Directive, HostListener, Self } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';

@Directive({
  selector: '[appGlobalTooltip]',
  standalone: true
})
export class GlobalTooltipDirective {

  private activeTooltip: MatTooltip | null = null;

  constructor(@Self() private tooltip: MatTooltip) {}

  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.activeTooltip && this.activeTooltip !== this.tooltip) {
      this.activeTooltip.hide();
    }
    this.activeTooltip = this.tooltip;
    this.tooltip.show();  // Ensure tooltip is shown on mouse enter
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    this.tooltip.hide();  // Ensure tooltip is hidden on mouse leave
  }
}