import { Component, effect, EventEmitter, Input } from '@angular/core';
import { CacheService } from 'src/app/_services/cache.service';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { CommonModule } from '@angular/common';
import { MatIcon } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DateFnsModule } from 'ngx-date-fns';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip } from '@angular/material/tooltip';
import { JsComment, JsReply } from 'src/app/_interfaces/Comment';
import * as _ from 'lodash';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { entityEnum } from 'src/app/_enums/entity.enum';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { entity } from 'src/app/_interfaces/Activity';
import { JsEntity } from 'src/app/_interfaces/Entities';
import { ImageViewerService } from 'src/app/_services/image-viewer.service';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Subject, takeUntil } from 'rxjs';
import { AttachmentType } from 'src/app/_interfaces/Other';
import { TextareaComponent } from 'src/app/modules/shared/textarea/textarea.component';
import { customTooltipDefaults } from 'src/app/_configs/tooltip.config';

@Component({
  selector: 'app-comment',
  standalone: true,
  imports: [
    CommonModule,
    MatIcon,
    MatMenuModule,
    DateFnsModule,
    FormsModule,
    ReactiveFormsModule,
    CdkTextareaAutosize,
    MatFormField,
    MatCheckbox,
    MatLabel,
    MatInput,
    MatTooltip,
    TextareaComponent
  ],
  templateUrl: './comment.component.html',
  styleUrl: './comment.component.scss',
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
  ]
})

export class CommentComponent {

  @Input() comment!: JsComment;
  @Input() entity!: JsEntity;
  @Input() entityType!: entity;
  isViewReply: boolean = false;
  replyControl: FormControl = new FormControl('');
  resolvedBy: string | null = null;
  replyEvent = new EventEmitter<JsComment>();
  entityEnum = entityEnum;
  replyToEdit: JsReply | null = null;
  replyType: 'new' | 'edit' | 'none' = 'none';
  unSubscribe = new Subject<void>();
  mentions: string[] = Object.keys(this.fbs.tagNameToUidMap);

  constructor(public cc: CacheService, public fbs: FirebaseService, private fbo: FirebaseOptimisticService, private confirmService: ConfirmService, private imageViewer: ImageViewerService, private storage: AngularFireStorage) {

  }

  toggleResolved(e: MatCheckboxChange) {
    const isChecked = e.checked;
    this.resolvedBy = isChecked ? this.fbs.getCurrentUserId() : null;
  }

  replayComment(event: MouseEvent) {
    event.stopPropagation();
    this.replyType = 'new';
    this.replyControl.setValue('');
    this.replyToEdit = null;
  }

  async sendReply(event: MouseEvent) {
    event.stopPropagation();
    let commentData = _.cloneDeep(this.comment);
    commentData.watchers = this.getWatchers(commentData.text);
    if (this.resolvedBy) {
      commentData.resolvedBy = this.resolvedBy;
    }
    const currentUserId = this.fbs.getCurrentUserId();
    const currentWatchers = commentData.watchers || [];
    if (!currentWatchers.includes(currentUserId)) {
      commentData.watchers.push(currentUserId);
    }

    if (this.replyType === 'edit' && this.replyToEdit) {
      const replyToUpdate = commentData.replies.find(reply => reply.id === this.replyToEdit!.id);
      if (replyToUpdate) {
        replyToUpdate.text = this.replyControl.value;
        replyToUpdate.updatedAt = new Date();
        replyToUpdate.updatedBy = currentUserId;
        replyToUpdate.editedAt = new Date();
      }
    } else {
      const reply: JsReply = {
        id: this.getNextReplyId(commentData),
        text: this.replyControl.value,
        createdAt: new Date(),
        createdBy: currentUserId,
        updatedAt: null,
        updatedBy: null,
        editedAt: null
      }
      commentData.replies.push(reply);
    }

    await this.fbs.updateItems<JsComment>([commentData], 'comments');
    this.replyControl.setValue('');
    this.resolvedBy = commentData.resolvedBy;
    await this.updateRespectiveEntity(_.cloneDeep(this.entity), commentData)
    this.replyType = 'none';
  }

  getWatchers(text: string): string[] {
    const regex = /<span>(.*?)<\/span>/g;
    const names: string[] = [];
    let match;
  
    while ((match = regex.exec(text)) !== null) {
      const name = match[1].replace('@','');
      names.push(this.fbs.tagNameToUidMap[name]);
    }
  
    return names;
  }

  cancelReply() {
    this.replyType = 'none';
    this.replyControl.setValue('');
  }

  editReply(data: JsReply) {
    if (data.createdBy === this.fbs.getCurrentUserId()) {
      this.replyType = 'edit';
      this.replyControl.setValue(data.text);
      this.replyToEdit = data;
    }
  }

  async deleteReply(reply: JsReply) {
    const confirmation = await this.confirmService.confirm(
      'Delete Reply',
      `Are you sure you want to delete your reply?`
    );
    if (!confirmation) return;
    const updatedComment = _.cloneDeep(this.comment);
    updatedComment.replies = updatedComment.replies.filter((r) => r.id !== reply.id);
    this.fbs.updateItems<JsComment>([updatedComment], 'comments');
  }

  async updateCommentWatchers(watchers: string[], comment: JsComment) {
    if (!comment) return;
    const updatedComment = _.cloneDeep(comment);
    updatedComment.watchers = watchers;
    await this.fbs.updateItems<JsComment>([updatedComment], 'comments');
  }

  async updateRespectiveEntity(entity: JsEntity | any, comment: JsComment) {
    entity.latestComment = comment;
    await this.fbo.updateItemsOptimistic<JsEntity>([entity], this.entityEnum[this.entityType]);
  }

  getNextReplyId(comment: JsComment): number {
    if (!comment?.replies?.length) {
      return 1;
    }
    return (
      comment.replies.reduce((acc, reply) => {
        if (reply.id > acc) {
          return reply.id;
        }
        return acc;
      }, 0) + 1
    );
  }

  showImageFromCommentInput(
    fileName: string,
    isDeleted = false
  ) {
    this.storage.ref(`comments/${this.comment.id}/${fileName}`)
      .getDownloadURL().pipe(takeUntil(this.unSubscribe))
      .subscribe((url) => {
        this.imageViewer
          .show(
            url,
            fileName.split('.').pop() as AttachmentType,
            true,
            false,
            true
          )
      });
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
