import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})

export class LocalStorageService {

    public setUser(data: any): void {
        localStorage.setItem('user', JSON.stringify(data));
    }

    public getUser(): any {
        return JSON.parse(localStorage.getItem('user') || '{}')!;
    }

    public clearLocalStore(): void {
        localStorage.clear();
        sessionStorage.clear();
    }


}
