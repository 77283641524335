import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { JsEvent } from '../../_interfaces/Events';
import { SharedFunctionService } from '../../_services/shared-function.service';
import { EventFormComponent } from '../../events/event-form/event-form.component';
import { JsParam } from '../../_interfaces/Param';
import { JsLog } from '../../_interfaces/Log';
import { CacheService } from '../../_services/cache.service';
import { LogFormComponent } from '../../logs/log-form/log-form.component';
import { IdbServiceService } from 'src/app/_services/idb-service.service';
import { Subject, takeUntil } from 'rxjs';
import { devEventStatusList } from 'src/app/shared/status';

@Component({
  selector: 'app-params-warning-modal',
  standalone: true,
  imports: [
    CommonModule
  ],
  templateUrl: './params-warning-modal.component.html',
  styleUrl: './params-warning-modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ParamsWarningModalComponent {

  filteredEvents: WritableSignal<JsEvent[]> = signal([]);
  filteredLogs: WritableSignal<JsLog[]> = signal([]);
  selectedGroups: JsParam[] = [];
  unSubscribe = new Subject<void>();
  isDevMode: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA)
  public data: {
    selectedGroups: JsParam[];
    type: 'Log' | 'Event';
    logs: JsLog[];
    events: JsEvent[];
  },
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ParamsWarningModalComponent>,
    public sharedFunc: SharedFunctionService,
    private idb: IdbServiceService,
    public cc: CacheService) {
    this.selectedGroups = data.selectedGroups;
  }

  ngOnInit() {
    if (this.data.type === 'Log') {
      this.getAllUsedLogs();
    } else {
      this.getAllUsedEvents();
    }
  }

  getAllUsedLogs(): JsLog[] {
    this.idb.logs$.pipe(takeUntil(this.unSubscribe)).subscribe((allLogs: JsLog[] | null) => {
      if (allLogs) {
        const paramsUsed = allLogs.filter(log => log.paramsGroupIds.includes(this.selectedGroups[0].id) && !log.deletedAt);
        this.filteredLogs.set(paramsUsed);
        this.isDevMode = paramsUsed.some(log => devEventStatusList.includes(log.status));
        return paramsUsed;
      } else {
        return [];
      }
    });
    return [];
  }

  getAllUsedEvents() {
    this.idb.events$.pipe(takeUntil(this.unSubscribe)).subscribe((allEvents: JsEvent[] | null) => {
      if (allEvents) {
        const paramsUsed = allEvents.filter(event => event.paramsGroupIds.includes(this.selectedGroups[0].id) && !event.deletedAt);
        this.filteredEvents.set(paramsUsed);
        this.isDevMode = paramsUsed.some(event => devEventStatusList.includes(event.status));
        return paramsUsed;
      } else {
        return [];
      }
    });
    return [];
  }

  openEventModal(mode: 'edit', event?: JsEvent) {
    const eventForm = this.dialog.open(EventFormComponent, {
      width: '500px !important',
      height: '800px',
      maxWidth: '500px !important',
      maxHeight: '80vh',
      disableClose: true,
      data: {
        mode: mode,
        eventValue: event,
      },
    });
  }

  openLogModal(mode: 'edit', log?: JsLog) {
    const logForm = this.dialog.open(LogFormComponent, {
      width: '500px !important',
      height: '800px',
      maxWidth: '500px !important',
      maxHeight: '80vh',
      disableClose: true,
      data: {
        mode: mode,
        logValue: log,
      },
    });
  }

  ngOnDestroy() {
    this.unSubscribe?.complete();
    this.unSubscribe?.next();
  }
}
