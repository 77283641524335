import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ 
    name: 'compareDate', 
    standalone: true
})

export class CompareDatePipe implements PipeTransform {
    transform(inputDate: string | Date): string {
        const today = new Date();
        const input = new Date(inputDate);

        // Set time to midnight for accurate comparison (ignoring hours, minutes, seconds)
        today.setHours(0, 0, 0, 0);
        input.setHours(0, 0, 0, 0);

        // Calculate the difference in time (in milliseconds)
        const timeDiff = input.getTime() - today.getTime();

        // Convert time difference from milliseconds to days
        const dayDiff = Math.floor(timeDiff / (1000 * 60 * 60 * 24));

        // Return 'today' if day difference is 0
        if (dayDiff === 0) {
            return 'Today';
        }

        // Return the exact difference in days
        return dayDiff > 0 ? `${dayDiff} d` : `${dayDiff} d`;
    }
}
