<div *ngIf="dailyToEdit" class="d-flex flex-column h-100 w-100"  [ngClass]="{'p-3' : dailyPlanType === 'model' }">
  <div class="d-flex path-bar justify-content-between align-items-center p-2">
    <p class="overflow-hidden me-2 path mb-0">{{mode === 'new' ? 'Log Time' : fbService.getUserNameFromId(dailyToEdit.assignedTo)}}</p>

    <div class="d-flex" *ngIf="this.mode === 'edit'">
      <button *ngIf="dailyInitial?.deletedAt" matTooltip="Restore" class="btn btn-danger btn-sm btn-restore d-flex align-items-center me-2" (click)="deleteHandler(false)"><mat-icon class="text-light">replay</mat-icon></button>
      <!-- <button class="btn btn-comment btn-sm btn-success d-flex align-items-center me-2" matTooltip="Comment" (click)="sharedFunc.getComments('daily', dailyInitial)" *ngIf="!dailyInitial?.deletedAt">
        <mat-icon class="pe-0">question_answer</mat-icon>
        <span class="ps-2" *ngIf="cc.idToDailyMap[dailyInitial?.id || '']?.latestComment">{{ cc.idToDailyMap[dailyInitial?.id || ''].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }} ago</span>
    </button> -->
        <button matTooltip="Get Activity" class="btn btn-light btn-sm btn-activity" (click)="getActivity()"><mat-icon class="text-info">restore</mat-icon></button>
    </div>
  </div>

  <div class="daily-body" [ngClass]="{'ri-pe-none': dailyInitial?.deletedAt}">
    <!-- // Plan -->
   <div class="d-flex justify-content-between align-items-center py-1 px-2">
    <h6 class="fw-bold mb-0">Plan for</h6>
    <mat-button-toggle-group class="daily-plan-type" [(ngModel)]="dailyToEdit.planFor" aria-label="Font Style" (change)="planTypeChange($event)" [disabled]="dailyPlanType === 'model' || mode !== 'new'">
      <mat-button-toggle value="Feature">Feature</mat-button-toggle>
      <mat-button-toggle value="Todo">Todo</mat-button-toggle>
    </mat-button-toggle-group>
   </div>
  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : dailyToEdit.tid !== dailyInitial?.tid}" *ngIf="dailyToEdit.planFor === 'Feature'">
    <mat-label>Feature</mat-label>
    <input type="text" matInput [(ngModel)]="selectTaskInput" [matAutocomplete]="auto" (keyup)="setFilteredTasks()"
      [disabled]="mode === 'readonly'" [readonly]="dailyPlanType === 'model'">
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onTaskSelected($event)">
      <mat-option *ngFor="let task of filteredTasks" [value]="task.tid">
        <span class="text-primary">F-{{task.tid}}</span> {{ task.title }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field appearance="fill" class="w-100" [class]="{'edited' : dailyToEdit.tid !== dailyInitial?.tid}" *ngIf="dailyToEdit.planFor === 'Todo'">
    <mat-label>Todo</mat-label>
    <input type="text" matInput [(ngModel)]="selectTaskInput" [matAutocomplete]="autoTodo" (keyup)="setFilteredTodos()"
      [disabled]="mode === 'readonly'" [readonly]="dailyPlanType === 'model'">
    <mat-autocomplete #autoTodo="matAutocomplete" (optionSelected)="onTaskSelected($event)">
      <mat-option *ngFor="let task of filteredTodos" [value]="task.tid">
        <span class="text-primary">T-{{task.tid}}</span> {{ task.title }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- Status & hours -->
  <div class="mt-2" *ngIf="dailyToEdit">
    <mat-form-field appearance="fill" class="w-50 pe-1"
      [class]="{'edited' : dailyToEdit.status !== dailyInitial?.status}">
      <mat-label>End of Day - Status</mat-label>
      <mat-select [(value)]="dailyToEdit.status" [disabled]="mode === 'readonly'">
        <ng-container *ngFor="let status of statusOptions">
          <mat-option [value]="status.value">{{status.label}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-25 ps-1"
      [class]="{'edited' : dailyToEdit.hours !== dailyInitial?.hours}">
      <mat-label>Hours Spent</mat-label>
      <input matInput type="number" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false"
        max="8" min="0" [(ngModel)]="dailyToEdit.hours" [disabled]="mode === 'readonly'">
    </mat-form-field>
    <mat-form-field appearance="fill" class="w-25 ps-1"
      [class]="{'edited' : dailyToEdit.hoursLeft !== dailyInitial?.hoursLeft}">
      <mat-label>Hours Left</mat-label>
      <input matInput type="number" autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false"
        max="100" min="0" [(ngModel)]="dailyToEdit.hoursLeft" [disabled]="mode === 'readonly'">
    </mat-form-field>
  </div>

  <!-- // EOD Note -->
  <div class="mt-2" *ngIf="dailyToEdit">
    <mat-form-field appearance="fill" class="w-100"
      [class]="{'edited' : dailyToEdit.eodNote !== dailyInitial?.eodNote}">
      <mat-label>What did you do on this feature today?</mat-label>
      <textarea matInput [(ngModel)]="dailyToEdit.eodNote" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
        cdkAutosizeMinRows="2" cdkAutosizeMaxRows="9" [disabled]="mode === 'readonly'"></textarea>
    </mat-form-field>
  </div>

  </div>
  <!-- Buttons -->
  <div class="d-flex justify-content-between mx-1" *ngIf="(mode === 'edit' || mode === 'new') && !dailyInitial?.deletedAt">
    <div class="mt-3">
      <button type="button" matTooltip="Delete" class="btn btn-sm text-bg-danger ri-fs-16px lh-sm me-2" (click)="deleteHandler(true)"
        *ngIf="mode === 'edit'">
        <i class="bi bi-trash"></i>
      </button>
    </div>
    <div class="mt-3 d-flex justify-content-end">
      <button type="button" class="btn btn-outline-secondary btn-sm px-5" (click)="resetDaily()"
        [disabled]="!hasChanges()">Reset</button>
      <button type="button" class="btn btn-primary btn-sm px-5" [disabled]="!hasChanges() || hasError()"
        (click)="updateDaily()">Save</button>
    </div>
  </div>
</div>

<div class="w-100 h-100 d-flex justify-content-center align-items-center text-muted" *ngIf="!dailyToEdit">
  Select a logged time
</div>