import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { JsUser } from '../_interfaces/User';
import { FirebaseService } from './firebase.service';
import { serverTimestamp } from '@angular/fire/firestore';
import { map, Observable, of } from 'rxjs';
import { IdbServiceService } from './idb-service.service';
import { Router } from '@angular/router';
import { VersionServiceService } from './version-service.service';

@Injectable({
    providedIn: 'root',
})

export class AuthService {

    constructor(
        private localService: LocalStorageService,
        private auth: AngularFireAuth,
        private fbs: FirebaseService,
        private idb: IdbServiceService,
        private versionService: VersionServiceService,
        private router: Router
    ) {

    }

    async loginUser() {
        const res = await this.auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
        if (
            // @ts-ignore
            res.additionalUserInfo?.profile?.hd !== 'realtime-innovations.com') {
            this.logoutUser();
            return;
        } else {
            this.idb.initializeDatabase();
            this.localService.setUser(res);
            // This is true always now. It should be updae to check if the user is new or not and only update relevant fields
            if (res.additionalUserInfo?.isNewUser) {
                this.fbs.createItems<JsUser>(
                    [
                        {
                            createdAt: new Date(),
                            createdBy: res.user?.uid || '',
                            deletedAt: null,
                            // @ts-ignore
                            email: res.additionalUserInfo?.profile?.email || '',
                            id: res.user?.uid || '',
                            // @ts-ignore
                            name: res.additionalUserInfo?.profile?.name || '',
                            uid: res.user?.uid || '',
                            // @ts-ignore
                            picture: res.additionalUserInfo?.profile?.picture || '',
                            // @ts-ignore
                            domain: res.additionalUserInfo?.profile?.hd || '',
                            verifiedEmail:
                                // @ts-ignore
                                res.additionalUserInfo?.profile?.verified_email || false,
                            updatedAt: new Date(),
                            roles: [],
                            cloudUpdatedAt: serverTimestamp(),
                            standupOrder: 1000
                        }
                    ],
                    'users'
                );
            } else {
                this.fbs.updateItems<JsUser>(
                    [
                        // @ts-ignore
                        {
                            id: res.user?.uid || '',
                            updatedAt: new Date(),
                            cloudUpdatedAt: serverTimestamp()
                        }
                    ],
                    'users'
                );
            }
            return res;
        }
    }

    logoutUser() {
        this.auth.signOut();
        this.localService.clearLocalStore();
        // this.idb.clearAll();
        this.idb.deleteIndexDb();
        this.versionService.stopSubscription();
        this.idb.stopSubscription();
        this.router.navigate(['/login']);
    }

    isValidUser(): Observable<boolean> {
        return this.auth.authState.pipe(
          map(user => {
            const uid = this.localService.getUser()?.user?.uid;
            if (!!user && !!uid) {
              return true;
            } else {
              if (user && !uid) {
                console.log('User logged in but no local user found');
              }
              return false;
            }
          })
        );
      }

    get isLoggedIn(): boolean {
        const localUser = this.localService.getUser();
        if (localUser && localUser.user?.uid) {
            return true;
        } else {
            return false;
        }
    }

}
