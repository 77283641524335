// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

console.log(window.location.hostname);

export const environment = {
  firebase: {
    apiKey: 'AIzaSyAd-ny_BqxUBMu1rG2W6_YCfmqQLWBBJb0',
    authDomain: 'mevolve-project-dev.firebaseapp.com',
    projectId: 'mevolve-project-dev',
    storageBucket: 'mevolve-project-dev-assets',
    messagingSenderId: '5433032500',
    appId: '1:5433032500:web:0062aedaf3aad8f552af61',
    measurementId: 'G-H7G2ZHFWQE',
  },
  production: false,
  version: '375',
  buildDate: '2024-11-15T07:08:04.667Z',
  useEmulators: window.location.hostname === 'localhos',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
