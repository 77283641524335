import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule, MatDatepickerToggle } from '@angular/material/datepicker';
import { MatFormField, MatHint, MatLabel, MatSuffix } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { getDateString } from 'src/app/shared/utils';

@Component({
  selector: 'app-date-picker',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatHint,
    MatDatepickerModule,
    MatDatepickerToggle,
    MatSuffix
  ],
  templateUrl: './date-picker.component.html',
  styleUrl: './date-picker.component.scss'
})

export class DatePickerComponent implements OnInit {

  @Input() control: FormControl = new FormControl();
  @Input() title: string = '';
  @Input() minDate: Date | null = null;
  @Input() maxDate: Date | null = null;
  @Input() defaultValue: string | null = null;
  @Output() dateChange = new EventEmitter<string | null>();

  constructor() {
  }

  ngOnInit() {
    this.control?.valueChanges.subscribe(value => {
      if (value && value instanceof Date) {
        const formattedDate = getDateString(value);
        this.control?.setValue(formattedDate, { emitEvent: false });
      }
    });
  }
}
