import { ChangeDetectionStrategy, Component, Input, OnInit, SimpleChange } from '@angular/core';
import { CacheService } from '../_services/cache.service';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { ListTranslationsOfViewComponent } from '../list-translations-of-view/list-translations-of-view.component';
import { CommonModule } from '@angular/common';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip } from '@angular/material/tooltip';
import { customTooltipDefaults } from '../_configs/tooltip.config';

@Component({
  selector: 'app-translation-status-counts-of-view',
  templateUrl: './translation-status-counts-of-view.component.html',
  styleUrls: ['./translation-status-counts-of-view.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltip
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
  ]
})
export class TranslationStatusCountsOfViewComponent implements OnInit {
  @Input() viewId: string = '';
  total: number = 0;
  isEmpty: boolean = true;
  Draft: number = 0;
  Paused: number = 0;
  Reviewed: number = 0;
  Translated: number = 0;
  Ready = 0;
  Implemented: number = 0;
  Failed: number = 0;
  Passed: number = 0;
  Deprecated: number = 0;
  Removed: number = 0;
  totalTranslations: number = 0;
  incompleteTranslations: number = 0;
  constructor(private cc: CacheService, private _dialog: DialogManagerService) { }

  ngOnInit(): void { }

  ngOnChanges(change: SimpleChange) {
    // @ts-ignore
    if (change['viewId']) {
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.total;
    this.totalTranslations = 0;
    this.incompleteTranslations = 0;
    this.Draft = 0;
    this.Paused = 0;
    this.Reviewed = 0;
    this.Translated = 0;
    this.Ready = 0;
    this.Implemented = 0;
    this.Failed = 0;
    this.Passed = 0;
    this.Deprecated = 0;
    this.Removed = 0;

    // if (this.cc.viewIdsToTranslationIdsGroupedByStatus[this.viewId]) {
    //   Object.keys(this.cc.viewIdsToTranslationIdsGroupedByStatus[this.viewId]).forEach((status: string) => {
    //     // @ts-ignore
    //     this[status] = this.cc.viewIdsToTranslationIdsGroupedByStatus[this.viewId][status].length;
    //     // @ts-ignore
    //     this.totalTreanslations += this[status];
    //   });
    //   if (this.totalTreanslations) {
    //     this.isEmpty = false;
    //   }
    // }
    const translationsByStatus = this.cc.viewIdsToTranslationIdsGroupedByStatus[this.viewId];

    if (translationsByStatus) {
      this.totalTranslations = Object.entries(translationsByStatus).reduce((total, [status, translationIds]) => {
         // @ts-ignore
        this[status] = translationIds.length;
        return total + translationIds.length;
      }, 0);

      this.isEmpty = this.totalTranslations === 0;
    }

    this.total =
      this.Draft + this.Paused + this.Reviewed + this.Ready + this.Translated + this.Implemented + this.Failed + this.Passed + this.Deprecated + this.Removed;
  }

  showSpecList(viewId: string, status: string, e: any) {
    this._dialog.openDialog(ListTranslationsOfViewComponent, {
      data: {
        viewId,
        status,
      }
    });
    e.stopPropagation();
  }
}
