<main [ngClass]="{'ri-w-900 p-3' : type === 'modal'}">
    <!-- list all widgets using flex bootstrap 5 -->
    <div *ngIf="type === 'modal'" class="sticky-top bg-body pb-2">
        <p class="fs-6">Daily Progress</p>
        <div class="mt-3 text-primary d-flex justify-content-between fw-bolder">
            <span class="text-start">{{ sharedFunc.getFormattedDate(todayDate) }}</span>
            <span class="text-start"></span>
            <span class="text-end">{{ cc.dailyLogHr() }} hr</span>
        </div>
    </div>
    <div class="card my-2 d-flex justify-content-between flex-column cursor" [class]="{ 'selected': selectedDailyId === item.id && type !== 'modal' && type !== 'new' }" *ngFor="let item of type === 'modal' ? cc.userDaily() : dailys; trackBy: trackById" (click)="selectDaily(item)">
        <!-- Title -->
        <div class="d-flex flex-row title-block">
            <div class="text-start w-50 px-1 py-2">
                <span class="badge rounded-pill text-black-50">{{ item.time }} - {{ item.updateTime }}</span>
                <span class="badge rounded-pill text-bg-dark ms-2"
                    matTooltip="{{ 'Total Time spent by ' + user.label }}">
                    {{ getTaskUserHours(item.tid, user.value).userHours | number : '1.0-1' }} hr
                </span>
                <span class="badge rounded-pill text-bg-secondary ms-2" matTooltip="Total Time spent by All">
                    {{ getTaskUserHours(item.tid, user.value).totalHours | number : '1.0-1' }} hr
                </span>
                <!-- <span class="ms-2" matTooltip="Task Completed" *ngIf="filterOptions.columns.selected.includes('Completed') && cc.tidTaskMap[item.tid]">
                    {{cc.tidTaskMap[item.tid].percentageCompleted || 0}}%
                </span> -->
            </div>
            <div class="w-50 p-2 d-flex justify-content-end">
                <span class="badge rounded-pill text-bg-dark me-2" matTooltip="Time spent">{{ item.hours | number :
                    '1.0-1' }} hr</span>
                <span class="badge rounded-pill me-2 {{ mapClass[item.status] }}" matTooltip="Status">{{ item.status
                    }}</span>
                <span class="badge rounded-pill text-bg-dark me-2" matTooltip="Time Left">{{ item?.hoursLeft || 0 |
                    number : '1.0-1' }} hr</span>
                <span class="text-capitalize badge rounded-pill text-black-50">
                    {{ (item.updatedAt | dfnsFormatDistanceToNowStrict) + ' ago' }}
                </span>
            </div>
        </div>

        <!-- Content -->
        <div class="w-100 content-block pb-2">
            <div class="w-50 d-inline-block p-2 align-top">
                <div class="fs-6">
                    <span matTooltip="Open Feature Details" *ngIf="item.planFor !== 'Todo'">
                        <span (click)="editTask(item.tid, $event, item.planFor)" class="text-primary">F-{{ item.tid
                            }}&nbsp;&nbsp;</span>
                        <span (click)="editTask(item.tid, $event, item.planFor)">{{ getTaskTitle(item.tid) }}</span>
                    </span>
                    <span matTooltip="Open Feature Details" *ngIf="item.planFor === 'Todo'">
                        <span (click)="editTask(item.tid, $event, item.planFor)" class="text-primary">T-{{ item.tid
                            }}&nbsp;&nbsp;</span>
                        <span (click)="editTask(item.tid, $event, item.planFor)">{{ cc.tidToTodoMap[item.tid].title }}</span>
                    </span>
                </div>
                <div *ngIf="filterOptions && cc.tidTaskMap[item.tid] && filterOptions.show.formControl.value?.length">
                    <div *ngIf="filterOptions.show.formControl.value?.includes('Status')" class="pt-3">
                      Status
                      <span class="float-end badge rounded-pill {{ statusStyleMap[cc.tidTaskMap[item.tid].status] }}">
                        {{ cc.tidTaskMap[item.tid].status }}
                      </span>
                    </div>
                    <div *ngIf="filterOptions.show.formControl.value?.includes('Assigned To')" class="pt-3">
                      Assigned To
                      <span class="float-end">
                        {{
                          fbService.getUsername(cc.tidTaskMap[item.tid].assignedTo) ||
                            '- -
                        -'
                        }}
                      </span>
                    </div>
                    <div *ngIf="filterOptions.show.formControl.value?.includes('Specs')" class="pt-3">
                      Specs&nbsp;
                      <app-spec-status-counts-of-task [task]="cc.tidTaskMap[item.tid]" class="float-end"></app-spec-status-counts-of-task>
                      &nbsp;&nbsp;
                    </div>
                    <div *ngIf="filterOptions.show.formControl.value?.includes('Translations')" class="pt-3">
                      Translations&nbsp;
                      <app-translation-status-counts-of-task
                        class="float-end"
                        [task]="cc.tidTaskMap[item.tid]"></app-translation-status-counts-of-task>
                    </div>
                  </div>
            </div>
            <div class="w-50 d-inline-block p-2 align-top" [innerText]="item.eodNote"></div>
        </div>
    </div>
</main>