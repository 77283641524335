import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { JsTranslation } from '../_interfaces/Translation';
import * as _ from 'lodash';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CacheService } from '../_services/cache.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslationDetailsModalComponent } from '../translation-details-modal/translation-details-modal.component';
import { SnackbarService } from '../_services/snackbar.service';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheet } from '@angular/material/bottom-sheet';
import { translationStatusList } from '../shared/status';

@Component({
  selector: 'app-list-translations-of-view',
  templateUrl: './list-translations-of-view.component.html',
  styleUrls: ['./list-translations-of-view.component.scss']
})
export class ListTranslationsOfViewComponent implements OnInit, OnDestroy {
  translations: JsTranslation[] = [];
  allTranslations: JsTranslation[] = [];
  statusClassMap = {
    Draft: 'text-bg-light',
    Paused: 'text-bg-dark',
    Reviewed: 'text-bg-review',
    Translated: 'text-bg-primary',
    Ready: 'text-bg-info',
    Implemented: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Passed: 'text-bg-success',
    Deprecated: 'text-bg-secondary',
    Removed: 'text-bg-dark'
  };
  data: any;
  translationSubscription: any;
  public filterOptions = {
    statusFilter: {
      options: ['All', ...translationStatusList],
      selected: 'All'
    },

    langFilter: {
      options: [...this.cc.config.supportedLanguageCodes, 'test'],
      selected: 'en'
    },
  };
  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) public dialogData: any,
    @Optional() @Inject(MAT_BOTTOM_SHEET_DATA) public bottomSheetData: any,
    private cc: CacheService,
    public dialog: MatDialog,
    private bottomSheet: MatBottomSheet,
    public snackbar: SnackbarService
  ) {
    // Determine which data source to use
    this.data = dialogData ? dialogData : bottomSheetData;
  }

  ngOnInit(): void {
    this.filterOptions.statusFilter.selected = this.data.status;
    this.initialise();
  }

  onStatusChange() {
    const allTranslations = _.cloneDeep(this.allTranslations);
    if (this.filterOptions.statusFilter.selected == 'All') {
      this.translations = allTranslations;
    } else {
      this.translations = allTranslations.filter(trans => trans.status === this.filterOptions.statusFilter.selected);
    }
  }

  initialise() {
    this.translationSubscription = this.cc.translations$.subscribe(res => {
      if (res) {
        let translations = res.filter(translation => translation.viewId === this.data.viewId && !translation.deletedAt);
        // Sort by text.en in order. Use lodash.
        this.translations = _.sortBy(translations, ['text.en.length']);
        this.allTranslations = _.cloneDeep(this.translations);
        this.onStatusChange();
      }
    });
  }

  ngOnDestroy() {
    this.translationSubscription?.unsubscribe();
  }

  openTranslation(translation: JsTranslation) {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TranslationDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      disableClose: true,
      data: {
        translationToEdit: translation
      },
      autoFocus: false
    });
    return confirmDialog.afterClosed();
  }

  showCommonTranslations(e: any) {
    this.bottomSheet.open(ListTranslationsOfViewComponent, {
      panelClass: 'ri-min-w-1000',
      data: {
        viewId: 'screen_common',
        status: 'All',
      }
    });

    e.stopPropagation();
  }

  addTranslation() {
    // Open the translation details modal
    const confirmDialog = this.dialog.open(TranslationDetailsModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: true,
      data: {
        viewId: this.data.viewId
      }
    });
    return confirmDialog.afterClosed();
  }
}
