import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, Sort } from '@angular/material/sort';
import { SvgService } from '../_services/svg.service';
import { FirebaseService } from '../_services/firebase.service';
import * as _ from 'lodash';
import { filter } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { SnackbarService } from '../_services/snackbar.service';
import { DownloadJsonService } from '../_services/download-json.service';
import { JsonService } from '../_services/json.service';
import { ListPublicationsComponent } from '../list-publications/list-publications.component';

import { JsTask } from '../_interfaces/Task';
import { CacheService } from '../_services/cache.service';
import { AppThemeConfig, Config } from '../_interfaces/Config';
import { MatDialog } from '@angular/material/dialog';
import { ColorsEditModalComponent } from '../colors-edit-modal/colors-edit-modal.component';
import { sortNestedColors, sortObjectKeys } from '../shared/utils';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { JsWidget } from '../_interfaces/Widget';
import { ListPreviewsComponent } from '../list-previews/list-previews.component';

type TableDataElement = {
  name: string;
  value: string;
  [key: string]: string;
};

@Component({
  selector: 'app-color-table',
  templateUrl: './color-table.component.html',
  styleUrls: ['./color-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ColorTableComponent implements OnInit, OnDestroy {
  configSubscription: any;
  widgetSubscription: any;
  themeInfo: AppThemeConfig = {} as any;
  availableColorModeNames: string[] = [];
  tableData: MatTableDataSource<TableDataElement> = new MatTableDataSource();
  displayedColumns: string[] = ['name'];
  filterValue: string = '';
  duplicateColors: { [key: string]: string[] } = {}; // { 'Blue Light': [ '#F2F2F2', '#A1A1A1' ], 'Green Dark': [ '#F2F2F2', '#A1A1A1' ], ...}
  colorCountsFromWidgetPreviews: { [key: string]: number } = {};
  invalidColorCountsFromWidgetPreviews: { [key: string]: number } = {};
  validStateWidgetsWithPreview: JsWidget[] = [];

  @ViewChild(MatSort, { static: true }) sort: MatSort = new MatSort();

  ngAfterViewInit() {
    this.tableData.sort = this.sort;
    this.tableData.sortingDataAccessor = this.sortingDataAccessor;
  }

  constructor(
    private fb: FirebaseService,
    private svgService: SvgService,
    private changeRef: ChangeDetectorRef,
    private snackbar: SnackbarService,
    private json: JsonService,
    private _dialog: DialogManagerService,
    public cc: CacheService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.tableData = new MatTableDataSource();
    this.tableData.filter = '1';
    this.cc.widgets$.subscribe((widgets) => {
      if(widgets){
        this.updateColorWidgetCounts(widgets);
        this.changeRef.detectChanges();
      }
    });
    this.configSubscription = this.fb
      .getConfig()
      .subscribe((config: Config[]) => {
        this.themeInfo = config[0].themeInfo;
        const initialTableData = _.map(
          this.svgService.defaultThemeColors,
          (value, key) => {
            return {
              name: key,
              colorNumber: parseInt(key.split(' ')[1], 10),
              [this.svgService.defaultThemeMode]: value,
            };
          }
        );
        // Loop through all themes and modes and add the color values to the table data. Use loadsh where possible.
        _.forEach(this.themeInfo.themes, (theme, themeName) => {
          _.forEach(theme, (mode, modeName) => {
            const colorMap = this.svgService.getThemeColorsAsMap(
              themeName,
              modeName as 'Light' | 'Dark'
            );
            _.forEach(colorMap, (value, key) => {
              const existing = initialTableData.find((el) => el.name === key);
              if (existing) {
                existing[`${themeName} ${modeName}`] = value;
              } else {
                initialTableData.push({
                  name: key,
                  colorNumber: parseInt(key.split(' ')[1], 10),
                  [`${themeName} ${modeName}`]: value,
                });
              }
            });
          });
        });

        this.displayedColumns = [
          'name',
          ...this.svgService.availableThemeModes,
        ];

        const tableData = _.sortBy(initialTableData, ['colorNumber']);
        // @ts-ignore
        this.tableData = new MatTableDataSource(tableData);
        this.tableData.sort = this.sort;
        this.tableData.sortingDataAccessor = this.sortingDataAccessor;
        this.tableData.filter = this.filterValue;

        // // Find duplicate colors
        // this.duplicateColors = {};
        // this.availableColorModeNames.forEach((modeName) => {
        //   const colors = this.svgService.getColorsFromModeName(modeName);
        //   const duplicateColors = colors.filter(
        //     (color) => colors.filter((c) => c.value === color.value).length > 1
        //   );
        //   if (duplicateColors.length > 0) {
        //     this.duplicateColors[modeName] = duplicateColors.map(
        //       (color) => color.value
        //     );
        //   }
        // });
        // Find duplicate colors. Use lodash where possible.
        this.duplicateColors = {};
        this.svgService.availableThemeModes.forEach((modeName) => {
          const colorsArray =
            this.svgService.getThemeColorsAsArrayFromThemeMode(modeName);
          const duplicateColors = colorsArray.filter(
            (color) =>
              colorsArray.filter((c) => c.value === color.value).length > 1
          );
          if (duplicateColors.length > 0) {
            this.duplicateColors[modeName] = duplicateColors.map(
              (color) => color.value
            );
          }
        });

        console.log(this.duplicateColors);
        this.updateColorWidgetCounts(this.validStateWidgetsWithPreview);

        this.changeRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.configSubscription?.unsubscribe();
    this.widgetSubscription?.unsubscribe();
  }

  sortingDataAccessor = (data: TableDataElement, sortHeaderId: string) => {
    switch (sortHeaderId) {
      case 'name':
        return (
          (data?.['Green Dark'] &&
            this.colorCountsFromWidgetPreviews?.[data['Green Dark']]) ||
          0
        );
      default:
        return '';
    }
  };

  applyFilter(event: Event) {
    this.filterValue = (event.target as HTMLInputElement).value;
    this.tableData.filter = this.filterValue;
  }

  resetFilter() {
    this.filterValue = '';
    this.tableData.filter = this.filterValue;
  }

  editColors() {
    const confirmDialog = this.dialog.open(ColorsEditModalComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      data: this.themeInfo,
    });

    return confirmDialog.afterClosed().toPromise();
  }

  publish() {
    // Don't allow if there are duplicate colors
    if (Object.keys(this.duplicateColors).length > 0) {
      this.snackbar.show('Duplicate colors detected');
      return;
    }

    const sortedObject = sortObjectKeys(this.themeInfo);

    this.json.publishJson('colors', sortNestedColors(sortedObject));
  }

  showHistory(e: any) {
    this._dialog.openDialog(ListPublicationsComponent, {
      data: {
        type: 'colors',
      },
    });
    e.stopPropagation();
  }

  updateColorWidgetCounts(widgets: JsWidget[]) {
    const colorCountsFromWidgetPreviews: { [key: string]: number } = {};
    this.validStateWidgetsWithPreview = [];
    widgets.forEach((w) => {
      if (
        w.deletedAt !== null ||
        w.type !== 'state' ||
        !w.path.startsWith('app') ||
        !w.stateFigmaPreviewUrl
      ) {
        return;
      }

      this.validStateWidgetsWithPreview.push(w);
      const previewColors = w.stateFigmaUsedColors || [];
      previewColors.forEach((c) => {
        if (!colorCountsFromWidgetPreviews[c.toUpperCase()])
          colorCountsFromWidgetPreviews[c] = 0;
        colorCountsFromWidgetPreviews[c.toUpperCase()]++;
      });
    });

    this.colorCountsFromWidgetPreviews = _.cloneDeep(
      colorCountsFromWidgetPreviews
    );
    console.log(this.colorCountsFromWidgetPreviews);

    // Finds colors not in the themeInfo
    const invalidColorCountsFromWidgetPreviews: { [key: string]: number } = {};
    const validColors = [];
    for (const key in this.themeInfo.themes?.['Green']?.['Dark'] || {}) {
      // @ts-ignore
      validColors.push(this.themeInfo.themes['Green']['Dark'][key]);
    }

    for (const key in colorCountsFromWidgetPreviews) {
      if (!validColors.includes(key)) {
        invalidColorCountsFromWidgetPreviews[key] =
          colorCountsFromWidgetPreviews[key];
      }
    }

    this.invalidColorCountsFromWidgetPreviews = _.cloneDeep(
      invalidColorCountsFromWidgetPreviews
    );
    console.log(this.invalidColorCountsFromWidgetPreviews);
  }

  showListPreview(hexCode: string, title: string) {
    this._dialog.openDialog(ListPreviewsComponent, {
      minWidth: '900px',
      data: {
        title: `Previews using ${title}`,
        widgets: this.validStateWidgetsWithPreview.filter((w) =>
          w.stateFigmaUsedColors?.includes(hexCode)
        ),
      },
    });
  }
}
