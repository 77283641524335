<div class="ri-filter-row">
  <mat-form-field [class]="{'edited': filterValue}" floatLabel="always">
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Color Names, Hex Codes etc..." [(ngModel)]="filterValue" autocomplete="off">
    <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Clear" (click)="resetFilter()">
      <mat-icon>close</mat-icon>
    </button>
  </mat-form-field>
  <button class="btn btn-warning btn-sm ms-1" (click)="editColors()">
    Edit Colors
  </button>
  <button class="btn btn-secondary btn-sm ms-1" (click)="publish()">
    Publish Colors
  </button>
  <button class="btn btn-primary btn-sm ms-1" (click)="showHistory($event)">
    Version History <span class="badge rounded-pill text-bg-light"
      *ngIf="this.cc.latestPublications['colors']">{{this.cc.latestPublications['colors'].pid}}</span>
  </button>
</div>

<div class="position-relative">
  <table mat-table matSort [dataSource]="tableData" class="mat-elevation-z8">

    <!-- Columns -->
    <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col" [sticky]="col === 'name'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{col}} </th>
      <td mat-cell *matCellDef="let element" class="ps-1"
        [ngClass]="{'duplicate': $any(duplicateColors[col])?.includes(element[col])}">
        <input *ngIf="!element[col]?.startsWith('Color')" class="color-box" matInput type="color"
          [ngModel]="element[col]">&nbsp;&nbsp;
        <span class="ri-pointer" [cdkCopyToClipboard]="element[col]"
          (click)="showListPreview(element['Green Dark'], element['name'])">{{element[col]}}
          <!-- <span class="badge rounded-pill text-bg-info" *ngIf="col === 'name'">{{cc.colorWidgetCounts[element['Green
            Dark']]}}</span> -->
          <span class="badge rounded-pill text-bg-info float-end me-3"
            *ngIf="col === 'name' && colorCountsFromWidgetPreviews[element['Green Dark']] !== undefined">
            {{colorCountsFromWidgetPreviews[element['Green
            Dark']]}}</span>
        </span>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <!-- Row shown when there is no matching data. -->
    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">No data matching the filter "{{filterValue}}"</td>
    </tr>
  </table>

  <div class="m-3" *ngIf="(invalidColorCountsFromWidgetPreviews | keys).length">
    <h2 class="text-danger">Invalid Colors Used in Previews</h2>

    <div class="my-3 invalid cursor" *ngFor="let color of invalidColorCountsFromWidgetPreviews | keyvalue"
      (click)="showListPreview(color.key, color.key)">
      {{color.key}}
      <span class="badge rounded-pill text-bg-danger float-end">{{color.value}}</span>
    </div>
  </div>

</div>

<div class="spacer"></div>