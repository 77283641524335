import { Component, computed, effect, Inject, Signal, signal, WritableSignal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { CacheService } from '../_services/cache.service';
import { FirebaseService } from '../_services/firebase.service';
import { JsTask } from '../_interfaces/Task';
import { taskStatusList } from '../shared/status';
import { FormControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SelectComponent } from '../shared/components/select/select.component';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip } from '@angular/material/tooltip';
import { customTooltipDefaults } from '../_configs/tooltip.config';
import { SpecStatusCountsOfTaskComponent } from '../spec-status-counts-of-task/spec-status-counts-of-task.component';
import { FeatureFormComponent } from '../modules/project/components/features/feature-form/feature-form.component';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-task-list-modal',
  templateUrl: './task-list-modal.component.html',
  styleUrl: './task-list-modal.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    SelectComponent,
    MatTooltip,
    SpecStatusCountsOfTaskComponent
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
  ]
})

export class TaskListModalComponent {
  statusStyleMap: { [key: string]: string } = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Develop: 'text-bg-primary',
    Blocked: 'text-bg-danger',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Mergeable: 'text-bg-primary',
    Passed: 'text-bg-success',
    Approved: 'text-bg-success'
  };
  statusFilter = {
    title: 'Status',
    options: ['All', ...taskStatusList],
    formControl: new FormControl('All'),
    defaultValue: 'All'
  }
  taskList: JsTask[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      tasks: Signal<JsTask[]>;
      status: string
    },
    public cc: CacheService,
    public fbs: FirebaseService,
    private dialog: MatDialog
  ) {
    effect(() => {
      this.taskList = this.data.tasks();
      this.onStatusChange();
    });
  }

  ngOnInit() {
    this.statusFilter.formControl.setValue(this.data.status);
    this.statusFilter.defaultValue = this.data.status;
  }

  openTask(task: JsTask): void {
    const featureForm = this.dialog.open(FeatureFormComponent, {
      width: '800px',
      maxWidth: '90vw',
      maxHeight: '90vh',
      autoFocus: false,
      disableClose: true,
      data: {
        mode: 'edit',
        featureValue: task,
      },
    });
  }

  onStatusChange() {
    const allTasks = this.data.tasks();
    if (this.statusFilter.formControl.value == 'All') {
      this.taskList = allTasks;
    } else {
      this.taskList = allTasks.filter(task => task.status === this.statusFilter.formControl.value);
    }
  }
}
