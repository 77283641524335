<div class="" [ngClass]="{'ri-pe-none' :  disabled }">
    <div class="d-flex justify-content-between align-items-center mb-1">
        <h6 class="mb-0 fw-semibold">Attachments</h6>
        <div class="">
            <input #attachmentInput type="file" id="riAddAttachments" class="form-control d-none"
                accept="image/png,video/mp4,image/jpeg,image/jpg,text/*" (change)="fileChange($event)"
                [multiple]="multiple">
            <label class="image-container add-gallery-image d-block">
                <button type="button" class="btn btn-sm btn-secondary btn-attachment" matTooltip="Attachment"
                    (click)="attachmentInput.click()">
                    <mat-icon>attachment</mat-icon>
                </button>
            </label>
        </div>
    </div>
    
    <div class="asset-block" *ngIf="attachmentsControl.value?.length">
        <div class="pe-2 pb-2">
            <button *ngFor="let item of attachmentsControl.value" class="btn btn-outline-secondary mt-2 ms-2 btn-sm"
                (click)="showImageFromCommentInput(item)">{{item}}</button>
        </div>
    </div>
    <div class="asset-block new" *ngIf="newAttachments().length">
        <div class="pe-2 pb-2">
            <button *ngFor="let item of newAttachments()" (click)="showLocalImage(item)"
                class="btn btn-outline-secondary mt-2 ms-2 btn-sm">{{item.name}}</button>
        </div>
    </div>
    <div class="asset-block deleted" *ngIf="deletedAttachments().length">
        <div class="pe-2 pb-2">
            <button *ngFor="let item of deletedAttachments()" (click)="showImageFromCommentInput(item.name, true)"
                class="btn btn-outline-secondary mt-2 ms-2 btn-sm">{{item.name}}</button>
        </div>
    </div>
</div>