import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import * as _ from 'lodash';
import { ActivityCache, TaskProgress } from '../_interfaces/Other';
import { IdbServiceService } from './idb-service.service';
import { FirebaseService } from './firebase.service';
import { JsWidget, status } from '../_interfaces/Widget';
import { BehaviorSubject, combineLatest, debounceTime, distinctUntilChanged, filter, Observable, Subject, takeUntil, throttleTime } from 'rxjs';
import { JsTranslation } from '../_interfaces/Translation';
import { JsView } from '../_interfaces/View';
import { JsParam, Param } from '../_interfaces/Param';
import { JsRelease } from '../_interfaces/Release';
import { JsTask } from '../_interfaces/Task';
import { FirebaseOptimisticService } from './firebase-optimistic.service';
import { JsPublication, type } from '../_interfaces/Publication';
import { filterWidgetsWithGlobsPatterns, getcurrentLocalDateAsString, getDateString } from '../shared/utils';
import { DeliverableOwners } from '../_interfaces/Other';
import { entity, JsActivity } from '../_interfaces/Activity';
import { JsDaily } from '../_interfaces/Daily';
import { JsTodo } from '../_interfaces/Todo';
import { JsEvent } from '../_interfaces/Events';
import { JsLog } from '../_interfaces/Log';
import { JsComment } from '../_interfaces/Comment';
import { JsLeave } from '../_interfaces/Leave';
import { Computed } from '../_interfaces/Computed';

type latestPublications = {
  [key in type]: JsPublication | null;
};

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public activity: ActivityCache = {
    items: [],
    time: null,
    filters: {
      uid: [],
      entity: 'task'
    }
  };

  worker!: Worker;
  eventWorker!: Worker;
  logWorker!: Worker;

  public showTaskProgressAsPercentage = false;
  public todayTaskProgress = signal<TaskProgress>({});
  public taskStatusUpdates = signal<JsActivity[]>([]);
  public userDaily = signal<JsDaily[]>([]);
  public featureDailyHrsMap: { [key: number]: number } = {};
  public todoDailyHrsMap: { [key: number]: number } = {};
  public dailyLogHr = signal<number>(0);
  public currentDeliverables = signal<string[]>([]);
  public deliverableOwners: DeliverableOwners = {};
  public availableMainReleaseNumbers = signal([0]);
  public releases: JsRelease[] = [];
  public undeletedReleases: JsRelease[] = [];
  public allTasks: JsTask[] = [];
  public allTodos: JsTodo[] = [];
  public activeTodos: JsTodo[] = [];
  public allEvents: JsEvent[] = [];
  public activeEvents: JsEvent[] = [];
  public allEventParams: WritableSignal<JsParam[]> = signal([]);
  public allLogParams: WritableSignal<JsParam[]> = signal([]);
  public paramGroupLookup: WritableSignal<{ [paramGroupId: string]: JsParam }> = signal({});
  public allLogs: JsLog[] = [];
  public activeLogs: JsLog[] = [];
  public activeTasks: JsTask[] = [];
  public activeOrderedFeatures: JsTask[] = [];
  public publications: JsPublication[] = [];
  public views: JsView[] = [];
  public viewNames: string[] = [];
  public validViewIds: string[] = [];
  public viewsByViewId: { [key: string]: JsView } = {};
  public viewIdsToWidgetIdsGroupedByType: {
    [key: string]: { [key: string]: string[] };
  } = {};
  public viewIdsToTranslationIdsGroupedByStatus: {
    [key: string]: { [key: string]: string[] };
  } = {};
  public validViewIdToStatus: { [key: string]: string } = {};
  public params: JsParam[] = [];
  public allTranslations: JsTranslation[] = [];
  public translationsWithInvalidViewId: JsTranslation[] = [];
  public translationPropertyUniqueValues: { [key: string]: string[] } = {
    en: [],
    translationName: []
  };
  public releasesSubject = new BehaviorSubject<JsRelease[] | null>(null);
  public releases$ = this.releasesSubject.asObservable();
  public tasksSubject = new BehaviorSubject<JsTask[] | null>(null);
  public tasks$: Observable<JsTask[] | null> = this.tasksSubject.asObservable();
  public viewsSubject = new BehaviorSubject<JsView[] | null>(null);
  public views$ = this.viewsSubject.asObservable();
  public paramsSubject = new BehaviorSubject<JsParam[] | null>(null);
  public params$ = this.paramsSubject.asObservable();
  public todosSubject = new BehaviorSubject<JsTodo[] | null>(null);
  public eventsSubject = new BehaviorSubject<JsEvent[] | null>(null);
  public events$ = this.eventsSubject.asObservable();
  public logsSubject = new BehaviorSubject<JsLog[] | null>(null);
  public logs$ = this.logsSubject.asObservable();
  public todos$: Observable<JsTodo[] | null> = this.todosSubject.asObservable();
  public relatedTodoByTaskId: { [key: string]: number } = {};
  public publicationsSubject = new BehaviorSubject<JsPublication[]>([]);
  public publications$ = this.publicationsSubject.asObservable();
  public translationsSubject = new BehaviorSubject<JsTranslation[] | null>(null);
  public translations$ = this.translationsSubject.asObservable();
  public currentRegressionSubject = new BehaviorSubject<string>('');
  public currentRegression$ = this.currentRegressionSubject.asObservable();
  public currentRegression = '';
  public config: any = {};
  public allTags: string[] = [];
  public expiredTags: string[] = [];
  public activeTags: { label: string; value: string }[] = [];
  public allRegressions: string[] = [];
  public expiredRegressions: string[] = [];
  public activeRegressions: string[] = [];
  public allActiveWidgets: JsWidget[] = [];
  public deletedWidgets: JsWidget[] = [];
  public widgetsSubject = new BehaviorSubject<JsWidget[] | null>(null);
  public widgets$: Observable<JsWidget[] | null> = this.widgetsSubject.asObservable();
  public deletedWidgetsSubject = new BehaviorSubject<JsWidget[]>([]);
  public deletedWidgets$ = this.deletedWidgetsSubject.asObservable();
  public overallProgressInPercent = '0%';
  public myTaskCount = 0;
  public mySpecsRelatedToFeaturesCount = 0;
  public selectedWidgetPaths = new Set<string>();
  public widgetStatusMap: { [key: string]: status } = {};
  public fallbackTaskForBottomSheet: JsTask | null = null;
  public isSvgViewerModalOpen = false;
  public specsUsingInvalidViewIds = 0;
  public translationsUsingInvalidViewIds = 0;
  public assignedFeatures = 0;
  public assignedTodos = 0;
  public assignedAndCurrentlyWithTodos: JsTodo[] = [];
  public latestPublications: latestPublications = {
    colors: null,
    views: null,
    specs: null,
    translations: null
  };
  private dailyDialogCloseSubject = new Subject<JsDaily | null>();
  dailyDialogClose$ = this.dailyDialogCloseSubject.asObservable();
  private updateEventSubject = new Subject<JsEvent>();
  getUpdatedEvent$ = this.updateEventSubject.asObservable();
  private updateLogSubject = new Subject<JsLog>();
  getUpdatedLog$ = this.updateLogSubject.asObservable();
  private updateParamSubject = new Subject<JsParam>();
  getUpdatedParam$ = this.updateParamSubject.asObservable();
  restoreEntitySubject = new Subject<entity>();
  getRestoreEntity$ = this.restoreEntitySubject.asObservable();
  unSubscribe = new Subject<void>();
  public idToReleaseMap: { [key: string]: JsRelease } = {};
  public idToTaskMap: { [key: string]: JsTask } = {};
  public idToSpecMap: { [key: string]: JsWidget } = {};
  public idToDeletedSpecsMap: { [key: string]: JsWidget } = {};
  public idToTodoMap: { [key: string]: JsTodo } = {};
  public tidToTodoMap: { [key: string]: JsTodo } = {};
  public idToViewMap: { [key: string]: JsView } = {};
  public idToTranslationMap: { [key: string]: JsTranslation } = {};
  public idToEventMap: { [key: string]: JsEvent } = {};
  public idToLogMap: { [key: string]: JsLog } = {};
  public idToParamMap: { [key: string]: JsParam } = {};
  public idToDailyMap: { [key: string]: JsDaily } = {};
  public idToLeaveMap: { [key: string]: JsLeave } = {};
  public featureToInvalidViewIdSpec: { [key: string]: JsWidget[] } = {};
  public featureToRelatedSpecs: { [key: string]: JsWidget[] } = {};
  public featureToRelatedSpecsMap = signal<{ [key: string]: JsWidget[] }>({});
  public uidToFeaturesMap = signal<{ [key: string]: JsTask[] }>({});
  public tidTaskMap: { [key: number]: JsTask } = {};
  public tidTaskMapSignal = signal<{ [key: number]: JsTask }>({});
  public tidBlockingTaskMap: { [key: number]: JsTask[] } = {};
  public uidToTodosMap: { [key: string]: JsTodo[] } = {};
  public idToSpecMapSignal = signal<{ [id: string]: JsWidget }>({});
  public cwToTodosMap: { [key: string]: JsTodo[] } = {};
  public validEvents: JsEvent[] = [];
  public invalidEvents: JsEvent[] = [];
  public deletedEvents: JsEvent[] = [];
  public validLogs: JsLog[] = [];
  public invalidLogs: JsLog[] = [];
  public deletedLogs: JsLog[] = [];
  public computed: Computed = { id: 'computed' };
  today: string = getDateString(new Date());
  todayLeaves: JsLeave[] = [];

  // public myAssignedComments: JsComment[] = [];
  // public myAssignedCommentWidgetIds: string[] = [];

  constructor(private idb: IdbServiceService, private fbs: FirebaseService, private fbo: FirebaseOptimisticService) {
    this.getComputedDatas();
    this.getSpecsUsingInvalidViewIds();
    this.getTranslationsUsingInvalidViewIds();
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../_web-workers/generic.worker', import.meta.url), { type: 'module' });
      this.eventWorker = new Worker(new URL('../_web-workers/event.worker', import.meta.url), { type: 'module' });
      this.logWorker = new Worker(new URL('../_web-workers/log.worker', import.meta.url), { type: 'module' });
    } else {
      console.error('Web Workers are not supported in this environment.');
    }

    this.idb.statusUpdates$.pipe(takeUntil(this.unSubscribe)).subscribe((activities: JsActivity[]) => {
      const taskStatusUpdates = activities.filter(a => a.entity === 'task');
      this.taskStatusUpdates.set(taskStatusUpdates);
      const todayProgress = this.getTaskProgress(new Date(), new Date());
      console.log('todayProgress', todayProgress);
      this.todayTaskProgress.set(todayProgress);
    });

    this.idb.releases$.pipe(takeUntil(this.unSubscribe)).subscribe((releases: JsRelease[] | null) => {
      if (releases) {
        const releasesMap: { [id: string]: JsRelease } = {};
        const undeletedReleases: JsRelease[] = [];
        const sortedReleases = releases
          .map(rel => {
            // Map each release to id
            releasesMap[rel.id] = rel;
            // Collect undeleted releases
            if (rel.deletedAt === null) {
              undeletedReleases.push(rel);
            }
            return rel;
          })
          .sort((a, b) => (a.rid > b.rid ? -1 : 1)); // Sort in a single operation
        // Assign the results
        this.idToReleaseMap = releasesMap;
        this.releases = sortedReleases;
        this.undeletedReleases = undeletedReleases;

        // Notify observers with sorted releases
        this.releasesSubject.next(sortedReleases);
      }
    });

    this.idb.events$.pipe(takeUntil(this.unSubscribe)).subscribe((events: JsEvent[] | null) => {
      if (events) {
        events.forEach(event => {
          this.idToEventMap[event.id] = event;
        });
        this.eventsSubject.next(events);
      }
    });

    this.idb.logs$.pipe(takeUntil(this.unSubscribe)).subscribe((logs: JsLog[] | null) => {
      if (logs) {
        logs.forEach(log => {
          this.idToLogMap[log.id] = log;
        });
        this.logsSubject.next(logs);
      }
    });

    this.idb.dailys$.pipe(takeUntil(this.unSubscribe)).subscribe((dailys: JsDaily[]) => {
      dailys.forEach(d => {
        this.idToDailyMap[d.id] = d;
      });
      const dailyPlan = dailys.filter(
        daily => daily.deletedAt === null && daily.createdBy === this.fbs.getCurrentUserId() && daily.date === getcurrentLocalDateAsString()
      );
      this.userDaily.set(dailyPlan);
      this.dailyLogHr.set(0);
      this.featureDailyHrsMap = {};
      this.todoDailyHrsMap = {};
      dailyPlan.forEach(d => {
        this.dailyLogHr.set(this.dailyLogHr() + d.hours);
        if (d.planFor === 'Todo') {
          this.todoDailyHrsMap[d.tid] = d.hours ? d.hours : 0;
        } else {
          this.featureDailyHrsMap[d.tid] = d.hours ? d.hours : 0;
        }
      });
    });

    this.idb.params$.pipe(takeUntil(this.unSubscribe)).subscribe((params: JsParam[] | null) => {
      if (params) {
        const paramGroupLookup: { [key: string]: JsParam } = {};
        const eventParams: JsParam[] = [];
        const logParams: JsParam[] = [];
        params.forEach(param => {
          // Map param by ID
          this.idToParamMap[param.id] = param;
          // Check if the param is active (not deleted)
          if (!param.deletedAt) {
            paramGroupLookup[param.id] = param;
            // Categorize into event or log params
            if (param.type === 'Event') {
              eventParams.push(param);
            } else if (param.type === 'Log') {
              logParams.push(param);
            }
          }
        });

        // Assign the results
        this.params = params;
        this.paramGroupLookup.set(paramGroupLookup);
        this.allEventParams.set(eventParams);
        this.allLogParams.set(logParams);

        // Notify observers with the params
        this.paramsSubject.next(params);
      }
    });
    this.idb.publications$.pipe(takeUntil(this.unSubscribe)).subscribe((publications: JsPublication[]) => {
      this.publications = publications;
      this.publicationsSubject.next(publications);
    });
    this.idb.translations$.pipe(takeUntil(this.unSubscribe)).subscribe((translations: JsTranslation[] | null) => {
      if (translations) {
        translations.forEach(trans => {
          this.idToTranslationMap[trans.id] = trans;
        });
        const sortedTranslations = _.sortBy(translations, 'viewId', 'translationName');
        this.allTranslations = sortedTranslations;
        this.translationsSubject.next(sortedTranslations);
        this.setTranslationUniqueValues(sortedTranslations);
        this.setViewIdsToTranslationIdsGroupedByStatus();
      }
    });
    this.currentRegression$.pipe(takeUntil(this.unSubscribe)).subscribe(regression => {
      this.currentRegression = regression;
    });
    // this.fbs.getAssignedComments().pipe(takeUntil(this.unSubscribe)).subscribe((data) => {
    //   this.myAssignedComments = data;
    //   this.myAssignedCommentWidgetIds = data.map((c) => c.wid);
    // });
    this.getInvalidViewCountOfSpecs();
    this.getEvents();
    this.getlogs();
  }

  findMatchingLeaves(leave: JsLeave, start: Date, end: Date): boolean {
    const leaveStart = new Date(leave.startDate);
    const leaveEnd = new Date(leave.endDate);

    return (
      (leaveStart <= end && leaveEnd >= start) || // Check if leave overlaps with the given range
      (leaveStart >= start && leaveStart <= end) || // Check if leave starts within the range
      (leaveEnd >= start && leaveEnd <= end) // Check if leave ends within the range
    );
  }

  getUidToFeatures(uid: string) {
    return computed(() => this.uidToFeaturesMap()[uid] || []);
  }

  getFeaturesByFids(fids: number[]) {
    return computed(() => {
      const taskMap = this.tidTaskMapSignal();
      return fids.map(fid => taskMap[fid])
        .filter(feature => feature !== undefined);
    });
  }

  getInvalidViewCountOfSpecs() {
    combineLatest([
      this.idb.tasks$,
      this.idb.specs$,
    ]).pipe(
      takeUntil(this.unSubscribe),
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(([features, specs]) => {
      if (features && specs) {
        const currentUser = this.fbs.getCurrentUserId();
        const specsStatusForCurrentUser = this.fbs.getSpecStatusOfUser();
        const assignedTasks = features.filter(t => t.assignedTo == currentUser && !t.deletedAt);
        const specData = specs.filter(x => !x.deletedAt);

        this.worker.postMessage({
          featuresData: features,
          specData: specData
        });

        // Listen for the worker's response
        this.worker.onmessage = ({ data }) => {
          const { featureToInvalidViewSpecMap, featureToRelatedSpecMap } = data;
          this.featureToInvalidViewIdSpec = featureToInvalidViewSpecMap;
          this.featureToRelatedSpecs = featureToRelatedSpecMap;
          this.featureToRelatedSpecsMap.set(featureToRelatedSpecMap);

          this.idb.updateRecord('computed', 'computed', { featureToRelatedSpecs: this.featureToRelatedSpecs });

          // this.tasksSubject.next(this.allTasks);

          const assignedWidgets = this.getSpecsOfFeatures(assignedTasks).filter(s => specsStatusForCurrentUser.includes(s.status));
          this.mySpecsRelatedToFeaturesCount = assignedWidgets.length;
        };

        // Handle any potential errors from the worker
        this.worker.onerror = (error) => {
          console.error('Error in Web Worker:', error);
        };
      }
    });
  }

  isFeatureToRelatedSpecs(): boolean {
    return Object.keys(this.featureToRelatedSpecs).length !== 0;
  }

  isViewIdsToWidgetIdsGroupedByType(): boolean {
    return Object.keys(this.viewIdsToWidgetIdsGroupedByType).length !== 0;
  }

  isViewIdsToTranslationIdsGroupedByStatus(): boolean {
    return Object.keys(this.viewIdsToTranslationIdsGroupedByStatus).length !== 0;
  }

  getTasksByIds(taskIds: string[]): JsTask[] {
    return taskIds.map(id => this.idToTaskMap[id]);
  }

  getEvents() {
    combineLatest([this.idb.events$, this.params$])
      .pipe(
        takeUntil(this.unSubscribe), // Unsubscribe properly
        debounceTime(1000), // Add a 1000ms debounce to avoid rapid updates
        distinctUntilChanged() // Only emit if the combined values have changed
      )
      .subscribe(([events, params]) => {
        if (events && params) {

          this.eventWorker.postMessage({
            eventData: events,
            paramGroupLookup: this.paramGroupLookup()
          });

          this.allEvents = events;

          // Listen for the worker's response
          this.eventWorker.onmessage = ({ data }) => {
            const { activeEvents, validEvents, invalidEvents, deletedEvents } = data;
            this.activeEvents = activeEvents;
            this.validEvents = validEvents;
            this.invalidEvents = invalidEvents;
            this.deletedEvents = deletedEvents;
            this.eventsSubject.next(this.allEvents);
          };

          // Handle any potential errors from the worker
          this.eventWorker.onerror = error => {
            console.error('Error in Web Worker:', error);
          };
        }
      });
  }

  getSpecsUsingInvalidViewIds() {
    combineLatest([this.widgets$, this.views$])
      .pipe(
        takeUntil(this.unSubscribe), // Unsubscribe properly
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(([specs, views]) => {
        if (specs && views) {
          this.specsUsingInvalidViewIds = 0;
          if (!this.allActiveWidgets || !this.viewsByViewId) {
            return;
          }
          this.specsUsingInvalidViewIds = this.allActiveWidgets.filter(widget => widget.viewId && !this.isValidViewId(widget.viewId)).length;
        }
      });
  }

  getTranslationsUsingInvalidViewIds() {
    combineLatest([this.views$, this.idb.translations$])
      .pipe(
        takeUntil(this.unSubscribe), // Unsubscribe properly
        debounceTime(1000),
        distinctUntilChanged()
      )
      .subscribe(([views, translations]) => {
        if (views && translations) {
          this.translationsUsingInvalidViewIds = 0;
          if (!translations || !this.viewsByViewId) {
            return;
          }
          this.translationsUsingInvalidViewIds = translations.filter(
            translation => translation.viewId && !this.isValidViewId(translation.viewId) && !translation.deletedAt
          ).length;
        }
      });
  }

  getlogs() {
    combineLatest([this.idb.logs$, this.params$])
      .pipe(
        takeUntil(this.unSubscribe), // Unsubscribe properly
        debounceTime(1000), // Add a 1000ms debounce to avoid rapid updates
        distinctUntilChanged(), // Only emit if the combined values have changed
      )
      .subscribe(([logs, params]) => {
        if (logs && params) {
          this.logWorker.postMessage({
            logData: logs,
            paramGroupLookup: this.paramGroupLookup()
          });

          // Listen for the worker's response
          this.logWorker.onmessage = ({ data }) => {
            const { activeLogs, validLogs, invalidLogs, deletedLogs } = data;
            this.activeLogs = activeLogs;
            this.validLogs = validLogs;
            this.invalidLogs = invalidLogs;
            this.deletedLogs = deletedLogs;
            this.logsSubject.next(this.allLogs);
          };

          // Handle any potential errors from the worker
          this.logWorker.onerror = error => {
            console.error('Error in Web Worker:', error);
          };
        }
      });
  }

  getComputedDatas() {
    this.idb.computed$.pipe(takeUntil(this.unSubscribe)).subscribe((computed: Computed[]) => {
      let computedData = computed[0] || {};
      computedData.id = 'computed';
      this.computed = computedData;
      this.featureToRelatedSpecs = computedData?.featureToRelatedSpecs || this.featureToRelatedSpecs;
    });
  }

  getLatestComment(entityType: entity, id: string): JsComment | null {
    switch (entityType) {
      case 'release':
        return this.idToReleaseMap[id]?.latestComment || null;
      case 'task':
        return this.idToTaskMap[id]?.latestComment || null;
      case 'todo':
        return this.idToTodoMap[id]?.latestComment || null;
      case 'widget':
        return this.idToSpecMap[id]?.latestComment || null;
      case 'translation':
        return this.idToTranslationMap[id]?.latestComment || null;
      case 'view':
        return this.idToViewMap[id]?.latestComment || null;
      case 'event':
        return this.idToEventMap[id]?.latestComment || null;
      case 'log':
        return this.idToLogMap[id]?.latestComment || null;
      case 'param':
        return this.idToParamMap[id]?.latestComment || null;
      case 'daily':
        return this.idToDailyMap[id]?.latestComment || null;
      default:
        return null;
    }
  }

  getWidgetPathFromId(id: string): string | null {
    const widget = this.allActiveWidgets.find(w => w.id === id);
    if (!widget) return null;
    return widget.path;
  }

  isUserWatchingWidget(userId: string, wid: string): boolean {
    const widget = this.allActiveWidgets.find(w => w.id === wid);
    if (!widget) return false;
    return widget?.watchers?.includes(userId) || false;
  }

  getWidgetById(id: string): JsWidget | null {
    return this.allActiveWidgets.find(w => w.id === id) || null;
  }

  getDeletedWidgetById(id: string): JsWidget | null {
    return this.deletedWidgets.find(w => w.id === id) || null;
  }

  repopulateWidgetStatusMap() {
    this.widgetStatusMap = {};
    this.allActiveWidgets.forEach(w => {
      this.widgetStatusMap[w.id] = w.status;
      // Update highetspecId if needed
      if (w.specId && w.specId > this.fbo.highestSpecId) {
        this.fbo.highestSpecId = w.specId;
      }
    });
  }

  getWidgetStatus(wid: string): status | null {
    return this.widgetStatusMap[wid] || null;
  }

  setAvailableMainReleaseNumbers(config: any) {
    const nextRelease = config.nextRelease || 0;
    const minRelease = 0;
    const maxRelease = nextRelease + 6;
    const availableMainReleaseNumbers = Array.from({ length: maxRelease - minRelease + 1 }, (_, i) => i + minRelease);

    this.availableMainReleaseNumbers.set(availableMainReleaseNumbers);
  }

  setTags(config: any) {
    this.allTags = config?.allTags || [];
    this.expiredTags = config?.expiredTags || [];
    //get only non expired tags to active tags
    this.activeTags = this.allTags
      .filter(t => !this.expiredTags.includes(t))
      .map(t => {
        return {
          label: t,
          value: t
        };
      });
    // console.log('allTags', this.allTags);
    // console.log('expiredTags', this.expiredTags);
    // console.log('activeTags', this.activeTags);
  }

  setRegressions(config: any) {
    this.allRegressions = config.allRegressions || [];
    this.expiredRegressions = config.expiredRegressions || [];
    //get only non expired regressions to active regressions
    this.activeRegressions = this.allRegressions.filter(t => !this.expiredRegressions.includes(t));
    // console.log('allRegressions', this.allRegressions);
    // console.log('expiredRegressions', this.expiredRegressions);
    // console.log('activeRegressions', this.activeRegressions);
  }

  setTranslationUniqueValues(translations: JsTranslation[]) {
    const uniqueProperties = ['en', 'translationName'];
    const uniqueValues: { [key: string]: string[] } = {};
    translations.forEach(t => {
      uniqueProperties.forEach((prop: any) => {
        if (!uniqueValues[prop]) uniqueValues[prop] = [];
        if (t[prop as keyof JsTranslation] && !uniqueValues[prop].includes(t[prop as keyof JsTranslation] as string))
          uniqueValues[prop].push(t[prop as keyof JsTranslation] as string);
      });
    });

    this.translationPropertyUniqueValues = _.cloneDeep(uniqueValues);
  }

  setViewIdsToWidgetIdsGroupedByType() {
    this.viewIdsToWidgetIdsGroupedByType = {};
    this.allActiveWidgets.forEach(w => {
      if (w.deletedAt !== null) return;
      if (w.viewId && !this.viewIdsToWidgetIdsGroupedByType[w.viewId]) this.viewIdsToWidgetIdsGroupedByType[w.viewId] = {};
      if (w.viewId && !this.viewIdsToWidgetIdsGroupedByType[w.viewId][w.type]) this.viewIdsToWidgetIdsGroupedByType[w.viewId][w.type] = [];
      if (w.viewId) this.viewIdsToWidgetIdsGroupedByType[w.viewId][w.type].push(w.id);
    });
  }

  setViewIdsToTranslationIdsGroupedByStatus() {
    // this.viewIdsToTranslationIdsGroupedByStatus = {};
    // this.allTranslations.forEach(t => {
    //   if (t.deletedAt !== null) return;
    //   if (t.viewId && !this.viewIdsToTranslationIdsGroupedByStatus[t.viewId]) this.viewIdsToTranslationIdsGroupedByStatus[t.viewId] = {};
    //   if (t.viewId && !this.viewIdsToTranslationIdsGroupedByStatus[t.viewId][t.status]) this.viewIdsToTranslationIdsGroupedByStatus[t.viewId][t.status] = [];
    //   if (t.viewId) this.viewIdsToTranslationIdsGroupedByStatus[t.viewId][t.status].push(t.id);
    // });

    this.viewIdsToTranslationIdsGroupedByStatus = {};
    this.allTranslations.forEach(t => {
      if (t.deletedAt !== null || !t.viewId) return;

      // Initialize the nested structure if it doesn't exist
      const viewTranslations = (this.viewIdsToTranslationIdsGroupedByStatus[t.viewId] ||= {});
      viewTranslations[t.status] ||= [];

      // Add the translation ID
      viewTranslations[t.status].push(t.id);
    });
  }

  getUniqueViewIdsOfTask(task: JsTask | undefined): string[] {
    if (!task) return [];
    // Loop through the widgets and get unique viewIds
    const viewIds = filterWidgetsWithGlobsPatterns(this.allActiveWidgets, task.includePatterns, task.excludePatterns).map(widget => widget.viewId);

    const uniqueViewIds = [...new Set(viewIds)].filter(v => v);
    if (uniqueViewIds.length) {
      return uniqueViewIds as string[];
    } else {
      return [];
    }
  }

  getUniqueViewIdsOfFeature(task: JsTask | undefined): string[] {
    if (!task) return [];
    // Loop through the widgets and get unique viewIds
    const viewIds = this.featureToRelatedSpecs[task.id]?.map(widget => widget.viewId);

    const uniqueViewIds = [...new Set(viewIds)].filter(v => v);
    if (uniqueViewIds.length) {
      return uniqueViewIds as string[];
    } else {
      return [];
    }
  }

  getUniqueViewIdsOfSpecs(specs: JsWidget[] | undefined): string[] {
    const uniqueViewIds = specs?.map(spec => spec.viewId);
    return [...new Set(uniqueViewIds)].filter(v => v) as string[];
  }

  getUniqueViewIdsForRelease(releaseNumber: number) {
    const specs = this.getFilteredSpecsForRelease(this.allActiveWidgets, releaseNumber);
    return this.getUniqueViewIdsOfSpecs(specs);
  }

  getUniqueViewIdsNotForRelease(releaseNumber: number) {
    const specs = this.getFilteredSpecsNotForRelease(this.allActiveWidgets, releaseNumber);
    return this.getUniqueViewIdsOfSpecs(specs);
  }

  getUniqueViewIdsInDeliverable(deliverable: string) {
    const specs = this.getFilteredSpecsInDeliverable(this.allActiveWidgets, deliverable);
    return this.getUniqueViewIdsOfSpecs(specs);
  }

  getUniqueViewIdsNotInDeliverable(deliverable: string) {
    const specs = this.getFilteredSpecsNotInDeliverable(this.allActiveWidgets, deliverable);
    return this.getUniqueViewIdsOfSpecs(specs);
  }

  getTranslationsOfTask(task: JsTask): JsTranslation[] {
    const uniqueViewIds = this.getUniqueViewIdsOfFeature(task);
    // Get the translations from views is the viewIds
    let translations = this.allTranslations.filter(translation => uniqueViewIds.includes(translation.viewId) && !translation.deletedAt);
    // Sort by text.en in order. Use lodash.
    translations = _.sortBy(translations, ['text.en.length']);
    return translations;
  }

  getSpecsOfTask(task: JsTask): JsWidget[] {
    let specs = filterWidgetsWithGlobsPatterns(this.allActiveWidgets, task.includePatterns, task.excludePatterns);
    // Sort by type in order. Use lodash.
    specs = _.sortBy(specs, ['type']);
    // MOve all the 'state' widgets to the top of the list
    specs = _.sortBy(specs, widget => (widget.type === 'state' ? 0 : 1));

    return specs;
  }

  getSpecsOfTasks(tasks: JsTask[]): JsWidget[] {
    let specIds: Set<string> = new Set();
    tasks.forEach(task => {
      this.getSpecsOfTask(task).forEach(spec => {
        specIds.add(spec.id);
      });
    });
    return this.allActiveWidgets.filter(spec => specIds.has(spec.id));
  }

  getSpecsOfFeature(task: JsTask): JsWidget[] {
    let specs = this.featureToRelatedSpecs[task.id] || [];
    // Sort by type in order. Use lodash.
    specs = _.sortBy(specs, ['type']);
    // MOve all the 'state' widgets to the top of the list
    specs = _.sortBy(specs, widget => (widget.type === 'state' ? 0 : 1));
    return specs;
  }

  getSpecsOfFeatures(tasks: JsTask[]): JsWidget[] {
    const specs: JsWidget[] = [];
    tasks.forEach(task => {
      const relatedSpecs = this.getSpecsOfFeature(task) || [];
      specs.push(...relatedSpecs);
    });
    return specs;
  }

  isValidViewId(viewId: string): boolean {
    return this.viewsByViewId[viewId] && !this.viewsByViewId[viewId].deletedAt;
  }

  getNextPid(type: type): number {
    const publications = this.publications.filter(p => p.type === type);
    if (!publications.length) return 1;
    return Math.max(...publications.map(p => p.pid)) + 1;
  }

  getLatestPid(type: type): number {
    const publications = this.publications.filter(p => p.type === type);
    if (!publications.length) return 0;
    return Math.max(...publications.map(p => p.pid));
  }

  getFilteredTasksForRelease(tasks: JsTask[], releaseNumber: number | null): JsTask[] {
    if (releaseNumber === null) return tasks;
    return tasks.filter(t => {
      let isValidTask = false;
      // Has valid fromRelease
      if (t.fromRelease != null && t.fromRelease <= releaseNumber) {
        isValidTask = true;
      }

      // Has valid toRelease
      if (t.toRelease == null) {
        isValidTask = isValidTask && true;
      } else if (t.toRelease >= releaseNumber) {
        isValidTask = isValidTask && true;
      } else {
        isValidTask = false;
      }
      return isValidTask;
    });
  }

  getFilteredTasksNotForRelease(tasks: JsTask[], notInReleaseNumber: number | null): JsTask[] {
    if (notInReleaseNumber === null) return tasks;
    return tasks.filter(t => {
      let isInvalidTask = false;
      // Has invalid fromRelease
      if (t.fromRelease == null || t.fromRelease > notInReleaseNumber) {
        isInvalidTask = true;
      }

      // Has invalid toRelease
      if (t.toRelease != null && t.toRelease < notInReleaseNumber) {
        isInvalidTask = true;
      }

      return isInvalidTask;
    });
  }

  getFilteredSpecsForRelease(specs: JsWidget[], releaseNumber: number | null): JsWidget[] {
    if (releaseNumber === null) return specs;
    const filteredTasks = this.getFilteredTasksForRelease(this.allTasks, releaseNumber);
    const includedSpecIds: Set<string> = new Set();
    filteredTasks.forEach(task => {
      const specs = this.getSpecsOfTask(task);
      specs.forEach(spec => {
        includedSpecIds.add(spec.id);
      });
    });
    return specs.filter(spec => includedSpecIds.has(spec.id));
  }

  getFilteredSpecsNotForRelease(specs: JsWidget[], notInReleaseNumber: number | null): JsWidget[] {
    if (notInReleaseNumber === null) return specs;
    const filteredTasks = this.getFilteredTasksForRelease(this.allTasks, notInReleaseNumber);
    const includedSpecIds: Set<string> = new Set();
    filteredTasks.forEach(task => {
      const specs = this.getSpecsOfTask(task);
      specs.forEach(spec => {
        includedSpecIds.add(spec.id);
      });
    });
    return specs.filter(spec => !includedSpecIds.has(spec.id));
  }

  getFilteredSpecsInDeliverable(specs: JsWidget[], deliverable: string): JsWidget[] {
    let filteredTasks = [];
    if (deliverable === '') {
      filteredTasks = this.allTasks.filter(t => t.title.split(' - ').length === 1);
    } else {
      filteredTasks = this.allTasks.filter(t => t.title.split(' - ').length > 1 && t.title.split(' - ')[0] === deliverable);
    }
    const includedSpecIds: Set<string> = new Set();
    filteredTasks.forEach(task => {
      const specs = this.getSpecsOfTask(task);
      specs.forEach(spec => {
        includedSpecIds.add(spec.id);
      });
    });
    return specs.filter(spec => includedSpecIds.has(spec.id));
  }

  getFilteredSpecsNotInDeliverable(specs: JsWidget[], notInDeliverable: string): JsWidget[] {
    let filteredTasks = [];
    if (notInDeliverable === '') {
      filteredTasks = this.allTasks.filter(t => t.title.split(' - ').length === 1);
    } else {
      filteredTasks = this.allTasks.filter(t => t.title.split(' - ').length > 1 && t.title.split(' - ')[0] === notInDeliverable);
    }
    const includedSpecIds: Set<string> = new Set();
    filteredTasks.forEach(task => {
      const specs = this.getSpecsOfTask(task);
      specs.forEach(spec => {
        includedSpecIds.add(spec.id);
      });
    });
    return specs.filter(spec => !includedSpecIds.has(spec.id));
  }

  getTaskProgress(fromDate: Date, toDate: Date): TaskProgress {
    // Loop through the activities
    // Make sure entity is task
    // Make sure its in the date range
    // The organic progress of Task is Backlog, Design, Develop, Test, Failed, Mergeable, Passed, Approved
    // Check if the change is up or down based on the before and after status
    // If its up, then add the task tid to the up status set of the user(uid) in the taskProgress
    // If its down, then add the task tid to the down status set of the user(uid) in the taskProgress
    // Return the taskProgress
    const taskActivities = this.taskStatusUpdates();
    const taskProgress: TaskProgress = {};
    taskActivities.forEach(activity => {
      if (activity.entity !== 'task') return;
      if (activity.hasStatusChanged === false) return;
      const updatedAt = new Date(activity.cloudUpdatedAt);
      const startOfFromDate = new Date(fromDate);
      startOfFromDate.setHours(0, 0, 0, 0);
      const endOfToDate = new Date(toDate);
      endOfToDate.setHours(23, 59, 59, 999);
      if (updatedAt < startOfFromDate || updatedAt > endOfToDate) return;
      const beforeTask = activity?.before as JsTask | null;
      const afterTask = activity?.after as JsTask | null;
      const before = beforeTask?.status || 'Backlog';
      const after = afterTask?.status || 'Backlog';
      const uid = activity.uid;
      if (!taskProgress[uid]) {
        taskProgress[uid] = {
          up: {
            Backlog: new Set<number>(),
            Design: new Set<number>(),
            Review: new Set<number>(),
            Develop: new Set<number>(),
            Test: new Set<number>(),
            Failed: new Set<number>(),
            Mergeable: new Set<number>(),
            Passed: new Set<number>(),
            Approved: new Set<number>(),
            All: new Set<number>()
          },
          down: {
            Backlog: new Set<number>(),
            Design: new Set<number>(),
            Review: new Set<number>(),
            Develop: new Set<number>(),
            Test: new Set<number>(),
            Failed: new Set<number>(),
            Mergeable: new Set<number>(),
            Passed: new Set<number>(),
            Approved: new Set<number>(),
            All: new Set<number>()
          }
        };
        const organicProgress = ['Backlog', 'Design', 'Review', 'Develop', 'Failed', 'Test', 'Mergeable', 'Passed', 'Approved'];
        const beforeIndex = organicProgress.indexOf(before);
        const afterIndex = organicProgress.indexOf(after);
        if (beforeIndex === -1 || afterIndex === -1) return;
        if (beforeIndex < afterIndex) {
          //@ts-ignore
          taskProgress[uid].up[after].add(afterTask.tid);
          //@ts-ignore
          taskProgress[uid].up.All.add(afterTask.tid);
        } else if (beforeIndex > afterIndex) {
          //@ts-ignore
          taskProgress[uid].down[after].add(afterTask.tid);
          //@ts-ignore
          taskProgress[uid].down.All.add(afterTask.tid);
        }
      } else {
        const organicProgress = ['Backlog', 'Design', 'Review', 'Develop', 'Failed', 'Test', 'Mergeable', 'Passed', 'Approved'];
        const beforeIndex = organicProgress.indexOf(before);
        const afterIndex = organicProgress.indexOf(after);
        if (beforeIndex === -1 || afterIndex === -1) return;
        if (beforeIndex < afterIndex) {
          //@ts-ignore
          taskProgress[uid].up[after].add(afterTask.tid);
          //@ts-ignore
          taskProgress[uid].up.All.add(afterTask.tid);
        } else if (beforeIndex > afterIndex) {
          //@ts-ignore
          taskProgress[uid].down[after].add(afterTask.tid);
          //@ts-ignore
          taskProgress[uid].down.All.add(afterTask.tid);
        }
      }
    });

    return taskProgress;
  }

  getTotalTodayTaskProgress(progress: TaskProgress): { up: number; down: number } {
    let up = 0;
    let down = 0;
    Object.keys(progress).forEach(uid => {
      up = progress[uid].up.All.size + up;
      down = progress[uid].down.All.size + down;
    });
    return { up, down };
  }

  getDailyByTid(tid: number, planFor: 'Feature' | 'Todo') {
    const daily = this.userDaily().find(d => d.tid === tid && (planFor === 'Todo' ? d.planFor === 'Todo' : d.planFor !== 'Todo'));
    return daily;
  }

  emitDailyChange(daily: JsDaily | null): void {
    this.dailyDialogCloseSubject.next(daily);
  }

  setUpdatedEvent(event: JsEvent) {
    this.updateEventSubject.next(event);
  }

  setUpdatedLog(log: JsLog) {
    this.updateLogSubject.next(log);
  }

  setUpdatedParam(param: JsParam) {
    this.updateParamSubject.next(param);
  }

  stopSubscription() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
