import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as _ from 'lodash';
import { Subject, takeUntil } from 'rxjs';
import { ConfirmService } from 'src/app/_services/confirm.service';

@Component({
  selector: 'app-select-config',
  standalone: true,
  imports: [
    CommonModule,
    MatCheckbox
  ],
  templateUrl: './select-config.component.html',
  styleUrl: './select-config.component.scss'
})

export class SelectConfigComponent {

  title: string;
  options: string[];
  selectedOptions: string[] = [];
  selectedOptionsInitial: string[] = [];
  unSubscribe = new Subject<void>();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, options: string[], selectedOptions: string[] },
    public dialogRef: MatDialogRef<SelectConfigComponent>,
    public confirmService: ConfirmService
  ) {
    this.title = data.title;
    this.options = data.options;
    this.selectedOptions = _.cloneDeep(this.data.selectedOptions);
    this.selectedOptionsInitial = _.cloneDeep(this.data.selectedOptions);

    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirmService.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
  }

  toggleCheckBox(e: any, value: string) {
    const isChecked = e.checked;
    if (isChecked && !this.selectedOptions.includes(value)) {
      this.selectedOptions.push(value);
    } else if (!isChecked && this.selectedOptions.includes(value)) {
      const index = this.selectedOptions.indexOf(value);
      this.selectedOptions.splice(index, 1);
    } else {
      return;
    }
  }

  reset() {
    this.selectedOptions = this.selectedOptionsInitial;
  }

  update() {
    this.dialogRef.close(this.selectedOptions)
  }

  hasChanges(): boolean {
    const initial = _.cloneDeep(this.selectedOptionsInitial);
    const edited = _.cloneDeep(this.selectedOptions);
    if (initial?.length !== edited?.length) return true;
    initial.sort((a: string, b: string) => (a > b ? 1 : -1));
    edited.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial, edited);
  }

  ngOnDestroy() {
    this.unSubscribe?.complete();
    this.unSubscribe?.next();
  }
}
