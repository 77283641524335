import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from '@angular/material/autocomplete';
import * as _ from 'lodash';

@Component({
  selector: 'app-select-autocomplete',
  templateUrl: './select-autocomplete.component.html',
  styleUrls: ['./select-autocomplete.component.scss'],
})
export class SelectAutocompleteComponent implements OnChanges {
  @Input() label: string = '';
  @Input() disabled: boolean = false;
  @Input() options: any[] = [];
  @Input() searchKey: string | undefined = undefined;
  @Input() valueKey: string | undefined = undefined;
  @Input() initialValue: any = null;
  @Input() emptyValue: any = null;
  @Input() clear: boolean = false;
  @Output() onSelect = new EventEmitter<any>();
  @ViewChild(MatAutocomplete) autocomplete: MatAutocomplete | undefined;
  @ViewChild(MatAutocompleteTrigger)
  autocompleteTrigger: MatAutocompleteTrigger | undefined;
  @ViewChild('searchTextInput') inputElementRef:
    | ElementRef<HTMLTextAreaElement>
    | undefined;

  public initialSearchValue: any = null;
  public selectedOption: any = null;
  public selectedSearchValue: any = null;
  public selectedValue: any = null;
  public filteredOptions: any[] = [];
  public isEdited: boolean = false;
  public searchText: string = '';

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    this.filteredOptions = _.cloneDeep(this.options);
    this.selectedOption = this.filteredOptions.find((option) => {
      if (this.valueKey) {
        return option[this.valueKey] === this.initialValue;
      } else {
        return option === this.initialValue;
      }
    });
    if (this.selectedOption) {
      this.selectedSearchValue = this.searchKey
        ? this.selectedOption[this.searchKey]
        : this.selectedOption;
      this.selectedValue = this.valueKey
        ? this.selectedOption[this.valueKey]
        : this.selectedOption;
    } else {
      this.selectedSearchValue = this.emptyValue;
      this.selectedValue = this.emptyValue;
    }
    this.initialSearchValue = _.cloneDeep(this.selectedSearchValue);
    this.isEdited = false;
  }

  ngAfterViewInit() {
    const panel = document.querySelector('.mat-mdc-autocomplete-panel');
    panel?.classList.add('custom-autocomplete');
  }

  setFilteredOptions() {
    this.filteredOptions = this.options.filter((option) =>
      this.searchKey
        ? option[this.searchKey]
            .toLowerCase()
            .includes(this.searchText.toLowerCase())
        : option.toLowerCase().includes(this.searchText.toLowerCase())
    );
    this.isEdited = this.selectedSearchValue !== this.initialSearchValue;
  }

  onOptionSelect(e: MatAutocompleteSelectedEvent) {
    this.selectedOption = e.option.value;
    if (this.selectedOption) {
      this.selectedSearchValue = this.searchKey
        ? this.selectedOption[this.searchKey]
        : this.selectedOption;
      this.selectedValue = this.valueKey
        ? this.selectedOption[this.valueKey]
        : this.selectedOption;
    } else {
      this.selectedSearchValue = this.emptyValue;
      this.selectedValue = this.emptyValue;
    }
    this.onSelect.emit(this.selectedValue);
    this.isEdited = this.selectedSearchValue !== this.initialSearchValue;
    this.filteredOptions = _.cloneDeep(this.options);
  }

  clearSelect(event: any) {
    event.stopPropagation();
    this.selectedOption = null;
    this.selectedSearchValue = this.emptyValue;
    this.selectedValue = this.emptyValue;
    this.onSelect.emit(this.selectedValue);
  }

  reset() {
    this.selectedOption =
      this.filteredOptions.find((option) => {
        if (this.valueKey) {
          return option[this.valueKey] === this.initialValue;
        } else {
          return option === this.initialValue;
        }
      }) || null;
    if (this.selectedOption) {
      this.selectedSearchValue = this.searchKey
        ? this.selectedOption[this.searchKey]
        : this.selectedOption;
      this.selectedValue = this.valueKey
        ? this.selectedOption[this.valueKey]
        : this.selectedOption;
    } else {
      this.selectedSearchValue = this.emptyValue;
      this.selectedValue = this.emptyValue;
    }
    this.onSelect.emit(this.selectedValue);
    this.isEdited = false;
  }

  openAutocomplete() {
    console.log('openAutocomplete');
    if (!this.autocomplete?.isOpen) {
      this.searchText = '';
    }
    if (this.autocompleteTrigger) {
      this.autocompleteTrigger.openPanel();
      setTimeout(() => {
        this.inputElementRef?.nativeElement.focus();
      }, 100);
    }
  }
}
