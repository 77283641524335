<div *ngIf="view === 'Daily Plan'">
  <div class="ri-filter-row">
    <mat-form-field [class]="{ edited: timeLogSearch.value }" floatLabel="always">
      <mat-label>Search</mat-label>
      <input matInput #search placeholder="Daily Status, Update/Description etc..." [formControl]="timeLogSearch" name="riTimeLogSearch" autocomplete="off" />
      <button *ngIf="timeLogSearch.value" matSuffix mat-icon-button aria-label="Clear"
            (click)="timeLogSearch.setValue(null);onFilterChange()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <app-select [options]="filterOptions.show.options" [title]="filterOptions.show.title" class="me-1" [control]="filterOptions.show.formControl" [defaultValue]="filterOptions.show.defaultValue" (selectEvent)="onShowChange()" [multiple]="true"></app-select>
    <button class="btn btn-warning float-end btn-sm mx-1" (click)="reset()">Reset</button>
    <button class="btn btn-primary float-end btn-sm" (click)="createNewDaily()">Log Time</button>
  </div>

  <div class="ri-filter-row ri-filter-row-background">
    <app-select [options]="filterOptions.status.options" [title]="filterOptions.status.title" class="me-1" [control]="filterOptions.status.formControl" [defaultValue]="filterOptions.status.defaultValue" (selectEvent)="cancelStandupMode();onFilterChange()" [multiple]="true"></app-select>

    <app-select *ngIf="fbService.users.length !== 0" [options]="fbService.users" [title]="filterOptions.uid.title" class="me-1" [control]="filterOptions.uid.formControl" [defaultValue]="filterOptions.uid.defaultValue" (selectEvent)="cancelStandupMode();onFilterChange()" [label]="'label'" [value]="'value'" [multiple]="true"></app-select>

    <app-select [options]="filterOptions.dateRange.options" [title]="filterOptions.dateRange.title" class="me-1" [control]="filterOptions.dateRange.formControl" [defaultValue]="filterOptions.dateRange.defaultValue" (selectEvent)="cancelStandupMode();onFilterChange()"></app-select>

    <div class="ms-auto d-flex">
      <button class="btn standupBtn" [ngClass]="{'btn-primary' : isStandupMode, 'btn-outline-primary' : !isStandupMode }"
        (click)="standupMode()">Standup Mode</button>
      <div class="standup-host bg-white rounded-2 d-flex align-items-center px-1 me-1" *ngIf="cc.config.standupHost">
        <img class="img" src="assets/images/assistant.png" style="height: 28px;">
        <div class="ps-2">
          <p class="mb-0 fw-bolder text-muted pt-1 lh-09">{{ cc.config.standupHost || '--' }}</p>
          <span class="ri-fs-10px text-muted mb-0">{{ cc.config.standupHostUpdatedAt | dfnsFormatDistanceToNowStrict }}
            ago</span>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-column">
    <div class="col-wrapper d-flex flex-row flex-grow-1">
      <div class="flex-grow-1">
        <div class="scroll-container">
          <as-split unit="pixel" [gutterSize]="2">
            <!-- STANDUP VIEW -->
            <as-split-area class="main-list">
              <div *ngFor="let user of orderedUsersForStandup; trackBy: trackByUserLabel">
                <div *ngIf="shouldShowUser(user.value)">
                  <div class="fs-6 d-flex justify-content-between fw-bolder text-bg-secondary px-2 py-3">
                    <span class="ps-1">
                      <span class="text-start ri-pointer" (click)="showUserTaskHours(user.value)">
                        {{ user.label }}
                      </span>
                      <span class="badge rounded-pill text-bg-light ri-pointer" (click)="showAssignedTasks(user.value)">
                        {{ $any(cc.uidToFeaturesMap()[user.value])?.length || 0 }}
                      </span>
                    </span>
                    <span class="text-start"></span>
                    <span class="text-end pe-1">{{ getUserDailysAll(user.value).totalHours | number:'1.0-1' }} {{
                      getUserDailysAll(user.value).totalHours <= 1 ? 'hr' : 'hrs' }}</span>
                  </div>

                  <div class="px-2 pt-2" *ngIf="filterOptions.show.formControl.value?.includes('Staff Note') && getPlanWidget(fbService.getDisplayName(user.value))">
                    <!-- <h6 class="mb-0 pb-2 px-1">Staff Note</h6> -->

                    <div class="text-muted plan-text mx-1 p-2 staff-note-card ri-pointer" *ngIf="trimText(getPlanWidget(fbService.getDisplayName(user.value))?.description || '')" (click)="openPlanWidget(getPlanWidget(fbService.getDisplayName(user.value)), $event)"
                    matTooltip="Staff Note">
                      <pre class="plan">{{ getPlanWidget(fbService.getDisplayName(user.value))?.description }}</pre>
                    </div>

                    <div *ngIf="!trimText(getPlanWidget(fbService.getDisplayName(user.value))?.description || '')" class="mx-1 card d-flex justify-content-between flex-column cursor text-center p-2 text-muted" (click)="openPlanWidget(getPlanWidget(fbService.getDisplayName(user.value)), $event)" matTooltip="Staff Note">
                      - - -
                    </div>
                  </div>

                  <div class="pt-2 px-2" *ngIf="filterOptions.show.formControl.value?.includes('Todos')">
                    <h6 class="mb-0 mx-1">Todos</h6>
                    
                    <div *ngIf="!cc.uidToTodosMap[user.value] || cc.uidToTodosMap[user.value]?.length === 0" class="card mx-1 my-2 d-flex justify-content-between flex-column cursor text-center p-2 text-muted"
                      matTooltip="Todos">
                      - - -
                    </div>

                    <div class="todo-lists mx-1 my-2" *ngIf="cc.uidToTodosMap[user.value] && cc.uidToTodosMap[user.value].length !== 0">
                      <div class="position-relative ri-todo-table-block">
                        <div class="ri-todo-flex-table">
                          <div class="ri-pointer table-main-row table-row table-body" *ngFor="let row of cc.uidToTodosMap[user.value] || []; trackBy: trackByForTodo" (click)="openTodoForm('edit', row)" [ngClass]="{ 'bg-light-red' : (row.currentlyWith && row.currentlyWith === user.value && ( row.status === 'Need Clarity' || row.status === 'Blocked' )) }">
                            <div class="row-item">
                              <div class="d-flex pb-1">
                                  <div class="title">{{ row.title }}</div>
                                  <div class="delivery" matTooltip="Delivery Date">{{ row.deliveryDate ? (row.deliveryDate |
                                      compareDate) : '-' }}</div>
                                  <div class="currentlyWith" matTooltip="Currently With">{{ row.currentlyWith ?  fbService.getDisplayName(row.currentlyWith) : '-' }}</div>
                              </div>
                              <pre class="mb-0 pe-2 text-muted note" *ngIf="row.note" matTooltip="Note">{{ row.note }}</pre>
                          </div>
                          <div class="row-item status" [ngClass]="{
                              'text-bg-review': row.status === 'Todo',
                              'text-bg-primary': row.status === 'In Progress',
                              'text-bg-dark': row.status === 'Paused',
                              'text-bg-secondary': row.status === 'Blocked',
                              'text-bg-info': row.status === 'Need Clarity',
                              'text-bg-warning': row.status === 'Done',
                              'text-bg-success': row.status === 'Approved'}" matTooltip="Status">{{ row.status }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="pt-2 px-2" *ngIf="filterOptions.show.formControl.value?.includes('Time Logs')">
                    <h6 class="mx-1 mb-0">Time Logs</h6>
                    <div
                      *ngIf="!getDatesFromUserDateDailysMap(user.value).length"
                      class="card mx-1 my-2 d-flex justify-content-between flex-column cursor text-center p-2 text-muted"
                      matTooltip="Time Logs">
                      - - -
                    </div>
                    <div class="mx-1" *ngIf="getDatesFromUserDateDailysMap(user.value).length">
                      <div *ngFor="let date of getDatesFromUserDateDailysMap(user.value); trackBy: trackByDate">
                        <div class="my-2 text-primary d-flex justify-content-between fw-bolder">
                          <span class="text-start">{{ sharedFunc.getFormattedDate(date) }}</span>
                          <span class="text-start"></span>
                          <span class="text-end">{{ getUserDailysByDate(user.value, date).totalHours | number:'1.0-1' }}
                            {{ getUserDailysByDate(user.value, date).totalHours <= 1 ? 'hr' : 'hrs' }}</span>
                        </div>
  
                        <div class="d-flex justify-content-between">
                          <!-- Task Status Counts -->
                          <div class="text-start d-flex flex-column cursor px-3"
                            (click)="showUpdatedTasks(user.value, date)">
                            <app-status-counts class="p-0" matTooltip="From Status" matTooltipPosition="above"
                              *ngIf="userDateTaskFromStatusCountsMap[user.value] && userDateTaskFromStatusCountsMap[user.value][date]"
                              [taskStatusCounts]="userDateTaskFromStatusCountsMap[user.value][date]"></app-status-counts>
                            <app-status-counts class="p-0" matTooltip="To Status" matTooltipPosition="above"
                              *ngIf="userDateTaskToStatusCountsMap[user.value] && userDateTaskToStatusCountsMap[user.value][date]"
                              [taskStatusCounts]="userDateTaskToStatusCountsMap[user.value][date]"></app-status-counts>
                            <app-status-counts class="p-0" matTooltip="Current Status" matTooltipPosition="above"
                              *ngIf="userDateTaskCurrentStatusCountsMap[user.value] && userDateTaskCurrentStatusCountsMap[user.value][date]"
                              [taskStatusCounts]="userDateTaskCurrentStatusCountsMap[user.value][date]"></app-status-counts>
                          </div>
  
                          <!-- Spec Status Counts -->
                          <div class="text-end d-flex flex-column cursor px-3"
                            (click)="showUpdatedWidgets(user.value, date)">
                            <app-status-counts class="p-0" matTooltip="From Status" matTooltipPosition="above"
                              *ngIf="userDateFromStatusCountsMap[user.value] && userDateFromStatusCountsMap[user.value][date]"
                              [specStatusCounts]="userDateFromStatusCountsMap[user.value][date]"></app-status-counts>
                            <app-status-counts class="p-0" matTooltip="To Status" matTooltipPosition="above"
                              *ngIf="userDateToStatusCountsMap[user.value] && userDateToStatusCountsMap[user.value][date]"
                              [specStatusCounts]="userDateToStatusCountsMap[user.value][date]"></app-status-counts>
                            <app-status-counts class="p-0" matTooltip="Current Status" matTooltipPosition="above"
                              *ngIf="userDateCurrentStatusCountsMap[user.value] && userDateCurrentStatusCountsMap[user.value][date]"
                              [specStatusCounts]="userDateCurrentStatusCountsMap[user.value][date]"></app-status-counts>
                          </div>
                        </div>
  
                        <!-- Daily plans -->
                        <app-daily-plan-card [dailys]="getUserDailysByDate(user.value, date).dailys" [filterOptions]="filterOptions" (selectEvent)="onSelect($event)" [type]="type" (typeChange)="type = $event"></app-daily-plan-card>
  
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </as-split-area>

            <!-- // DETAIL FORM AREA -->
            <as-split-area [size]="475" [maxSize]="650" [minSize]="475" class="position-relative">
              <app-daily-plan-detail [dailyInput]="selectedDaily"></app-daily-plan-detail>
            </as-split-area>
          </as-split>
        </div>
      </div>
    </div>
  </div>
</div>
<app-status-updates *ngIf="view === 'Status Updates'" [who]="selectedWho" [when]="selectedWhen"
  (onClose)="onCloseStatusUpdates()"></app-status-updates>