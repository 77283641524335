<span class="" *ngIf="!isEmpty; else noSpecs">
    <span class="badge rounded-pill text-bg-info me-1 ri-pointer" matTooltip="Backlog" [ngClass]="{ 'opacity-25': !Backlog }" (click)="showSpecsListOfTask(task,'Backlog', $event)">
        {{ Backlog || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-secondary me-1 ri-pointer" matTooltip="Design" [ngClass]="{ 'opacity-25': !Design }" (click)="showSpecsListOfTask(task,'Design', $event)">
        {{ Design || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-review me-1 ri-pointer" matTooltip="Review" [ngClass]="{ 'opacity-25': !Review }" (click)="showSpecsListOfTask(task,'Review', $event)">
        {{ Review || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark-danger me-1 ri-pointer" matTooltip="Correction" [ngClass]="{ 'opacity-25': !Correction }" (click)="showSpecsListOfTask(task,'Correction', $event)">
        {{ Correction || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-primary me-1 ri-pointer" matTooltip="Develop" [ngClass]="{ 'opacity-25': !Develop }" (click)="showSpecsListOfTask(task,'Develop', $event)">
        {{ Develop || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-warning me-1 ri-pointer" matTooltip="Test" [ngClass]="{ 'opacity-25': !Test }" (click)="showSpecsListOfTask(task,'Test', $event)">
        {{ Test || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-danger me-1 ri-pointer" matTooltip="Failed" [ngClass]="{ 'opacity-25': !Failed }" (click)="showSpecsListOfTask(task,'Failed', $event)">
        {{ Failed || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-success me-1 ri-pointer" matTooltip="Approve" [ngClass]="{ 'opacity-25': !Approve }" (click)="showSpecsListOfTask(task,'Approve', $event)">
        {{ Approve || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-purple me-1 ri-pointer" matTooltip="Deprecated" [ngClass]="{ 'opacity-25': !Deprecated }" (click)="showSpecsListOfTask(task,'Deprecated', $event)">
        {{ Deprecated || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark-purple me-1 ri-pointer" matTooltip="Removed" [ngClass]="{ 'opacity-25': !Removed }" (click)="showSpecsListOfTask(task,'Removed', $event)">
        {{ Removed || '-' }}
    </span>
    <span class="badge rounded-pill text-bg-dark ri-pointer" matTooltip="Total" [ngClass]="{ 'opacity-25': !Total }" (click)="showSpecsListOfTask(task,'All', $event)">
        {{ Total }}
    </span>
</span>
<ng-template #noSpecs>
    <span class="d-inline-block">- - -</span>
</ng-template>
