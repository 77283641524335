<main class="feature-form pb-3">

    <div class="d-flex justify-content-between mb-3 p-3"
        [ngClass]="{ 'bg-danger': currentRegression && regressionResult === 'FAIL', 'bg-success': currentRegression && regressionResult === 'PASS' }">
        <h2 class="d-inline-block mb-0"
            [ngClass]="{ 'text-white': currentRegression && (regressionResult === 'FAIL' || regressionResult === 'PASS') }">
            Feature Details <span class="text-primary fs-5"
                [ngClass]="{ 'text-white': currentRegression && (regressionResult === 'FAIL' || regressionResult === 'PASS') }">(F-{{featureInitial.tid}})</span>
        </h2>
        <div class="d-flex" *ngIf="mode === 'edit'">

            <mat-button-toggle-group class="regression-type me-2"
                *ngIf="mode === 'edit' && currentRegression && !featureInitial.deletedAt" [(ngModel)]="regressionResult"
                aria-label="Font Style" (change)="onRegressionChange($event)">
                <mat-button-toggle [value]="''">None</mat-button-toggle>
                <mat-button-toggle value="PASS">PASS</mat-button-toggle>
                <mat-button-toggle value="FAIL">FAIL</mat-button-toggle>
            </mat-button-toggle-group>


            <button *ngIf="featureInitial.deletedAt" matTooltip="Restore"
                class="btn btn-danger btn-sm btn-restore d-flex align-items-center me-2"
                (click)="deleteHandler(false)"><mat-icon class="text-light">replay</mat-icon></button>
            <button *ngIf="!featureInitial.deletedAt" class="btn btn-primary btn-sm float-end me-2"
                (click)="openDailyPlanModal(cc.featureDailyHrsMap[featureInitial.tid] >= 0)"> {{
                cc.featureDailyHrsMap[featureInitial.tid] ? cc.featureDailyHrsMap[featureInitial.tid] + ' Hr' : 'Log
                Time'}}</button>
            <button *ngIf="!featureInitial.deletedAt" matTooltip="Comment"
                class="btn btn-comment btn-sm btn-success d-flex align-items-center h-100 me-2"
                (click)="sharedFunc.getComments('task', featureInitial)"
                [ngClass]="{ 'bg-white text-success': currentRegression && regressionResult === 'PASS' }">
                <mat-icon class="pe-0">question_answer</mat-icon>
                <span class="ps-2" *ngIf="cc.idToTaskMap[featureInitial.id]?.latestComment">{{
                    cc.idToTaskMap[featureInitial.id].latestComment?.updatedAt | dfnsFormatDistanceToNowStrict }}
                    ago</span>
            </button>
            <button matTooltip="Get Activity" class="btn btn-info btn-sm btn-activity d-flex align-items-center"
                (click)="getActivity()"><mat-icon class="text-light">restore</mat-icon></button>
        </div>
    </div>

    <div class="ri-dialog-content px-3" [ngClass]="{'ri-pe-none': featureInitial.deletedAt}">
        <form [formGroup]="featureForm" #feForm="ngForm">
            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : featureForm.value.title !== featureInitial.title}">
                <mat-label>Title</mat-label>
                <input matInput type="text" autocomplete="off" autocorrect="off" autocapitalize="none"
                    spellcheck="false" formControlName="title" name="riFeatureTitle" />
            </mat-form-field>

            <mat-form-field appearance="fill" class="w-100 mb-1"
                [class]="{'edited' : featureForm.value.description !== featureInitial.description}">
                <mat-label>What to do / User stories / Optimisation details (Bullet Points)</mat-label>
                <textarea matInput formControlName="description" name="riFeatureDescription" cdkTextareaAutosize
                    #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
            </mat-form-field>

            <div class="row g-1 mb-1">
                <div class="col-3">
                    <div class="row g-1">
                        <div class="col-6">
                            <app-select [options]="cc.availableMainReleaseNumbers()" [title]="'From Release'" class="w-100" [control]="getFc('fromRelease')"  [defaultValue]="featureInitial.fromRelease" [noneOption]="true" [noneValue]="null" (selectEvent)="fromReleaseChange()"></app-select>
                        </div>
                        <div class="col-6">
                            <app-select [options]="cc.availableMainReleaseNumbers()" [title]="'To Release'"
                                class="w-100" [control]="getFc('toRelease')" [defaultValue]="featureInitial.toRelease"
                                [noneOption]="true" [disabled]="featureForm.value.fromRelease === null || featureForm.value.fromRelease === undefined" [noneValue]="null"></app-select>
                        </div>
                    </div>
                </div>

                <div class="col-3">
                    <app-select [options]="taskTypes" [title]="'Feature Type'" class="w-100" [control]="getFc('type')"
                        [defaultValue]="featureInitial.type"></app-select>
                </div>

                <div class="col-3">
                    <app-select [options]="taskPriorities" [title]="'Feature Priority'" class="w-100"
                        [control]="getFc('priority')" [defaultValue]="featureInitial.priority"></app-select>
                </div>

                <div class="col-3">
                    <mat-form-field appearance="fill" class="w-100"
                        [class]="{ edited: featureForm.value.order != featureInitial.order }">
                        <mat-label>Feature Order</mat-label>
                        <input type="number" placeholder="1.3" matInput formControlName="order" name="riFeatureOrder" />
                    </mat-form-field>
                </div>
            </div>

            <div class="row g-1 mb-1 pt-3">
                <div class="col-6">
                    <mat-form-field appearance="fill" class="w-100"
                        [class]="{'edited' : featureForm.value.includePatterns !== featureInitial.includePatterns }">
                        <mat-label>Spec Include Pattern (eg: appMobile*, *past*, *illustration)</mat-label>
                        <textarea matInput formControlName="includePatterns" name="riFeatureIncludePatterns"
                            cdkTextareaAutosize placeholder="appMobile_screens*, *past*" #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="2" cdkAutosizeMaxRows="9"></textarea>
                    </mat-form-field>
                </div>
                <div class="col-6">
                    <mat-form-field appearance="fill" class="w-100"
                        [class]="{'edited' : featureForm.value.excludePatterns !== featureInitial.excludePatterns }">
                        <mat-label>Spec Exclude Pattern (eg: appMobile*, *past*, *illustration)</mat-label>
                        <textarea matInput formControlName="excludePatterns" name="riFeatureExcludePatterns"
                            cdkTextareaAutosize placeholder="appMobile_screens*, *past*" #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="2" cdkAutosizeMaxRows="9"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="row g-1 mb-1">
                <div class="col-3">
                    <app-select [options]="fbs.users" [title]="'Product Owner'" [noneOption]="true"
                        [control]="getFc('productOwner')" [defaultValue]="featureInitial.productOwner" [label]="'label'"
                        [value]="'value'"></app-select>
                </div>
                <div class="col-3">
                    <app-select [options]="fbs.users" [title]="'Design Owner'" [noneOption]="true"
                        [control]="getFc('designOwner')" [defaultValue]="featureInitial.designOwner" [label]="'label'"
                        [value]="'value'"></app-select>
                </div>
                <div class="col-3">
                    <app-select [options]="fbs.users" [title]="'Dev Owner'" [noneOption]="true"
                        [control]="getFc('devOwner')" [defaultValue]="featureInitial.devOwner" [label]="'label'"
                        [value]="'value'"></app-select>
                </div>
                <div class="col-3">
                    <app-select [options]="fbs.users" [title]="'Test Owner'" [noneOption]="true"
                        [control]="getFc('testOwner')" [defaultValue]="featureInitial.testOwner" [label]="'label'"
                        [value]="'value'"></app-select>
                </div>
            </div>

            <div class="row g-1 pt-3 mb-1">
                <mat-form-field appearance="fill" [ngClass]="{
                  'col-12': getNotesSelectedKeys().length === 1, 
                  'col-6': getNotesSelectedKeys().length === 2, 
                  'col-4': getNotesSelectedKeys().length === 3, 
                  'col-3': getNotesSelectedKeys().length === 4 
                }" [class.edited]="featureForm.value.productOwnerNote !== featureInitial.productOwnerNote"  *ngIf="noteOptions['productOwnerNote'] === true">

                    <mat-label>Note to the product owner (Bullet Points)</mat-label>

                    <textarea matInput formControlName="productOwnerNote" name="riTodNnote" cdkTextareaAutosize  #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>

                </mat-form-field>

                <mat-form-field appearance="fill" [ngClass]="{
                    'col-12': getNotesSelectedKeys().length === 1, 
                    'col-6': getNotesSelectedKeys().length === 2, 
                    'col-4': getNotesSelectedKeys().length === 3, 
                    'col-3': getNotesSelectedKeys().length === 4 
                  }" [class.edited]="featureForm.value.designerNote !== featureInitial.designerNote"  *ngIf="noteOptions['designerNote'] === true">
                    <mat-label>Note to the designer (Bullet Points)</mat-label>
                    <textarea matInput formControlName="designerNote" name="riTodNnote" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                </mat-form-field>

                <mat-form-field appearance="fill" [ngClass]="{
                    'col-12': getNotesSelectedKeys().length === 1, 
                    'col-6': getNotesSelectedKeys().length === 2, 
                    'col-4': getNotesSelectedKeys().length === 3, 
                    'col-3': getNotesSelectedKeys().length === 4 
                  }" [class.edited]="featureForm.value.developerNote !== featureInitial.developerNote"  *ngIf="noteOptions['developerNote'] === true">
                    <mat-label>Notes to the developer (Bullet Points)</mat-label>
                    <!-- Notes by the developer / Edge cases / Blocker Details (Bullet Points) -->
                    <textarea matInput formControlName="developerNote" name="riTodNnote" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                </mat-form-field>

                <mat-form-field appearance="fill" [ngClass]="{
                    'col-12': getNotesSelectedKeys().length === 1, 
                    'col-6': getNotesSelectedKeys().length === 2, 
                    'col-4': getNotesSelectedKeys().length === 3, 
                    'col-3': getNotesSelectedKeys().length === 4 
                  }" [class.edited]="featureForm.value.testerNote !== featureInitial.testerNote"  *ngIf="noteOptions['testerNote'] === true">
                    <mat-label>Notes to the tester (Bullet Points)</mat-label>
                    <textarea matInput formControlName="testerNote" name="riTodNnote" cdkTextareaAutosize
                        #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="2" cdkAutosizeMaxRows="10"></textarea>
                </mat-form-field>
            </div>

            <div class="row g-1 mb-1">
                <div class="col">
                    <app-select [options]="fbs.users" [title]="'Assigned To'" [noneOption]="true"
                        [control]="getFc('assignedTo')" [defaultValue]="featureInitial.assignedTo" [label]="'label'"
                        [value]="'value'"></app-select>
                </div>
                <div class="col">
                    <app-select [options]="taskStatuses" [title]="'Status'" [control]="getFc('status')"
                        [defaultValue]="featureInitial.status"></app-select>
                </div>
                <div class="col">
                    <app-select [options]="taskSubStatuses" [title]="'Sub Status'" [control]="getFc('subStatus')"
                        [defaultValue]="featureInitial.subStatus"></app-select>
                </div>
                <div class="col">
                    <app-select [options]="otherFeatures" [title]="'Blocked By Features'"
                        [control]="getFc('blockedByTids')" [defaultValue]="featureInitial.blockedByTids"
                        [label]="'title'" [value]="'tid'" [prefix]="'F-'" [prefixValue]="'tid'"
                        [multiple]="true" [search]="true"></app-select>
                </div>
                <div class="col">
                    <app-select [options]="otherFeatures" [title]="'Related Features'" [control]="getFc('relatedTids')"
                        [defaultValue]="featureInitial.relatedTids" [label]="'title'" [value]="'tid'" [prefix]="'F-'"
                        [prefixValue]="'tid'" [multiple]="true" [search]="true"></app-select>
                </div>
            </div>
        </form>
    </div>

    <!-- <pre>{{ featureInitial | json }}</pre>
    ///// Form Value
    <pre>{{ featureForm.value | json }}</pre> -->

    <div class="ri-dialog-footer px-3" [ngClass]="{'ri-pe-none': featureInitial.deletedAt}">
        <div class="mt-4 pt-2 pb-1 d-flex justify-content-between" *ngIf="!featureInitial.deletedAt">
            <div class="d-flex">
                <button class="btn btn-danger btn-sm me-2 ri-btn-delete" matTooltip="Delete"
                    (click)="deleteHandler(true)" *ngIf="mode === 'edit'">
                    <i class="bi bi-trash"></i>
                </button>
                <button class="btn btn-sm btn-add-note me-2" (click)="selectValues()"><mat-icon
                        class="text-dark ri-fs-20px">note_add</mat-icon></button>
                <button *ngIf="featureInitial?.timeLogs?.length" class="btn btn-secondary btn-sm"
                    (click)="showTimeLogs = !showTimeLogs">Logged Time</button>
            </div>
            <div class="d-flex">
                <button class="btn btn-secondary btn-sm me-2" (click)="cancelChanges()" [disabled]="!hasChanges()">
                    Reset
                </button>
                <button class="btn btn-primary btn-sm" (click)="saveChanges()"
                    [disabled]="featureForm.invalid || isUpdate || !hasChanges()">Save
                </button>
            </div>
        </div>

        <div class="mt-2 ri-pointer" *ngIf="showTimeLogs" (click)="showTaskDailys(featureForm.value)">
            <h2>Total Timelogs <span class="float-end">{{getTotalTimeSpent(featureForm.value) | number:'1.0-1'}}
                    hr</span></h2>
            <mat-grid-list cols="3" *ngFor="let timeLog of featureForm.value.timeLogs" rowHeight="40px">
                <mat-grid-tile class="d-block">
                    <div class="text-start">{{fbs.getDisplayName(timeLog.uid)}}</div>
                </mat-grid-tile>
                <mat-grid-tile class="d-block">
                    <div class="text-end">{{getTimeDistance(timeLog.lastUpdated)}}</div>
                </mat-grid-tile>
                <mat-grid-tile class="d-block">
                    <div class="text-end">{{timeLog.hours | number:'1.0-1'}} hr</div>
                </mat-grid-tile>
            </mat-grid-list>
        </div>

        <div class="my-3 pt-2 d-flex justify-content-between">
            <span>Specs:&nbsp;<app-spec-status-counts-of-task
                    [task]="featureInitial"></app-spec-status-counts-of-task>&nbsp;&nbsp;</span>
            <span
                *ngIf="cc.featureToInvalidViewIdSpec[featureInitial.id] && cc.featureToInvalidViewIdSpec[featureInitial.id].length !== 0"
                class="d-flex align-items-center">Specs without viewId: <span class="badge bg-dark ms-1">{{
                    cc.featureToInvalidViewIdSpec[featureInitial.id].length }}</span></span>
            <span>Translations:&nbsp;<app-translation-status-counts-of-task class="float-end"
                    [task]="featureInitial"></app-translation-status-counts-of-task></span>
        </div>

        <div *ngIf="cc.tidBlockingTaskMap[featureInitial.tid]?.length">
            <h3 class="d-inline-block text-danger fw-bold">Blocking other Features</h3>
            <div class="pb-2 ri-pointer" *ngFor="let task of cc.tidBlockingTaskMap[featureInitial.tid]"
                (click)="openTask(task)">
                <span class="text-primary me-2">F-{{task.tid}}</span> <span>{{task.title}}</span>
            </div>
        </div>

        <div *ngIf="featureInitial.blockedByTids?.length">
            <h3 class="d-inline-block text-warning fw-bold">Blocked by below Features</h3>
            <div class="pb-2 ri-pointer" *ngFor="let tid of featureInitial.blockedByTids"
                (click)="openTask(cc.tidTaskMap[tid])">
                <span class="text-primary me-2">F-{{tid}}</span> <span>{{cc.tidTaskMap[tid].title}}</span>
            </div>
        </div>

        <div *ngIf="featureInitial.relatedTids?.length">
            <h3 class="d-inline-block text-primary fw-bold">Related Features</h3>
            <div class="pb-2 ri-pointer" *ngFor="let tid of featureInitial.relatedTids"
                (click)="openTask(cc.tidTaskMap[tid])">
                <span class="text-primary me-2">F-{{tid}}</span> <span>{{cc.tidTaskMap[tid].title}}</span>
            </div>
        </div>
    </div>

</main>