import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, Output, Renderer2, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MentionModule } from 'angular-mentions';
import { Subject } from 'rxjs';
import _ from 'lodash';
import { FirebaseService } from 'src/app/_services/firebase.service';

@Component({
  selector: 'app-tags-textarea',
  standalone: true,
  imports: [
    CommonModule,
    MentionModule
  ],
  templateUrl: './tags-textarea.component.html',
  styleUrl: './tags-textarea.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagsTextareaComponent),
      multi: true
    },
    {
      provide: MatFormFieldControl,
      useExisting: TagsTextareaComponent
    }
  ]
})

export class TagsTextareaComponent {

  @ViewChild('RiEditableDiv', { static: true }) editableDiv!: ElementRef;
  @Input() mentions: string[] = [];
  @Input() value: string = '';
  @Output() valueChange = new EventEmitter<string>();

  // @Input() value: string = '';
  stateChanges = new Subject<void>();
  focused: boolean = false;
  touched: boolean = false;
  @Input() disabled: boolean = false;
  private lastHtml: string = '';
  isSelectingMention: boolean = false;

  onChange = (_: any) => { };
  onTouched = () => { };

  @HostListener('window:mention-select-started')
  onMentionSelectStarted() {
    this.isSelectingMention = true;
  }

  @HostListener('window:mention-select-ended')
  onMentionSelectEnded() {
    this.isSelectingMention = false;
  }

  constructor(private renderer: Renderer2, public fbs: FirebaseService) { }

  ngOnInit() {
    this.editableDiv.nativeElement.innerHTML = _.cloneDeep(this.value);
  }

  mentionConfig = {
    // mentionSelect: (item: any) => this.onMentionSelect(item),
    allowSpace: true,
    items: this.mentions,
    mentionSelect: this.mentionSelect,
    // labelKey: 'label'
  };

  mentionSelect(event: { label: any; }) {
    return `#${event.label}`;
  }

  itemSelected(event: any) {
    setTimeout(() => {
      const element = this.editableDiv.nativeElement;
      const selection = window.getSelection();
      const range = selection?.getRangeAt(0);

      if (!range) return;

      const searchText = `#${event.label}`;

      // Function to find text node containing the tag
      function findTextNodeWithTag(node: Node, searchText: string): { node: Node, offset: number } | null {
        if (node.nodeType === Node.TEXT_NODE) {
          const index = node.textContent?.indexOf(searchText) ?? -1;
          if (index !== -1) {
            return { node, offset: index };
          }
        }

        // Search child nodes
        for (let i = 0; i < node.childNodes.length; i++) {
          const result = findTextNodeWithTag(node.childNodes[i], searchText);
          if (result) return result;
        }

        return null;
      }

      // Find the text node containing our tag
      const searchResult = findTextNodeWithTag(range.startContainer, searchText);
      if (!searchResult) return;

      const { node: textNode, offset: tagStart } = searchResult;
      const nodeContent = textNode.textContent || '';

      // Create the new elements
      const spanElement = document.createElement('span');
      spanElement.textContent = `@${event.label}`;

      const beforeText = nodeContent.substring(0, tagStart);
      const afterText = nodeContent.substring(tagStart + searchText.length);

      const beforeNode = document.createTextNode(beforeText);
      const afterNode = document.createTextNode(afterText + '\u00A0');

      // Replace the original text node
      const parentNode = textNode.parentNode;
      if (parentNode) {
        parentNode.replaceChild(beforeNode, textNode);
        parentNode.insertBefore(spanElement, beforeNode.nextSibling);
        parentNode.insertBefore(afterNode, spanElement.nextSibling);

        // Set cursor position
        const newRange = document.createRange();
        newRange.setStart(afterNode, 1);
        newRange.collapse(true);

        selection?.removeAllRanges();
        selection?.addRange(newRange);
      }
      const updatedHTML = element.innerHTML;
      this.valueChange.emit(updatedHTML);
    }, 0);
  }

  // You can remove or keep the original selectEnd() method for other uses
  selectEnd() {
    let range = document.createRange();
    let selection = window.getSelection();

    if (this.editableDiv?.nativeElement) {
      range.selectNodeContents(this.editableDiv.nativeElement);
      range.collapse(false);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }

  onMentionSelect(item: any) {
    return `@${item.value}`;
  }

  ngAfterViewInit() {
    // Focus the div if the initial value is null
    if (this.value === null || this.value === '') {
      this.focus();
    }
  }

  focus() {
    this.editableDiv.nativeElement.focus();
    this.focused = true;
    this.stateChanges.next();
  }

  writeValue(value: string): void {
    // this.value = value;
    // if (this.editableDiv) {
    //   this.editableDiv.nativeElement.innerHTML = value;
    // }
  }

  get empty() {
    return !this.editableDiv?.nativeElement?.innerHTML;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  onBlur() {
    this.focused = false;
    this.onTouched();
    this.stateChanges.next();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.editableDiv.nativeElement.contentEditable = !isDisabled;
  }

  // Implement other required MatFormFieldControl methods...
  setDescribedByIds(ids: string[]): void {
    // Implementation
  }

  onContainerClick(event: MouseEvent): void {
    if (!this.focused) {
      this.focus();
    }
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      if (this.isSelectingMention) {
        // Prevent default behavior when selecting a mention
        event.preventDefault();
      } else {
        // Allow default behavior (new line) when not selecting a mention
        this.onInput();
      }
    }
  }

  insertLineBreak() {
    document.execCommand('insertLineBreak');
    this.onInput();
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const text = event.clipboardData?.getData('text/plain') || '';
    const tempElement = document.createElement('div');
    tempElement.innerHTML = text;

    // Remove all inline styles and spans
    const cleanText = this.cleanupText(text);

    // Insert the clean text at the cursor position
    document.execCommand('insertHTML', false, cleanText);
  }

  private cleanupText(text: string): string {
    // Create a temporary element
    const temp = document.createElement('div');
    temp.innerHTML = text;

    // Remove all spans
    const spans = temp.querySelectorAll('span');
    spans.forEach(span => {
      const parent = span.parentNode;
      while (span.firstChild) {
        parent?.insertBefore(span.firstChild, span);
      }
      parent?.removeChild(span);
    });

    // Remove all style attributes
    const elementsWithStyle = temp.querySelectorAll('[style]');
    elementsWithStyle.forEach(el => el.removeAttribute('style'));

    // Get the cleaned text content
    return temp.textContent || temp.innerText || '';
  }

  onInput(event?: Event) {
    let html = this.editableDiv.nativeElement.innerHTML;
    
    // Handle empty content
    if (!html || html === '<br>') {
      html = '\u200B';
    }
    
    if (html !== this.lastHtml) {
      const cursorPosition = this.getCursorPosition();
      
      // Clean up any automatically inserted <br> tags at the end
      html = this.cleanBrTags(html);
      
      // Process mentions
      const processedHtml = this.processMentions(html);
      
      if (processedHtml !== html) {
        this.renderer.setProperty(
          this.editableDiv.nativeElement, 
          'innerHTML', 
          processedHtml
        );
        this.setCursorPosition(cursorPosition);
      }
      
      this.lastHtml = processedHtml;
      this.valueChange.emit(this.cleanOutput(processedHtml));
    }
  }

  private cleanBrTags(html: string): string {
    // Replace consecutive <br> tags with a single one
    html = html.replace(/(<br\s*\/?>\s*){2,}/g, '<br>');
    
    // Remove <br> if it's the only content
    if (html === '<br>') {
      return '\u200B';
    }
    
    // Remove <br> at the start of content
    html = html.replace(/^(\s*<br\s*\/?>\s*)+/, '');
    
    return html;
  }

  private cleanOutput(html: string): string {
    return html
      .replace(/\u200B/g, '') // Remove zero-width spaces
      .replace(/^\s+|\s+$/g, ''); // Trim whitespace
  }

  processMentions(html: string): string {
    // Remove existing mention spans
    html = html.replace(/<span>(.*?)<\/span>/g, '$1');
    
    // Add spans to new mentions
    return html.replace(/@([\w-]+\s[\w-]+)/g, (match, name) => {
      if (this.mentions.includes(name)) {
        return `<span>@${name}</span>`;
      }
      return match;
    });
  }

  getCursorPosition(): number {
    const selection = window.getSelection();
    if (!selection?.rangeCount) return 0;

    const range = selection.getRangeAt(0);
    const preCaretRange = range.cloneRange();
    preCaretRange.selectNodeContents(this.editableDiv.nativeElement);
    preCaretRange.setEnd(range.endContainer, range.endOffset);
    return preCaretRange.toString().length;
  }

  setCursorPosition(position: number) {
    const selection = window.getSelection();
    const range = document.createRange();

    let currentNode = this.editableDiv.nativeElement;
    let currentPos = 0;

    const findNodeAtPosition = (node: Node): { node: Node, offset: number } | null => {
      if (node.nodeType === Node.TEXT_NODE) {
        const nodeLength = node.textContent?.length || 0;
        if (currentPos + nodeLength >= position) {
          return { node, offset: position - currentPos };
        }
        currentPos += nodeLength;
      } else {
        for (let i = 0; i < node.childNodes.length; i++) {
          const result = findNodeAtPosition(node.childNodes[i]);
          if (result) return result;
        }
      }
      return null;
    };

    const result = findNodeAtPosition(currentNode);
    if (result) {
      range.setStart(result.node, result.offset);
      range.collapse(true);
      selection?.removeAllRanges();
      selection?.addRange(range);
    }
  }
}
