import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { CacheService } from '../_services/cache.service';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { ListSpecsOfViewComponent } from '../list-specs-of-view/list-specs-of-view.component';
import { CommonModule } from '@angular/common';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltip } from '@angular/material/tooltip';
import { customTooltipDefaults } from '../_configs/tooltip.config';

@Component({
  selector: 'app-spec-status-counts-of-view',
  templateUrl: './spec-status-counts-of-view.component.html',
  styleUrls: ['./spec-status-counts-of-view.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatTooltip
  ],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: customTooltipDefaults }
  ]
})

export class SpecStatusCountsOfViewComponent implements OnChanges {
  @Input() viewId: string = '';
  Backlog: number = 0;
  Design: number = 0;
  Review: number = 0;
  Correction: number = 0;
  Develop: number = 0;
  Test: number = 0;
  Failed: number = 0;
  Approve: number = 0;
  Deprecated: number = 0;
  Removed: number = 0;
  Total: number = 0;
  isEmpty: boolean = true;
  constructor(
    private cc: CacheService,
    private _dialog: DialogManagerService
  ) { }

  ngOnInit(): void { }

  ngOnChanges(change: SimpleChanges) {
    // @ts-ignore
    if (change['viewId']) {
      // @ts-ignore
      this.update();
    }
  }

  update() {
    this.isEmpty = true;
    this.Backlog = 0;
    this.Design = 0;
    this.Review = 0;
    this.Correction = 0;
    this.Develop = 0;
    this.Test = 0;
    this.Failed = 0;
    this.Approve = 0;
    this.Deprecated = 0;
    this.Removed = 0;
    if (this.viewId) {
      const specs = this.cc.allActiveWidgets.filter(
        (widget) => widget.viewId === this.viewId
      );
      if (specs.length) {
        specs.forEach((spec) => {
          switch (spec.status) {
            case 'Backlog':
              this.Backlog++;
              break;
            case 'Design':
              this.Design++;
              break;
            case 'Review':
              this.Review++;
              break;
            case 'Correction':
              this.Correction++;
              break;
            case 'Develop':
              this.Develop++;
              break;
            case 'Test':
              this.Test++;
              break;
            case 'Failed':
              this.Failed++;
              break;
            case 'Approve':
              this.Approve++;
              break;
            case 'Deprecated':
              this.Deprecated++;
              break;
            case 'Removed':
              this.Removed++;
              break;
          }
        });
        this.isEmpty = false;
        this.Total =
          this.Backlog +
          this.Design +
          this.Review +
          this.Correction +
          this.Develop +
          this.Test +
          this.Failed +
          this.Approve +
          this.Deprecated +
          this.Removed;
      }
    }
  }

  showSpecList(viewId: string, status: string, e: any) {
    this._dialog.openDialog(ListSpecsOfViewComponent, {
      data: {
        viewId,
        status
      },
    });
    e.stopPropagation();
  }
}
