import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { CommonModule } from '@angular/common';
import { Component, Inject, signal, ViewChild, WritableSignal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatIconButton } from '@angular/material/button';
import { MatOption } from '@angular/material/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatLabel, MatFormField, MatError } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatInput } from '@angular/material/input';
import { MatSelect } from '@angular/material/select';
import { CommentType, JsComment } from 'src/app/_interfaces/Comment';
import { CacheService } from 'src/app/_services/cache.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { getNewId } from 'src/app/shared/utils';
import _ from 'lodash';
import { entity } from 'src/app/_interfaces/Activity';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { AttachmentsComponent } from 'src/app/modules/shared/attachments/attachments.component';
import { DeleteAttachment } from 'src/app/_interfaces/Other';
import { serverTimestamp } from '@angular/fire/firestore';
import { entityEnum } from 'src/app/_enums/entity.enum';
import { ActivityService } from 'src/app/_services/activity.service';
import { JsEntity } from 'src/app/_interfaces/Entities';
import { Subject, takeUntil } from 'rxjs';
import { TextareaComponent } from 'src/app/modules/shared/textarea/textarea.component';

@Component({
  selector: 'app-comment-form',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    CdkTextareaAutosize,
    MatLabel,
    MatFormField,
    MatOption,
    MatInput,
    MatSelect,
    MatError,
    MatIcon,
    SelectComponent,
    MatIconButton,
    MatCheckbox,
    AttachmentsComponent,
    TextareaComponent
  ],
  templateUrl: './comment-form.component.html',
  styleUrl: './comment-form.component.scss'
})

export class CommentFormComponent {

  @ViewChild(AttachmentsComponent) AttachmentsComponent!: AttachmentsComponent;
  mentions: string[] = Object.keys(this.fbs.tagNameToUidMap);
  mode: 'new' | 'edit';
  commentType: CommentType[] = ['Bug', 'Question', 'Other'];
  commentForm: FormGroup;
  @ViewChild('cmtForm') cmtForm!: NgForm;
  commentInitial: JsComment;
  newAttachments: WritableSignal<File[]> = signal([]);
  deletedAttachments: WritableSignal<DeleteAttachment[]> = signal([]);
  entityEnum = entityEnum;
  unSubscribe = new Subject<void>();
  isSaveInProcess: boolean = false;
  @ViewChild(TextareaComponent) textArea: TextareaComponent | null = null;

  constructor(
    public cc: CacheService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'new' | 'edit', commentValue: JsComment, entityType: entity, entity: JsEntity },
    public dialogRef: MatDialogRef<CommentFormComponent>,
    public dialog: MatDialog,
    private fbo: FirebaseOptimisticService,
    public fbs: FirebaseService,
    private confirm: ConfirmService,
    private as: ActivityService
  ) {

    this.mode = data.mode;
    this.commentInitial = data.mode == 'new' ? this.initiateCommentForm() : this.initiateCommentForm(data.commentValue);
    this.commentForm = this.fb.group({
      text: new FormControl(this.commentInitial.text, Validators.required),
      assignedTo: new FormControl(this.commentInitial.assignedTo),
      resolvedBy: new FormControl(this.commentInitial.resolvedBy),
      type: new FormControl(this.commentInitial.type, Validators.required),
      attachmentNames: new FormControl(this.commentInitial.attachmentNames),
      replies: new FormControl(this.commentInitial.replies),
      watchers: new FormControl(this.commentInitial.watchers),
      entity: new FormControl(this.commentInitial.entity, Validators.required),
      entityId: new FormControl(this.commentInitial.entityId, Validators.required),
      id: new FormControl(this.commentInitial.id, Validators.required),
      createdBy: new FormControl(this.commentInitial.createdBy, Validators.required),
      deletedAt: new FormControl(this.commentInitial.deletedAt)
    });

    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        if(!this.isSaveInProcess){
          const confirmed = await this.confirm.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
          if (!confirmed) {
            return;
          }
          this.dialogRef.close();
        }
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
  }

  ngOnInit() {

  }

  getActivity() {
    this.as.getActivity('comment', this.commentInitial);
  }

  getFc(fcName: string): FormControl {
    return this.commentForm.get(fcName) as FormControl;
  }

  toggleResolved(e: MatCheckboxChange) {
    const isChecked = e.checked;
    this.commentForm.get('resolvedBy')?.setValue(isChecked ? this.fbs.getCurrentUserId() : null);
  }

  initiateCommentForm(comment?: JsComment): JsComment {
    return {
      text: comment ? comment.text : '',
      assignedTo: comment ? comment.assignedTo : '',
      resolvedBy: comment ? comment.resolvedBy : null,
      type: comment ? comment.type : 'Bug',
      attachmentNames: comment ? comment.attachmentNames : [],
      replies: comment ? comment.replies : [],
      watchers: comment ? comment.watchers : [this.fbs.getCurrentUserId()],
      entity: comment ? comment.entity : this.data.entityType,
      entityId: comment ? comment.entityId : this.data.entity.id,
      id: comment ? comment.id : getNewId(),
      createdBy: comment ? comment.createdBy : this.fbs.getCurrentUserId(),
      deletedAt: comment ? comment.deletedAt : null,
    }
  }

  checkAttachmentsChanges() {
    const initial = _.cloneDeep(this.commentInitial);
    const current = _.cloneDeep(this.commentForm.value);

    if (initial.attachmentNames?.length !== current.attachmentNames?.length) return true;
    initial.attachmentNames.sort((a: string, b: string) => (a > b ? 1 : -1));
    current.attachmentNames.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial.attachmentNames, current.attachmentNames);
  }

  hasChanges() {
    const initial = _.cloneDeep(this.commentInitial);
    const current = _.cloneDeep(this.commentForm.value);

    if (initial.watchers?.length !== current.watchers?.length) return true;
    initial.watchers.sort((a: string, b: string) => (a > b ? 1 : -1));
    current.watchers.sort((a: string, b: string) => (a > b ? 1 : -1));

    if (initial.attachmentNames?.length !== current.attachmentNames?.length) return true;
    initial.attachmentNames.sort((a: string, b: string) => (a > b ? 1 : -1));
    current.attachmentNames.sort((a: string, b: string) => (a > b ? 1 : -1));
    if (this.newAttachments().length !== 0 || this.deletedAttachments().length !== 0) return true;
    return !_.isEqual(initial, current);
  }

  async saveChanges(fromDelete?: boolean, isDelete?: boolean) {
    let updateStatus = null;
    this.isSaveInProcess = true;
    let commentData = _.cloneDeep(this.commentForm.value);
    if (this.newAttachments().length > 0) {
      const urls = await this.uploadFilesAndGetFileNames(this.newAttachments());
      const updatedUrls = [...this.commentForm.value.attachmentNames, ...urls];
      commentData.attachmentNames = updatedUrls;
    }
    const commentBackup = _.cloneDeep(commentData);

    commentData.uid = this.fbs.getCurrentUserId();
    commentData.updatedAt = new Date();
    commentData.cloudUpdatedAt = serverTimestamp();
    commentData.deletedAt = fromDelete && isDelete ? new Date() : null;
    commentData.watchers = this.getWatchers(commentData.text);

    const updateId = commentData.id;
    if (!commentData.id) return;
    if (this.mode === 'new') {
      commentData.createdAt = new Date();
      await this.fbo.createItemsOptimistic<JsComment>([commentData], 'comments', false, false);
      this.dialogRef.close(this.mode === 'new');
      await this.updateRespectiveEntity(this.data.entity, commentData);
      if (updateStatus && updateId === this.commentInitial.id) {
        this.mode = 'edit';
        this.commentInitial = _.cloneDeep(commentBackup);
        this.cancelChanges();
      }
      this.isSaveInProcess = false;
    } else if (this.mode === 'edit') {
      if (!fromDelete) {
        commentData.editedAt = new Date();
      }
      this.commentInitial = _.cloneDeep(commentData);
      await this.fbs.updateItems<JsComment>([commentData], 'comments');
      this.dialogRef.close(false);
      await this.updateRespectiveEntity(this.data.entity, commentData);
      if (fromDelete && !isDelete) {
        this.cc.restoreEntitySubject.next('comment');
      }
      if (this.deletedAttachments.length > 0) {
        const fileNames = this.deletedAttachments().map((file) => file.name);
        await this.fbs.deleteFiles(
          fileNames,
          `comments/${commentData.id}/`
        );
      }
      this.isSaveInProcess = false;
    }

    if (!updateStatus) {
      this.commentInitial = _.cloneDeep(commentBackup);
    }
    this.isSaveInProcess = false;
  }

  getWatchers(text: string): string[] {
    const regex = /<span>(.*?)<\/span>/g;
    const names: string[] = [];
    let match;

    while ((match = regex.exec(text)) !== null) {
      const name = match[1].replace('@', '');
      names.push(this.fbs.tagNameToUidMap[name]);
    }

    return names;
  }

  async updateRespectiveEntity(entity: JsEntity | any, comment: JsComment) {
    entity.latestComment = comment.deletedAt ? null : comment;
    await this.fbo.updateItemsOptimistic<JsEntity>([entity], this.entityEnum[this.data.entityType]);
  }

  async uploadFilesAndGetFileNames(files: any[]) {
    await this.fbs.uploadFiles(files, `comments/${this.commentForm.value.id}/`);
    return files.map((file) => file.name);
  }

  async deleteHandler(isDelete: boolean) {
    const confirmed = await this.confirm.confirm(`${isDelete ? 'Delete' : 'Restore'} Comment`, `Are you sure you want to ${isDelete ? 'delete' : 'restore'} this comment?`);
    if (!confirmed) return;
    this.saveChanges(true, isDelete);
  }

  cancelChanges() {
    this.commentForm.reset();
    this.cmtForm.resetForm();
    this.newAttachments.set([]);
    this.deletedAttachments.set([]);
    this.AttachmentsComponent.clearFiles();
    this.commentForm.patchValue(this.commentInitial);
    if (this.textArea) {
      this.textArea.reset();
    }
  }

  ngOnDestroy() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
