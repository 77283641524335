<span *ngIf="specStatusCounts">
  <!-- Backlog -->
  <span matTooltip="Backlog" matTooltipPosition="below" class="me-1 badge rounded-pill bg-info" [class]="{ 'opacity-25': specStatusCounts.Backlog === 0 }">
    {{ specStatusCounts.Backlog }}
  </span>
  <!-- Design -->
  <span matTooltip="Design" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-secondary" [class]="{ 'opacity-25': specStatusCounts.Design === 0 }">
    {{ specStatusCounts.Design }}
  </span>
  <!-- Review -->
  <span matTooltip="Review" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-review" [class]="{ 'opacity-25': specStatusCounts.Review === 0 }">
    {{ specStatusCounts.Review }}
  </span>
   <!-- Correction -->
   <span matTooltip="Correction" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-dark-danger" [class]="{ 'opacity-25': specStatusCounts.Correction === 0 }">
    {{ specStatusCounts.Correction }}
  </span>
  <!-- Develop -->
  <span matTooltip="Develop" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-primary" [class]="{ 'opacity-25': specStatusCounts.Develop === 0 }">
    {{ specStatusCounts.Develop }}
  </span>
  <!-- Test -->
  <span matTooltip="Test" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-warning" [class]="{ 'opacity-25': specStatusCounts.Test === 0 }">
    {{ specStatusCounts.Test }}
  </span>
  <!-- Failed -->
  <span matTooltip="Failed" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-danger" [class]="{ 'opacity-25': specStatusCounts.Failed === 0 }">
    {{ specStatusCounts.Failed }}
  </span>
  <!-- Approve -->
  <span matTooltip="Approve" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-success" [class]="{ 'opacity-25': specStatusCounts.Approve === 0 }">
    {{ specStatusCounts.Approve }}
  </span>
  <!-- Approve -->
  <span matTooltip="Deprecated" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-purple" [class]="{ 'opacity-25': specStatusCounts.Deprecated === 0 }">
      {{ specStatusCounts.Deprecated }}
  </span>
  <!-- Removed -->
  <span matTooltip="Removed" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-dark-purple" [class]="{ 'opacity-25': specStatusCounts.Removed === 0 }">
      {{ specStatusCounts.Removed }}
  </span>
  <!-- Total -->
  <span matTooltip="Total" matTooltipPosition="below" class="ms-1 badge rounded-pill bg-dark" [class]="{ 'opacity-25': specStatusCounts.Total === 0 }">
    {{ specStatusCounts.Total }}
  </span>
</span>

<span *ngIf="taskStatusCounts">
  <!-- Backlog -->
  <span matTooltip="Backlog" matTooltipPosition="below" class="me-1 badge rounded-pill bg-info" [class]="{ 'opacity-25': taskStatusCounts.Backlog === 0 }">
    {{ taskStatusCounts.Backlog }}
  </span>
  <!-- Design -->
  <span matTooltip="Design" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-secondary" [class]="{ 'opacity-25': taskStatusCounts.Design === 0 }">
    {{ taskStatusCounts.Design }}
  </span>
  <!-- Review -->
  <span matTooltip="Review" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-review" [class]="{ 'opacity-25': taskStatusCounts.Review === 0 }">
    {{ taskStatusCounts.Review }}
  </span>
  <!-- Develop -->
  <span matTooltip="Develop" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-primary" [class]="{ 'opacity-25': taskStatusCounts.Develop === 0 }">
    {{ taskStatusCounts.Develop }}
  </span>
  <!-- Test -->
  <span matTooltip="Test" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-warning" [class]="{ 'opacity-25': taskStatusCounts.Test === 0 }">
    {{ taskStatusCounts.Test }}
  </span>
  <!-- Failed -->
  <span matTooltip="Failed" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-danger" [class]="{ 'opacity-25': taskStatusCounts.Failed === 0 }">
    {{ taskStatusCounts.Failed }}
  </span>
  <!-- Approve -->
  <span matTooltip="Approve" matTooltipPosition="below" class="mx-1 badge rounded-pill bg-success" [class]="{ 'opacity-25': taskStatusCounts.Approved === 0 }">
    {{ taskStatusCounts.Approved }}
  </span>
  <!-- Total -->
  <span matTooltip="Total" matTooltipPosition="below" class="ms-1 badge rounded-pill bg-dark" [class]="{ 'opacity-25': taskStatusCounts.Total === 0 }">
    {{ taskStatusCounts.Total }}
  </span>
</span>
