import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CacheService } from '../_services/cache.service';
import { FirebaseService } from '../_services/firebase.service';
import { DialogManagerService } from '../_services/dialog-manager.service';
import { ListDailysOfTaskComponent } from '../list-dailys-of-task/list-dailys-of-task.component';
import { JsTask } from '../_interfaces/Task';

@Component({
  selector: 'app-user-task-hours',
  templateUrl: './user-task-hours.component.html',
  styleUrls: ['./user-task-hours.component.scss'],
})
export class UserTaskHoursComponent implements OnInit, OnDestroy {
  // public timeFrameSelected: 'Today' | 'Last 7 Days' | 'All' = 'Today';
  // public timeFrameOptions = ['Today', 'Last 7 Days', 'All'];
  public taskHours: {
    tid: number;
    taskName: string;
    hours: number;
    currentStatus: string;
    lastUpdated: Date;
    task: JsTask;
  }[] = [];
  public totalHours = 0;
  private taskSub: any;
  statusStyleMap: { [key: string]: string } = {
    Backlog: 'text-bg-info',
    Design: 'text-bg-secondary',
    Review: 'text-bg-review',
    Develop: 'text-bg-primary',
    Blocked: 'text-bg-dark-danger',
    Test: 'text-bg-warning',
    Failed: 'text-bg-danger',
    Mergeable: 'text-bg-purple',
    Passed: 'text-bg-dark-success',
    Approved: 'text-bg-success',
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      uid: string;
    },
    public dialogRef: MatDialogRef<UserTaskHoursComponent>,
    public cc: CacheService,
    public fbs: FirebaseService,
    private _dialog: DialogManagerService
  ) {}

  ngOnInit(): void {
    this.taskSub = this.cc.tasks$.subscribe((tasks) => {
      if(tasks){
        this.taskHours = [];
        this.totalHours = 0;
        tasks.forEach((task) => {
          if (
            task.timeLogs?.length > 0 &&
            task.timeLogs?.filter((tl) => tl.uid === this.data.uid).length > 0
          ) {
            const timeLog = task.timeLogs.filter(
              (tl) => tl.uid === this.data.uid
            )[0];
            this.taskHours.push({
              tid: task.tid,
              taskName: task.title,
              hours: timeLog.hours,
              currentStatus: task.status,
              lastUpdated: timeLog.lastUpdated,
              task: task,
            });
            this.totalHours += timeLog.hours;
          }
        });
        // Sort by latest timeLog updated first
        this.taskHours.sort((a, b) => {
          return b.lastUpdated.getTime() - a.lastUpdated.getTime();
        });
      }
    });
  }

  listDailysOfTask(task: JsTask): void {
    // Show app-task-dailys component in bottom sheet
    this._dialog.openDialog(ListDailysOfTaskComponent, {
      data: { task: task },
    });
  }

  ngOnDestroy(): void {
    this.taskSub?.unsubscribe();
  }
}
