<mat-form-field appearance="fill" class="w-100 h-100" [ngClass]="{ 'edited': hasChanged, 'ri-disabled' : disabled }">
    <mat-label>{{title}}</mat-label>
    <mat-select [formControl]="control" [multiple]="multiple" [placeholder]="title" [disabled]="disabled" (openedChange)="search && clearSearch($event)">
        <mat-select-trigger *ngIf="multiple">
            {{getFirstIndexValue() || ''}}
            <span *ngIf="(control.value?.length || 0) > 1">
                + {{(control.value?.length || 0) - 1}}
            </span>
        </mat-select-trigger>
        <mat-optgroup class="width: 100%" *ngIf="search">
            <mat-form-field style="width:100%;">
                <input class="px-2" #searchInput autocomplete="off" placeholder="Search" aria-label="Search" matInput [formControl]="searchControl" (keyup)="filterValues()" (keydown)="handleSearchKeydown($event)">
                <button [disableRipple]="true" *ngIf="searchInput.value" matSuffix mat-icon-button
                    aria-label="Clear" (click)="clearSearch($event)">
                    <mat-icon>close</mat-icon>
                </button>
            </mat-form-field>
        </mat-optgroup>
        <mat-option *ngIf="noneOption" [value] = noneValue (click)="selectChange('None')">{{ noneOptionText }}</mat-option>
        <mat-option *ngIf="allOption" value = 'All' (click)="selectChange('All')">ALL</mat-option>
        <mat-option *ngFor="let item of filteredOptions" (click)="selectChange()" [value]="value ? item[value] : item" [disabled]="disabledOptions.includes(value ? item[value] : item)" [hidden]="hiddenOptions.includes(value ? item[value] : item)">
            <span class="text-primary" *ngIf="prefix">{{prefix}}</span>
            <span class="text-primary" *ngIf="prefixValue">{{item[prefixValue]}} &nbsp;</span>
            <span>{{ label ? item[label] : item }}</span>
        </mat-option>
    </mat-select>
</mat-form-field>