import { Component, Inject, signal, ViewChild, WritableSignal } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { serverTimestamp } from '@angular/fire/firestore';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DownloadJsonService } from 'src/app/_services/download-json.service';
import { Env, EnvConfig, EnvDatas, JsRelease } from 'src/app/_interfaces/Release';
import { ActivityService } from 'src/app/_services/activity.service';
import { CacheService } from 'src/app/_services/cache.service';
import { ConfirmService } from 'src/app/_services/confirm.service';
import { FirebaseOptimisticService } from 'src/app/_services/firebase-optimistic.service';
import { FirebaseService } from 'src/app/_services/firebase.service';
import { SharedFunctionService } from 'src/app/_services/shared-function.service';
import * as _ from 'lodash';
import { convertDotNotationToStructured, flattenObjectToDotNotation, getNewId } from 'src/app/shared/utils';
import { faClone } from '@fortawesome/free-regular-svg-icons';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, NgForm, ReactiveFormsModule, Validators } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { MatIcon } from '@angular/material/icon';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Subject, takeUntil } from 'rxjs';
import { Config } from 'src/app/_interfaces/Config';
import { SelectComponent } from 'src/app/shared/components/select/select.component';
import { ViewTranslatedTextComponent } from 'src/app/shared/components/view-translated-text/view-translated-text.component';
import { MatTooltip } from '@angular/material/tooltip';
import { SelectConfigComponent } from 'src/app/shared/components/select-config/select-config.component';
import { JsonEditComponent } from 'src/app/shared/components/json-edit/json-edit.component';
import { checkInvalidFlags, checkInvalidParameter, floatWith3DecimalsValidator, releaseFeatureFlagValidator, releaseParameterValidator } from 'src/app/_configs/form-validator';
import { JsonViewComponent } from 'src/app/shared/components/json-view/json-view.component';
import { NumbersOnlyDirective } from 'src/app/_directives/numbers-only.directive';
import { DateFnsModule } from 'ngx-date-fns';

@Component({
  selector: 'app-release-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FaIconComponent,
    MatIcon,
    MatLabel,
    MatFormField,
    MatInput,
    CdkTextareaAutosize,
    SelectComponent,
    MatTooltip,
    MatError,
    SelectComponent,
    NumbersOnlyDirective,
    DateFnsModule
  ],
  templateUrl: './release-form.component.html',
  styleUrl: './release-form.component.scss'
})

export class ReleaseFormComponent {

  faDownload = faDownload;
  faClone = faClone;
  releaseForm: FormGroup;
  releaseInitial: JsRelease;
  mode: 'new' | 'edit' = 'new';
  unSubscribe = new Subject<void>();
  config!: Config;
  isFormReady: boolean = false;
  updationInProgress = false;
  deletionInProgress = false;
  translateInProgress = false;
  platformList: WritableSignal<string[]> = signal([]);
  segmentsList: WritableSignal<string[]> = signal([]);
  supportedEnvs: WritableSignal<Env[]> = signal([]);
  supportedLanguageCodes: WritableSignal<string[]> = signal([]);
  lastTranslatedText: string = '';
  @ViewChild('relConfigForm') relConfigForm!: NgForm;
  diffWith: FormControl = new FormControl(null);
  isDuplicateRid: boolean = false;
  displayForm: EnvDatas;

  constructor(
    private fbs: FirebaseService,
    public cc: CacheService,
    private fbo: FirebaseOptimisticService,
    private fns: AngularFireFunctions,
    @Inject(MAT_DIALOG_DATA) public data: { mode: 'new' | 'edit', releaseValue: JsRelease },
    public dialogRef: MatDialogRef<ReleaseFormComponent>,
    public sharedFunc: SharedFunctionService,
    public as: ActivityService,
    private fb: FormBuilder,
    public dialog: MatDialog,
    private confirmService: ConfirmService,
    private jsonService: DownloadJsonService
  ) {

    this.mode = data.mode;
    this.releaseInitial = data.mode == 'new' ? this.initiateReleaseForm() : this.initiateReleaseForm(data.releaseValue);

    this.releaseForm = this.fb.group({
      internalDescription: new FormControl(this.releaseInitial.internalDescription, Validators.required),
      externalDescription: this.fb.group({}),
      rid: new FormControl(this.releaseInitial.rid, [Validators.required, Validators.min(0.001), floatWith3DecimalsValidator()]),
      dbVersion: new FormControl(this.releaseInitial.dbVersion, [Validators.required, Validators.min(0)]),
      envs: this.fb.group({}),
      id: new FormControl(this.releaseInitial.id, Validators.required),
      deletedAt: new FormControl(this.releaseInitial.deletedAt)
    });

    dialogRef.backdropClick().pipe(takeUntil(this.unSubscribe)).subscribe(async () => {
      if (this.hasChanges()) {
        const confirmed = await this.confirmService.confirm('Alert', 'You have unsaved changes. Do you really want to discard them?', 'Discard', 'Cancel');
        if (!confirmed) {
          return;
        }
        this.dialogRef.close();
      } else {
        this.dialogRef.close(); // Close immediately if no unsaved changes
      }
    });
    this.displayForm = this.getConvertedArray();
  }

  initiateReleaseForm(release?: JsRelease, clone?: true): JsRelease {
    return {
      internalDescription: release && !clone ? release.internalDescription : '',
      externalDescription: release && !clone ? release.externalDescription : {},
      rid: release && !clone ? release.rid : 0,
      dbVersion: release && !clone ? release.dbVersion : 0,
      envs: release ? release.envs : {} as any,
      id: release && !clone ? release.id : getNewId(),
      deletedAt: release && !clone ? release.deletedAt : null,
    }
  }

  ngOnInit() {
    this.fbs.getConfig().pipe(takeUntil(this.unSubscribe)).subscribe((config: Config[]) => {
      this.config = config[0];
      this.platformList.set(this.config.supportedPlatforms);
      this.segmentsList.set(this.config.supportedSegments);
      this.supportedEnvs.set(this.config.supportedEnvs);
      this.supportedLanguageCodes.set(this.config.supportedLanguageCodes);
      this.supportedLanguageCodes().map((langCode: string) => {
        if (this.mode === 'new') {
          this.releaseInitial.externalDescription[langCode] = '';
          this.externalDescGroup.addControl(langCode, new FormControl('', langCode === 'en' ? Validators.required : null));
        } else {
          this.externalDescGroup.addControl(langCode, new FormControl(this.releaseInitial.externalDescription[langCode], langCode === 'en' ? Validators.required : null));
          this.lastTranslatedText = this.releaseInitial.externalDescription['en'];
        }
        this.displayForm = this.getConvertedArray();
      });
      this.supportedEnvs().map((env: Env) => {
        if (this.mode === 'new') {
          this.releaseInitial.envs[env] = {
            enabledPlatforms: [],
            enabledSegments: [],
            featureFlags: {},
            parameters: {},
          }
          this.envGroup.addControl(env, this.createEnvConfig());
        } else {
          this.envGroup.addControl(env, this.createEnvConfig(this.releaseInitial.envs[env]));
        }
        this.displayForm = this.getConvertedArray();
      });
    });
    this.displayForm = this.getConvertedArray();
  }

  createEnvConfig(envConfig?: EnvConfig) {
    return this.fb.group({
      enabledPlatforms: new FormControl(envConfig?.enabledPlatforms ? envConfig?.enabledPlatforms : []),
      enabledSegments: new FormControl(envConfig?.enabledSegments ? envConfig?.enabledSegments : []),
      featureFlags: new FormControl(envConfig?.featureFlags ? envConfig?.featureFlags : {}, [Validators.required, releaseFeatureFlagValidator(this.segmentsList())]),
      parameters: new FormControl(envConfig?.parameters ? envConfig?.parameters : {}, [Validators.required, releaseParameterValidator()]),
    })
  }

  get isTranslated(): boolean {
    return this.externalDescGroup.get('en')?.value && this.externalDescGroup.get('en')?.value === this.lastTranslatedText;
  }

  get externalDescGroup(): FormGroup {
    return this.releaseForm.get('externalDescription') as FormGroup;
  }

  get envGroup(): FormGroup {
    return this.releaseForm.get('envs') as FormGroup;
  }

  getPlatformFc(env: string): FormControl {
    return this.envGroup.get(env)?.get('enabledPlatforms') as FormControl;
  }

  getSegmentsFc(env: string): FormControl {
    return this.envGroup.get(env)?.get('enabledSegments') as FormControl;
  }

  getFeatureFlagsFc(env: string): FormControl {
    return this.envGroup.get(env)?.get('featureFlags') as FormControl;
  }

  getParametersFc(env: string): FormControl {
    return this.envGroup.get(env)?.get('parameters') as FormControl;
  }

  viewTranslatedTexts() {
    const viewTranslatedText = this.dialog.open(ViewTranslatedTextComponent, {
      width: '800px',
      maxHeight: '90vh',
      data: {
        title: 'External Description',
        texts: this.externalDescGroup.value,
      },
    });
    return viewTranslatedText.afterClosed();
  }

  getConvertedArray(): EnvDatas {
    const flatConfig = flattenObjectToDotNotation(this.releaseForm.get('envs')?.value);
    const envArray = convertDotNotationToStructured(flatConfig, this.supportedEnvs());
    return envArray;
  }

  selectValues(env: string, type: 'PLAT' | 'FLAG' | 'SEG', key?: string) {
    const selectConfig = this.dialog.open(SelectConfigComponent, {
      width: '600px',
      maxHeight: '90vh',
      disableClose: true,
      data: {
        title: type === 'PLAT' ? 'Enabled Platforms' : 'Available Segments',
        options: type === 'PLAT' ? this.platformList() : this.segmentsList(),
        selectedOptions: type === 'PLAT' ? (this.getPlatformFc(env).value ? this.getPlatformFc(env).value : []) : type === 'SEG' ? (this.getSegmentsFc(env).value ? this.getSegmentsFc(env).value : []) : key && this.getFeatureFlagsFc(env).value[key] && Array.isArray(this.getFeatureFlagsFc(env).value[key]) ? this.getFeatureFlagsFc(env).value[key] : []
      },
    });

    selectConfig.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (type === 'PLAT') {
          this.getPlatformFc(env)?.setValue(result);
        } else if (type === 'SEG') {
          this.getSegmentsFc(env)?.setValue(result);
        } else {
          if (key) {
            const updatedResult = Array.from(new Set(result)) as string[];
            const validSegments = updatedResult.filter((item) => this.segmentsList().includes(item));
            let flagsValue = _.cloneDeep(this.getFeatureFlagsFc(env).value);
            flagsValue[key] = validSegments;
            this.getFeatureFlagsFc(env).setValue(flagsValue);
          }
        }
      }
      this.displayForm = this.getConvertedArray();
    });
  }

  editJson(env: string, type: 'FLAG' | 'PARAM') {
    const jsonEdit = this.dialog.open(JsonEditComponent, {
      width: '800px',
      maxHeight: '90vh',
      disableClose: true,
      data: {
        title: type === 'PARAM' ? 'Parameters' : 'Feature Flags',
        type: type,
        json: type === 'PARAM' ? this.getParametersFc(env).value : this.getFeatureFlagsFc(env).value,
        control: type === 'PARAM' ? new FormControl(null, releaseParameterValidator()) : new FormControl(null, releaseFeatureFlagValidator(this.segmentsList())),
        hint: type === 'PARAM' ? 'parameter key should end with _ and n: number, b: boolean, s: string, nl: number[], bl: boolean[] or sl: string[]' : `Available segments -`,
        example: type === 'PARAM' ? "'colorVersion_n': 1, 'isDarkTheme_b': true, 'theme_s': 'dark', 'theme_sl': ['dark', 'light', 'system']" : '',
        segments: this.segmentsList()
      },
    });

    jsonEdit.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (type === 'PARAM') {
          this.getParametersFc(env).setValue(result);
        } else {
          this.getFeatureFlagsFc(env).setValue(result);
        }
      }
      this.displayForm = this.getConvertedArray();
    });
  }

  clone() {
    const clonedData = _.cloneDeep(this.releaseInitial);
    this.releaseInitial = this.initiateReleaseForm(clonedData, true);
    this.supportedLanguageCodes().map((langCode: string) => {
      this.releaseInitial.externalDescription[langCode] = '';
    });
    this.releaseForm.patchValue(this.releaseInitial);
    this.mode = 'new';
    this.supportedEnvs().map((env: Env) => {
      this.getPlatformFc(env).setValue(this.releaseInitial.envs[env].enabledPlatforms);
      this.getSegmentsFc(env).setValue(this.releaseInitial.envs[env].enabledSegments);
      this.getFeatureFlagsFc(env).setValue(this.releaseInitial.envs[env].featureFlags);
      this.getParametersFc(env).setValue(this.releaseInitial.envs[env].parameters);
    });
    this.displayForm = this.getConvertedArray();
  }

  download() {
    this.jsonService.downloadJson(`release_${this.releaseInitial.rid}_${this.releaseInitial.dbVersion}`, this.releaseInitial, true);
  }

  onDiffWithChanged() {
    let compareWith: JsRelease | undefined = undefined;
    if (this.diffWith.value) {
      compareWith = this.cc.releases.find(r => r.rid === this.diffWith.value);
    }
    const jsonDiffDialog = this.dialog.open(JsonViewComponent, {
      autoFocus: false,
      data: {
        entity: 'release',
        mode: 'view',
        title: `Diff for Release ${this.releaseForm.value.rid} with ${this.diffWith.value}`,
        type: 'JSONDIFF',
        oldJson: compareWith,
        newJson: this.releaseForm.value
      },
    });

    return jsonDiffDialog.afterClosed().subscribe(result => {
      this.diffWith.setValue(null);
      this.displayForm = this.getConvertedArray();
    });
  }

  objectEnvKeys(obj: any): Env[] {
    return Object.keys(obj) as Env[];
  }

  objectKeys(obj: any): string[] {
    return Object.keys(obj) as string[];
  }

  translateExternalDesc() {
    const translationCallableFn = this.fns.httpsCallable('translate');
    this.translateInProgress = true;
    const { externalDescription } = this.releaseForm.value;
    const { en } = externalDescription || {};
    const translateTo = this.supportedLanguageCodes().filter(code => code !== 'en');
    if (en) {
      const data$ = translationCallableFn({
        en,
        translateTo
      });
      data$.subscribe(data => {
        let translatedData = data;
        translateTo.forEach(langCode => {
          this.externalDescGroup.get(langCode)?.setValue(translatedData[langCode]);
        })
        this.lastTranslatedText = this.externalDescGroup.get('en')?.value;
        this.translateInProgress = false;
      });
    }
  }

  hasChanges(): boolean {
    return !_.isEqual(this.releaseInitial, this.releaseForm.value);
  }

  hasPlatformsChanged(env: Env): boolean {
    const initial = _.cloneDeep(this.releaseInitial.envs[env].enabledPlatforms);
    const current = _.cloneDeep(this.getPlatformFc(env).value);
    initial?.sort((a: string, b: string) => (a > b ? 1 : -1));
    current?.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial, current);
  }

  hasSegmentsChanged(env: Env): boolean {
    const initial = _.cloneDeep(this.releaseInitial.envs[env].enabledSegments);
    const current = _.cloneDeep(this.getSegmentsFc(env).value);
    initial?.sort((a: string, b: string) => (a > b ? 1 : -1));
    current?.sort((a: string, b: string) => (a > b ? 1 : -1));
    return !_.isEqual(initial, current);
  }

  hasObjectChanged(env: Env, key: string, value: any, type: 'FLAG' | 'PARAM'): boolean {
    const object = type === 'PARAM' ? this.releaseInitial.envs[env].parameters : this.releaseInitial.envs[env].featureFlags;
    const objectKeys = Object.keys(object);
    const editedObject = type === 'PARAM' ? this.getParametersFc(env).value : this.getFeatureFlagsFc(env).value;
    const editedObjectKeys = Object.keys(editedObject);
    if(!editedObjectKeys.includes(key)) {
      return false;
    }
    if (objectKeys.length === 0 || !objectKeys.includes(key)) {
      return true;
    }
    const objValue = object[key];
    if (Array.isArray(objValue)) {
      objValue?.sort((a: any, b: any) => (a > b ? 1 : -1));
    }

    if (Array.isArray(value)) {
      value?.sort((a: any, b: any) => (a > b ? 1 : -1));
    }
    return !_.isEqual(objValue, value);
  }

  isInValidParameter(env: Env, key: string, value: any) {
    const editedObject = this.getParametersFc(env).value;
    const editedObjectKeys = Object.keys(editedObject);
    if(!editedObjectKeys.includes(key)) {
      return false;
    }
    return checkInvalidParameter(key, value);
  }

  isInvalidFeatureFlag(value: any) {
    return checkInvalidFlags(this.segmentsList(), value);
  }

  getActivity() {
    this.as.getActivity('release', this.releaseInitial);
  }

  async updateHandler() {
    this.updationInProgress = true;
    const updatedRelease = _.cloneDeep(this.releaseForm.value);
    updatedRelease.updatedAt = new Date();
    updatedRelease.cloudUpdatedAt = serverTimestamp();
    updatedRelease.uid = this.fbs.getCurrentUserId();
    if (this.mode === 'new') {
      this.isDuplicateRid = this.cc.releases.some(rel => rel.rid === this.releaseForm.value.rid);
      if (!this.isDuplicateRid) {
        updatedRelease.createdAt = new Date();
        await this.fbo.createItemsOptimistic<JsRelease>([updatedRelease], 'releases');
      } else {
        this.updationInProgress = false;
      }
    } else {
      await this.fbo.updateItemsOptimistic<JsRelease>([updatedRelease], 'releases');
    }
    this.updationInProgress = false;
    this.dialogRef.close();
  }

  resetHandler() {
    this.releaseForm.reset();
    this.relConfigForm.resetForm();
    const releaseInitial = _.cloneDeep(this.releaseInitial);
    this.lastTranslatedText = this.releaseInitial.externalDescription['en'];
    this.releaseForm.patchValue(releaseInitial);
    this.displayForm = this.getConvertedArray();
  }

  async deleteHandler(isDelete: boolean) {
    const confirmed = await this.confirmService.confirm(`${isDelete ? 'Delete' : 'Restore'} Release`, `Are you sure you want to ${isDelete ? 'delete' : 'restore'} this release?`);
    if (!confirmed) {
      return;
    }
    this.deletionInProgress = true;
    const deletedRelease = _.cloneDeep(this.releaseInitial);
    deletedRelease.deletedAt = isDelete ? new Date() : null;
    deletedRelease.cloudUpdatedAt = serverTimestamp();
    deletedRelease.uid = this.fbs.getCurrentUserId();
    await this.fbo.updateItemsOptimistic<JsRelease>([deletedRelease], 'releases');
    if (!isDelete) {
      this.cc.restoreEntitySubject.next('release');
    }
    this.deletionInProgress = false;
    this.dialogRef.close();
  }

  ngOnDestroy() {
    this.unSubscribe?.complete();
    this.unSubscribe?.next();
  }
}
