<main class="px-3 pb-3 ri-min-w-1000">
  <!-- list all widgets using flex bootstrap 5 -->
  <div class="sticky-top bg-body p-2 d-flex align-items-center justify-content-between">
    <!-- <span class="fs-6">Todos for Feature: <span class="text-primary">F-{{data.task.tid}}</span> {{data.task.title}}</span> -->
    <h2 class="fw-semibold mb-0 fs-6">Todos List</h2>
    <app-select [options]="statusFilter.options" [title]="statusFilter.title" [control]="statusFilter.formControl"
      [defaultValue]="statusFilter.defaultValue" (selectEvent)="onStatusChange()"></app-select>
  </div>
  <div class="ri-min-h-400 mt-2 px-2" *ngIf="todos()?.length;else noTodos">
    <table class="table todos-list">
      <thead>
        <tr>
          <!-- <th>Priority</th> -->
          <th>Todo Title</th>
          <th>Status</th>
          <th>Type</th>
        </tr>
      </thead>
      <tbody>
        <tr class="ri-pointer" *ngFor="let todo of todos()" (click)="openTodo(todo)">
          <!-- <td scope="row" valign="middle">
              <span class="badge round-pill text-capitalize" [class]="priorityMap[todo.priority]">{{todo.priority}}</span>
            </td> -->
          <td class="text-break" width="90%" valign="middle"><span class="text-primary">T-{{todo.tid}}</span>
            {{todo.title}}</td>
          <td valign="middle">
            <span class="badge round-pill" [class]="statusClassMap[todo.status]">{{todo.status}}</span>
          </td>
          <td valign="middle">
            {{todo.type}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</main>
<ng-template #noTodos>
  <div class="text-center text-muted py-5 my-5">No Todos</div>
</ng-template>