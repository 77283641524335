import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { convertTimeStampsJs_Fb, getNewId } from '../shared/utils';
import { JsWidget } from '../_interfaces/Widget';
import { JsEntity } from '../_interfaces/Entities';
import { Collection } from '../_interfaces/Collection';
import { IdbServiceService } from './idb-service.service';
import { serverTimestamp, deleteField } from '@angular/fire/firestore';
import { ConfirmService } from './confirm.service';
import { OnlineStatusService } from 'ngx-online-status';
import * as _ from 'lodash';

interface keyValueType {
  [key: string]: string | number | boolean | Date | null;
}

@Injectable({
  providedIn: 'root',
})
export class FirebaseOptimisticService {

  internetStatus = 1;
  public highestSpecId = 0;
  unSubscribe = new Subject<void>();
  isUploading: boolean = false;
  isCreating: boolean = false;
  updateCollection: Collection | '' = '';
  updateEntities: JsEntity[] = [];
  createdEntities: JsEntity[] = [];
  
  constructor(
    private afs: AngularFirestore,
    public auth: AngularFireAuth,
    private router: Router,
    private idb: IdbServiceService,
    private confirmService: ConfirmService,
    private onlineStatusService: OnlineStatusService
  ) {
    this.onlineStatusService.status.pipe(takeUntil(this.unSubscribe)).subscribe((status) => {
      this.internetStatus = status;
    });
  }

  async createItemsOptimistic<T extends JsEntity>(
    items: T[],
    collection: Collection,
    skipPreviewCheck = false,
    isIdbUpdate = true,
  ) {
    this.isCreating = true;
    this.updateCollection = collection;
    this.createdEntities = [];
    if (this.internetStatus === 0) {
      this.isCreating = false;
      await this.confirmService.confirm(
        'Offline',
        'You are offline. Please connect to the internet to create items.',
        'Ok',
        '',
        true
      );
      return;
    }
    if (collection === 'widgets') {
      items = this.getWidgetsWithValidSpecIds(items as JsWidget[]) as T[];
    }
    if (items.length > 500) {
      this.isCreating = false;
      alert('You can only create 500 items at a time.');
      return;
    }
    const uid = this.getLocalUser()?.user?.uid;
    if (!items.length || !uid) return;
    // Important. Otherwise it will updated items in the components
    items = _.cloneDeep(items);
    // Flesh details
    items.forEach((item) => {
      item.id = item.id || getNewId();
      item.uid = uid;
      item.createdBy = uid;
      item.createdAt = new Date();
      item.updatedAt = new Date();
      item.cloudUpdatedAt = serverTimestamp();
    });
    // Add skipPreviewCheck for widgets
    if (collection === 'widgets') {
      (items as JsWidget[]).forEach((item) => {
        item.skipPreviewCheck = skipPreviewCheck;
      });
    }
    //Add it in Index database
    // const itemsBeforeCreate = await this.getItemsBeforeUpdate(
    //   items,
    //   collection
    // );
    this.createdEntities = items;
    if(isIdbUpdate) {
      await this.idb.addBulk(collection, items);
      await this.idb.updateObservablesFromIdb(collection);
    }
    // Add in Firestore - Because of the max limit of 500 writes per batch
    const batch = this.afs.firestore.batch();
    items.forEach(async (item) => {
      const docRef = this.afs.firestore.collection(collection).doc(item.id);
      batch.set(docRef, convertTimeStampsJs_Fb(item)!, { merge: true });
    });
    try {
      await batch.commit();
      this.isCreating = false;
      return true;
    } catch (err) {
      console.log('err', err);
      if(isIdbUpdate) {
        await this.idb.bulkDelete(
          collection,
          items.map((item) => item.id)
        );
        await this.idb.updateObservablesFromIdb(collection);
      }
      this.isCreating = false;

      alert('Error creating items. Please REFRESH now to see correct data.');
      return false;
    }
  }

  async updateItemsOptimistic<T extends JsEntity>(
    items: T[],
    collection: Collection,
    skipPreviewCheck = false,
    isIdbUpdate = true,
  ) {
    this.isUploading = true;
    this.updateCollection = collection;
    this.updateEntities = [];
    if (this.internetStatus === 0) {
      this.isUploading = false;
      await this.confirmService.confirm(
        'Offline',
        'You are offline. Please connect to the internet to update items.',
        'Ok',
        '',
        true
      );
      return;
    }
    if (items.length > 500) {
      this.isUploading = false;
      alert('You can only update 500 items at a time.');
      return;
    }
    if (collection === 'widgets') {
      items = this.getWidgetsWithValidSpecIds(items as JsWidget[]) as T[];
    }
    const uid = this.getLocalUser()?.user?.uid;
    if (!items.length || !uid) return;
    // Important. Otherwise it will updated items in the components
    items = _.cloneDeep(items);
    // Flesh details
    items.forEach((item) => {
      item.uid = uid;
      item.updatedAt = new Date();
      item.cloudUpdatedAt = serverTimestamp();
      // @ts-ignore
      if (item._meta) {
        // @ts-ignore
        item._meta = deleteField();
      }
    });
    // Add skipPreviewCheck for widgets
    if (collection === 'widgets') {
      (items as JsWidget[]).forEach((item) => {
        item.skipPreviewCheck = skipPreviewCheck;
      });
    }
    //Add it in Index database
    const itemsBeforeUpdate = await this.getItemsBeforeUpdate(
      items,
      collection
    );
    this.updateEntities = itemsBeforeUpdate;
    await this.idb.updateBulk(collection, items);
    await this.idb.updateObservablesFromIdb(collection);

    const diffItemsToUpdate = this.getDiffItemsToUpdate(
      itemsBeforeUpdate,
      items
    );
    console.log('UPDATE DIFF', diffItemsToUpdate);
    // Add in Firestore - Because of the max limit of 500 writes per batch
    const batch = this.afs.firestore.batch();
    diffItemsToUpdate.forEach((diffItem) => {
      // Exception for notifications -> activities
      const docRef = this.afs.firestore
        .collection(collection === 'notifications' ? 'activities' : collection)
        .doc(diffItem.id);
      batch.update(docRef, convertTimeStampsJs_Fb(diffItem)!);
    });
    try {
      await batch.commit();
      this.isUploading = false;
      return true;
    } catch (err) {
      console.log('err', err);
      this.idb.updateBulk(collection, itemsBeforeUpdate);
      await this.idb.updateObservablesFromIdb(collection);
      this.isUploading = false;
      alert('Error updating items. Please REFRESH now to see correct data.');
      return false;
    }
  }

  async handleUploadInterruption() {
    if(this.updateCollection !== '') {
      if(this.isCreating){
        await this.idb.bulkDelete(
          this.updateCollection,
          this.createdEntities.map((item) => item.id)
        );
        await this.idb.updateObservablesFromIdb(this.updateCollection);
      }
      if(this.isUploading){
        this.idb.updateBulk(this.updateCollection, this.updateEntities);
        await this.idb.updateObservablesFromIdb(this.updateCollection);
      }
    }
  }

  getLocalUser() {
    return JSON.parse(localStorage.getItem('user')!);
  }

  async getItemsBeforeUpdate(items: JsEntity[], collection: Collection) {
    const uid = this.getLocalUser()?.user?.uid;
    if (!items.length || !uid) return [] as JsEntity[];
    const ids = items.map((item) => item.id);
    console.log('ids', ids);
    const itemsBeforeUpdate = await this.idb.getBulk(collection, ids);
    return itemsBeforeUpdate as JsEntity[];
  }

  async getCurrentWidgetStatus(widgetId: string) {
    const widget = (await this.idb.getByKey('widgets', widgetId)) as JsWidget;
    return widget?.status || '';
  }

  // Get only the changed properties along with id. Taking current as the starting point
  getDiffItemsToUpdate(prev: JsEntity[], current: JsEntity[]) {
    console.log('prev', prev);
    console.log('current', current);
    const finalUpdates = current.map((item) => {
      const prevItem = prev.find((p) => p.id === item.id);
      if (!prevItem) {
        return item;
      } else {
        // Use lodash to find the difference between the two objects
        const diff = _.omitBy(item, (v, k) => {
          // @ts-ignore
          return _.isEqual(v, prevItem[k]);
        });
        diff.id = item.id;
        // @ts-ignore
        if (diff._meta) {
          // @ts-ignore
          delete diff._meta;
        }
        return diff;
      }
    });
    return finalUpdates;
  }

  getWidgetsWithValidSpecIds(widgets: JsWidget[]): JsWidget[] {
    const currentSpectIds: number[] = [];
    // Loop through the widgets and upadte the specIds if they are invalid or duplicate
    widgets.forEach((w) => {
      if (!w.specId || currentSpectIds.includes(w.specId)) {
        w.specId = this.highestSpecId + 1;
        this.highestSpecId++;
      } else {
        currentSpectIds.push(w.specId);
        if (w.specId > this.highestSpecId) this.highestSpecId = w.specId;
      }
    });
    return widgets;
  }

  stopSubscription() {
    this.unSubscribe?.next();
    this.unSubscribe?.complete();
  }
}
