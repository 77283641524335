export const dbConfig = {
    name: 'mevolveProjectDb',
    version: 24,
    objectStoresMeta: [
        {
            store: 'widgets',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [
                { name: 'id', keypath: 'id', options: { unique: true } },
                {
                    name: 'deletedAt',
                    keypath: 'deletedAt',
                    options: { unique: false }
                }
            ]
        },
        {
            store: 'views',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'params',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [
                { name: 'id', keypath: 'id', options: { unique: true } },
                {
                    name: 'deletedAt',
                    keypath: 'deletedAt',
                    options: { unique: false }
                }
            ]
        },
        {
            store: 'publications',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'translations',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'notifications',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'statusUpdates',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'dailys',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'releases',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'tasks',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'events',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'logs',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'todos',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'todos',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        },
        {
            store: 'computed',
            storeConfig: { keyPath: 'id', autoIncrement: false },
            storeSchema: [{ name: 'id', keypath: 'id', options: { unique: true } }]
        }
    ]
};