<mat-form-field appearance="fill" class="w-100" [class]="{'edited' : isEdited}">
  <mat-label>{{label}}</mat-label>
  <textarea #inputId type="text" [placeholder]="label" matInput [(ngModel)]="selectedSearchValue" [disabled]="disabled"
    [matAutocomplete]="auto" class="ri-pointer" readonly="readonly" (click)="openAutocomplete()"></textarea>
  <mat-autocomplete  class="custom-autocomplete-panel" [classList]="['custom-autocomplete']" #auto="matAutocomplete" (optionSelected)="onOptionSelect($event)">
    <input #searchTextInput class="p-3 sticky-top text-bg-light" type="text" placeholder="Search" matInput
      [(ngModel)]="searchText" (keyup)="setFilteredOptions()">
    <mat-option class="options-break-word" *ngFor="let option of filteredOptions" [value]="option">
      {{searchKey ? option[searchKey] : option}}
    </mat-option>
    <mat-option *ngIf="!filteredOptions.length" [value]="null">
      No results found
    </mat-option>
  </mat-autocomplete>
  <button matSuffix (click)="clearSelect($event)" *ngIf="clear && selectedOption !== null && selectedOption !== ''" aria-label="Clear" mat-icon-button type="button">
    <mat-icon>clear</mat-icon>
  </button>
</mat-form-field>